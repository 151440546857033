import { useState, useLayoutEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { serviceNameState } from '../hooks/atom/AtomState';
import '../css/profile.css';

import {
    getServiceInfo,
    updateServiceInfo
} from '../api/service/ServiceApi';

import BreadCrumb from '../components/BreadCrumb';
import usePopup from "../hooks/popup/usePopup";

export default function Settings() {
    const customer = JSON.parse(sessionStorage.getItem('customer') || '{}');
    const setServiceNameState = useSetRecoilState(serviceNameState);
    const { showPopup } = usePopup();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [model, setModel] = useState('');
    // const [modelApiKey, setModelApiKey] = useState('');
    const [embeddings, setEmbeddings] = useState('');
    // const [embeddingsApiKey, setEmbeddingsApiKey] = useState('');

    const update = () => {
        updateServiceInfo({
            id: customer.service_id,
            customer_id: customer.id,
            name: name,
            description: description,
            api_key: apiKey,
            model: model,
            embeddings: embeddings,
        }, 
        ()=>{
            sessionStorage.setItem('service_name', name)
            setServiceNameState(name)
            showPopup({ message: `수정 되었습니다.` });
        },
        (status, detail) => {
            if (detail) {
                showPopup({ message: detail.message });
            }
            else {
                showPopup({ message: `Error(${status})\n실패 했습니다.` });
            }
        }
        );
    }
    const models = [
        {
            name: "Chat Gpt",
            model: "gpt-3.5",
            // keys: "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"
        },
        {
            name: "kullm",
            model: "kullm-awq",
            // keys: "kullmv3#"
        }
    ];

    const embbedings = [
        {
            name: "Open AI",
            model: "openAI",  
            // keys: "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"
        },
        {
            name: "intfloat E5",
            model: "E5",
            // keys: "N/A"
        }
    ];

    const changeModel = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let modelName = e.target.value;
        let item = models.find((element) => element.model === modelName);
        if (item) setModel(item.model);
    }
    
    const changeEmbedding = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let modelName = e.target.value;
        let item = embbedings.find((element) => element.model === modelName);
        if (item) setEmbeddings(item.model);
    }

    useLayoutEffect(() => {
        getServiceInfo({ id: customer.service_id },
            (response) => {
                if (response) {
                    setName(response.data.name || `${customer.name}님의 서비스`);
                    setDescription(response.data.description);
                    setApiKey(response.data.api_key);
                    setModel(response.data.model);
                    setEmbeddings(response.data.embeddings);
                }

            },
            (status, detail) => {
                if (detail) {
                    showPopup({ message: detail.message });
                }
                else {
                    showPopup({ message: `Error(${status})\n실패 했습니다.` });
                }
            }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="home_contents">
            <BreadCrumb items={[{ name: "홈", path: "/" }, { name: "환경 설정" }]} />
            <h1 className="page_title">환경 설정</h1>

            <div className='ui_box'>
                {/* <div className='box_title'>UI 설정</div> */}
                <div className='profile_row'><div className='profile_label'> {'서비스 이름 : '}</div>
                    <input value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className='profile_row'><div className='profile_label'> {'설명 : '}</div>
                    <input value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
                <div className='profile_row'><div className='profile_label'> {'API Key : '}</div>
                    <input value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
                </div>
                <div className='profile_row'>
                    <div className='profile_label'>{'사용 모델 : '}</div>
                    <select value={model} onChange={(e) => changeModel(e)}>
                        {
                            models.map((item, index)=>{
                                return <option key={"model_" + index} value={item.model}>{item.name}</option>
                            })
                        }
                        {/* <option value='gpt-3.5'>Chat-gpt</option>
                        <option value='kullm-awq'>KULMM</option> */}
                    </select>
                </div>
                {/* <div className='profile_row'><div className='profile_label'> {'모델 API Key : '}</div>
                    <input disabled value={modelApiKey} onChange={(e) => setModelApiKey(e.target.value)} />
                </div> */}
                <div className='profile_row'>
                    <div className='profile_label'>{'임베딩 모델 : '}</div>
                    <select value={embeddings} onChange={(e) => changeEmbedding(e)}>
                        {
                            embbedings.map((item, index)=>{
                                return <option key={"embbedings_" + index} value={item.model}>{item.name}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            <button className='profile_apply_button' onClick={update}>수정</button>
        </div>
    )
}