import { atom, RecoilEnv } from 'recoil';
import { PopupProperty } from '../../popup/Popup';
import { PdfFileItem } from '../../dto/Items';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false

export const popupState = atom({
    key: 'popupStateAtomKey', // unique ID (with respect to other atoms/selectors)
    default: [] as PopupProperty[], // default value (aka initial value)
});

export const serviceState = atom({
    key: 'serviceStateAtomKey', // unique ID (with respect to other atoms/selectors)
    default: 0, // default value (aka initial value)
});

export const serviceNameState = atom({
    key: 'serviceNameAtomKey', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
});

export const sessionState = atom({
    key: 'sessionStateAtomKey', // unique ID (with respect to other atoms/selectors)
    default: {
        customer_id: 'test_customer',
        service_id: 'test_service'
    }, // default value (aka initial value)
});