import '../../css/kakao.css';
import login from '../../assets/guide/kakao_login.png';
import channel from '../../assets/guide/move_channel.png';

export default function Page1() {
    return (
        <div className='page_content'>
            <div className='sub_header'>채널 생성</div>
            <div className='conter_contents'>
                <ul>
                    <li>1. 카카오톡 비즈니스 센터 접속(<a href='https://business.kakao.com/'>https://business.kakao.com/</a>)</li>
                    <li>2. 본인 아이디 로그인</li>
                    <li><img className='guide_image' src={login} width='350px' alt='login page' /></li>
                    <li>3. 좌측 상단의 채널 클릭
                        <ul>
                            <li><img className='guide_image' src={channel} width='230px' alt='select channel' /></li>
                            <li>3-1. 운영 중인 채널이 없다면 채널 만들기</li>
                            <li>3-2. 운영 중인 기존 채널이 있다면 통과</li>
                            <li>{'   '}</li>
                        </ul>
                    </li>

                </ul>
            </div>
        </div>
    )
}