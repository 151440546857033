// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_contents {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.profile {
    flex: 20 1;
    padding-top: 10px;
    padding-left: 20px;
    background-color: white;
    min-height: calc(100vh - 50px);
}

.ui_box {
    border: solid 1px #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    margin: 12px 10px;
}

.profile_row {
    display: flex;
    margin-top: 7px;
    font-size: 13px;
    color: black;
}

.profile_label {
    width: 150px;
    text-align: right;
}

.profile_row input {
    border: solid 1px #e0e0e0;
    margin-left: 20px;
    border-radius: 5px;
    flex-grow: 1;
    padding: 5px 10px;
}

.profile_row select {
    border: solid 1px #e0e0e0;
    margin-left: 20px;
    border-radius: 4px;
    padding: 5px 5px;
    flex-grow: 1;
}

.profile_apply_button {
    cursor: pointer;
    border: solid 1px #e0e0e0;
    border-radius: 5px;
    background-color: #4361ee;
    min-width: 100px;
    color: white;
    padding: 5px 15px;
}`, "",{"version":3,"sources":["webpack://./src/css/profile.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,UAAQ;IACR,iBAAiB;IACjB,kBAAkB;IAClB,uBAAuB;IACvB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".home_contents {\n    width: 100%;\n    height: 100%;\n    overflow-y: scroll;\n}\n\n.profile {\n    flex: 20;\n    padding-top: 10px;\n    padding-left: 20px;\n    background-color: white;\n    min-height: calc(100vh - 50px);\n}\n\n.ui_box {\n    border: solid 1px #e0e0e0;\n    border-radius: 4px;\n    padding: 10px;\n    margin: 12px 10px;\n}\n\n.profile_row {\n    display: flex;\n    margin-top: 7px;\n    font-size: 13px;\n    color: black;\n}\n\n.profile_label {\n    width: 150px;\n    text-align: right;\n}\n\n.profile_row input {\n    border: solid 1px #e0e0e0;\n    margin-left: 20px;\n    border-radius: 5px;\n    flex-grow: 1;\n    padding: 5px 10px;\n}\n\n.profile_row select {\n    border: solid 1px #e0e0e0;\n    margin-left: 20px;\n    border-radius: 4px;\n    padding: 5px 5px;\n    flex-grow: 1;\n}\n\n.profile_apply_button {\n    cursor: pointer;\n    border: solid 1px #e0e0e0;\n    border-radius: 5px;\n    background-color: #4361ee;\n    min-width: 100px;\n    color: white;\n    padding: 5px 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
