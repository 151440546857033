import { useState, useLayoutEffect, useRef } from 'react';
import usePopup from "../hooks/popup/usePopup";
import BreadCrumb from '../components/BreadCrumb';
import { getChatbotInfo, updateChatbotInfo, chatbotImageUpload } from '../api/chatbot/ChatbotApi'

import '../css/chatbot.css';

export default function ChatbotSetting() {
    const { showPopup } = usePopup();
    // const customer_id = sessionStorage.getItem('customer_id');
    const service_id = sessionStorage.getItem('service_id');
    // const customerInfo = sessionStorage.getItem('customer');

    // const userName = JSON.parse(customerInfo!).name
    // const fileId = useRef(0);
    const botIconRef = useRef<HTMLInputElement>(null);
    const userIconRef = useRef<HTMLInputElement>(null);
    const sendIconRef = useRef<HTMLInputElement>(null);

    const [llm, setLlm] = useState('gpt');
    const [welcomeMessage, setWelcomeMessage] = useState('안녕하세요. 무엇을 도와드릴까요?');
    const [chatWidth, setChatWidth] = useState('300px');
    const [chatHeight, setChatHeight] = useState('500px');

    const [inputTextColor, setInputTextColor] = useState('#000000');
    const [sendButtonColor, setSendButtonColor] = useState('#2898ec');
    const [sendButtonIcon, setSendButtonIcon] = useState('');
    const [background, setBackground] = useState('#FFFFFF');
    const [userIcon, setUserIcon] = useState('');
    const [userBackground, setUserBackground] = useState('#ccd5ae');
    const [userTextColor, setUserTextColor] = useState('#000000');
    const [botIcon, setBotIcon] = useState('');
    const [botBackground, setBotBackground] = useState('#c9eded');
    const [botTextColor, setBotTextColor] = useState('#000000');

    const [embedWidth, setEmbedWidth] = useState('300px');
    const [embedHeight, setEmbedHeight] = useState('500px');


    let chatbot_url = `${process.env.REACT_APP_CHAT_URL}/external/chatbot?service_id=${service_id}&width=${embedWidth}&height=${embedHeight}&user_name=사용자이름`;

    const applySetting = () => {
        showPopup({
            message: "적용 하시겠습니까?",
            type: 'confirm',
            confirm: () => {
                sendUpdateInfo();
            }
        });
    };

    const sendUpdateInfo = (deleteType?: string) => {
        let send_button_icon = sendButtonIcon;
        let user_icon = userIcon;
        let bot_icon = botIcon;
        if (deleteType) {
            switch (deleteType) {
                case 'bot':
                    bot_icon = '';
                    break;
                case 'user':
                    user_icon = '';
                    break;
                case 'send':
                    send_button_icon = '';
                    break;
            }
        }
        updateChatbotInfo(
            {
                id: service_id!,
                summary: {
                    LLM: llm,
                    welcome_message: welcomeMessage
                },
                width: chatWidth,
                height: chatHeight,
                input_text_color: inputTextColor || '',
                send_button_color: sendButtonColor || '',
                send_button_icon: send_button_icon || '',
                background: background,
                customer: {
                    icon: user_icon || '',
                    background: userBackground,
                    text: userTextColor
                },
                bot: {
                    icon: bot_icon || '',
                    background: botBackground,
                    text: botTextColor
                }
            },
            () => {
                setEmbedWidth(chatWidth);
                setEmbedHeight(chatHeight);
                tryReload();
                showPopup({ message: '적용 되었습니다.' });
            },
            () => { }
        );
    }

    const handleUploadButtonClick = (type: string) => {
        switch (type) {
            case 'bot':
                botIconRef?.current?.click();
                break;
            case 'send':
                sendIconRef?.current?.click();
                break;
            case 'user':
            default:
                userIconRef?.current?.click();
                break;
        }
    };

    const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const formData = new FormData();
        formData.append("service_id", service_id!);
        formData.append("infotype", type);
        if (e.target.files) {
            for (const file of Array.from(e.target.files)) {
                formData.append("file", file);
            }
        }
        chatbotImageUpload(formData,
            () => { },
            (response) => {
                tryReload();
                if (response) {
                    switch (type) {
                        case 'bot':
                            setBotIcon(response.data?.image_url);
                            break;
                        case 'send':
                            setSendButtonIcon(response.data?.image_url);
                            break;
                        default:
                        case 'user':
                            setUserIcon(response.data?.image_url);
                            break;
                    }
                }
                showPopup({ message: '적용 되었습니다.' });
            },
            (status, detail) => {
                showPopup({ message: detail ? detail.message : '적용에 실패 했습니다.' });
            }
        )
    }
    const tryReload = () => {
        document.getElementById("chatbot_embedding")!.setAttribute('src', chatbot_url);
    }

    const copyToClipboard = () => {
        const str = `<embed src=${chatbot_url} width="${chatWidth}" height="${chatHeight}" />`;
        navigator.clipboard.writeText(str);
        showPopup({ message: '클립보드에 복사 되었습니다.' });
    }

    useLayoutEffect(() => {
        getChatbotInfo({ id: service_id! },
            (response) => {
                if (response?.data) {
                    const data = response.data;
                    setLlm(data.summary.LLM);
                    setWelcomeMessage(data.summary.welcome_message);
                    setChatWidth(data.width);
                    setChatHeight(data.height);
                    setBackground(data.background);
                    setUserIcon(data.customer.icon);
                    setUserBackground(data.customer.background);
                    setUserTextColor(data.customer.text);
                    setBotIcon(data.bot.icon);
                    setBotBackground(data.bot.background);
                    setBotTextColor(data.bot.text);

                    setInputTextColor(data.input_text_color);
                    setSendButtonColor(data.send_button_color);
                    setSendButtonIcon(data.send_button_icon);
                    setEmbedWidth(data.width);
                    setEmbedHeight(data.height);
                }
            },
            (status) => {
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='home_contents'>
            <BreadCrumb items={[{ name: "홈", path: "/" }, { name: "챗봇설정" }]} />
            <div className='page_title'>챗봇설정</div>
            <div className='chatbot_setting_layout'>
                <div className='chatbot_setting_left_layout'>
                    <div className='chatbot_embedding'>
                        <embed id={'chatbot_embedding'} src={chatbot_url} style={{ width: `calc(${embedWidth} + 1px)`, height: `calc(${embedHeight} + 1px)` }} width={`calc(${embedWidth} + 2px)`} height={`calc(${embedHeight} + 2px)`} />
                    </div>
                    <button className='reload_button' onClick={tryReload}>새로고침</button>
                </div>
                <div className='chat_divider' />

                <div className='chatbot_setting_right_layout'>
                    <div className='chatbot_setting_ui_box'>
                        <div className='ui_box_title'>Chat 설정</div>
                        <div className='chatbot_setting_row'>
                            <div className='setting_label'>{'웰컴메세지 : '}</div>
                            <input value={welcomeMessage} onChange={(e) => setWelcomeMessage(e.target.value)} />
                        </div>
                    </div>
                    <div className='chatbot_setting_ui_box'>
                        <div className='ui_box_title'>UI 설정</div>
                        <div className='chatbot_setting_row'><div className='setting_label'> {'폭 : '}</div>
                            <input value={chatWidth} onChange={(e) => setChatWidth(e.target.value)} />
                        </div>
                        <div className='chatbot_setting_row'><div className='setting_label'> {'높이 : '}</div>
                            <input value={chatHeight} onChange={(e) => setChatHeight(e.target.value)} />
                        </div>
                        <div className='color_setting_row'><div className='setting_label'> {'배경색 : '}</div>
                            <input className='color_preview' type='color' value={background} onChange={(e) => { setBackground(e.target.value) }} />
                            <input className='color_setting' value={background} onChange={(e) => setBackground(e.target.value)} />
                        </div>
                        <div className='color_setting_row'>
                            <div className='setting_label'>{'입력 텍스트 색 : '}</div>
                            <input className='color_preview' type='color' value={inputTextColor} onChange={(e) => { setInputTextColor(e.target.value) }} />
                            <input className='color_setting' value={inputTextColor} onChange={(e) => setInputTextColor(e.target.value)} />
                        </div>
                        <div className='color_setting_row'>
                            <div className='setting_label'>{'전송버튼 색 : '}</div>
                            <input className='color_preview' type='color' value={sendButtonColor} onChange={(e) => { setSendButtonColor(e.target.value) }} />
                            <input className='color_setting' value={sendButtonColor} onChange={(e) => setSendButtonColor(e.target.value)} />
                        </div>
                        <div className='chatbot_setting_row'><div className='setting_label'> {'전송 아이콘 : '}</div>
                            <div className='icon_setting'>
                                <input value={sendButtonIcon} disabled />
                                <input type="file" ref={sendIconRef} style={{ display: "none" }} accept="image/*" onChange={(e) => onChangeFiles(e, 'send')} />
                                {
                                    sendButtonIcon && <img className='icon_preview' alt='send icon preview' src={sendButtonIcon} />
                                }
                                <div className='chatbot_imege_upload_button' onClick={() => { handleUploadButtonClick('send') }}>업로드</div>
                                {
                                    sendButtonIcon && <div className='chatbot_imege_upload_button'
                                        onClick={() => {
                                            setSendButtonIcon('');
                                            if (sendIconRef?.current?.value) sendIconRef.current.value = '';
                                            sendUpdateInfo('send');
                                        }}
                                    >삭제</div>
                                }
                            </div>
                        </div>

                    </div>
                    <div className='chatbot_setting_ui_box bot_setting'>
                        <div className='ui_box_title'>봇 UI 설정</div>
                        <div className='color_setting_row'>
                            <div className='setting_label'>{'말풍선 색 : '}</div>
                            <input className='color_preview' type='color' value={botBackground} onChange={(e) => { setBotBackground(e.target.value) }} />
                            <input className='color_setting' value={botBackground} onChange={(e) => setBotBackground(e.target.value)} />
                        </div>
                        <div className='color_setting_row'>
                            <div className='setting_label'>{'텍스트 색 : '}</div>
                            <input className='color_preview' type='color' value={botTextColor} onChange={(e) => { setBotTextColor(e.target.value) }} />
                            <input className='color_setting' value={botTextColor} onChange={(e) => setBotTextColor(e.target.value)} />
                        </div>
                        <div className='chatbot_setting_row'><div className='setting_label'> {'아이콘 : '}</div>
                            <div className='icon_setting'>
                                <input value={botIcon} disabled />
                                <input type="file" ref={botIconRef} style={{ display: "none" }} accept="image/*" onChange={(e) => onChangeFiles(e, 'bot')} />
                                {
                                    botIcon && <img className='icon_preview' alt='bot icon preview' src={botIcon} />
                                }
                                <div className='chatbot_imege_upload_button' onClick={() => { handleUploadButtonClick('bot') }}>업로드</div>
                                {
                                    botIcon && <div className='chatbot_imege_upload_button'
                                        onClick={() => {
                                            setBotIcon('');
                                            if (botIconRef?.current?.value) botIconRef.current.value = '';
                                            sendUpdateInfo('bot');
                                        }}>삭제</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='chatbot_setting_ui_box user_setting'>
                        <div className='ui_box_title'>사용자 UI 설정</div>
                        <div className='color_setting_row'>
                            <div className='setting_label'> {'말풍선 색 : '}</div>
                            <input className='color_preview' type='color' value={userBackground} onChange={(e) => { setUserBackground(e.target.value) }} />
                            <input className='color_setting' value={userBackground} onChange={(e) => setUserBackground(e.target.value)} />
                        </div>
                        <div className='color_setting_row'>
                            <div className='setting_label'>{'텍스트 색 : '}</div>
                            <input className='color_preview' type='color' value={userTextColor} onChange={(e) => { setUserTextColor(e.target.value) }} />
                            <input className='color_setting' value={userTextColor} onChange={(e) => setUserTextColor(e.target.value)} />
                        </div>
                        <div className='chatbot_setting_row'><div className='setting_label'> {'아이콘 : '}</div>
                            <div className='icon_setting'>
                                <input value={userIcon} disabled />
                                <input type="file" ref={userIconRef} style={{ display: "none" }} accept="image/*" onChange={(e) => onChangeFiles(e, 'user')} />
                                {
                                    userIcon && <img className='icon_preview' alt='user icon preview' src={userIcon} />
                                }
                                <div className='chatbot_imege_upload_button' onClick={() => { handleUploadButtonClick('user') }}>업로드</div>
                                {
                                    userIcon && <div className='chatbot_imege_upload_button' 
                                    onClick={() => { 
                                        setUserIcon(''); 
                                        if (userIconRef?.current?.value) userIconRef.current.value = ''; 
                                        sendUpdateInfo('user');
                                    }}>삭제</div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className='flex_right'>
                        <button className='update_button' onClick={applySetting}>적용</button>
                    </div>
                </div>
            </div>
            <div className='url_copy_area'>

                <div className='url_copy_description'>
                    <button className='reload_button' style={{ marginLeft: '10px', backgroundColor: 'rgba(0, 0, 254, 0.5)', color: 'white' }} onClick={copyToClipboard}>Embedding Code 복사하기</button>
                    &nbsp;&nbsp;* 아래의 값들 중에 user_name은 사용자의 이름 또는 ID로 해당 사용자를 구분 할 수 있는 값을 적용해야 합니다.
                </div>
                <div className='chatbot_setting_ui_box url_copy_box'>{`<embed src=${chatbot_url} width="${chatWidth}" height="${chatHeight}" />`}</div>
            </div>
        </div>
    );
}