import { AxiosResponse } from 'axios';
import APIService from '../APIService';
import { BrandAnalyticsItem, PurposeAnalyticsItem, ChatAnalyticsItem, ChannelAnalyticsItem} from '../../dto/Items'
import { GetAnalyticsParam} from '../../dto/RequestParams'

const prefix = 'analytics/';

export async function  getBrandCount (params: GetAnalyticsParam, success: (res: AxiosResponse<BrandAnalyticsItem[], any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_brand', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getPurposeCount (params: GetAnalyticsParam, success: (res: AxiosResponse<PurposeAnalyticsItem[], any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_purpose', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getChatCount (params: GetAnalyticsParam, success: (res: AxiosResponse<ChatAnalyticsItem[], any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_chat', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getChannelCount (params: GetAnalyticsParam, success: (res: AxiosResponse<ChannelAnalyticsItem[], any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_channel', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}
