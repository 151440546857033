import React, { useState } from 'react';
import Layer from '../../components/Layer';
import { ReactComponent as CloseButton } from '../../assets/icon/close.svg';
import './productPopup.css'

import usePopup from "../../hooks/popup/usePopup";
import { updateKnowledgeItem, deleteKnowledgeItem } from '../../api/product/KnowledgeItemApi';

interface KnowledgeEditPopupProps {
    id: string;
    content: string;
    onChanged: () => void;
    onClose: () => void;
}

export default function KnowledgeEditPopup({ id, content, onChanged, onClose }: KnowledgeEditPopupProps) {
    const service_id = sessionStorage.getItem('service_id');
    const { showPopup } = usePopup();
    const [knowledgeContent, setKnowledgeContent] = useState(content);
    const knowledgeBeforeContent = content;

    const onEditClick = () => {
        showPopup({
            type: 'confirm',
            title: '확인',
            message: '변경사항을 수정 하시겠습니까?',
            confirmText: "수정",
            cancelText: "취소",
            confirm: () => {
                updateKnowledgeItem({ id: id, service_id: service_id!, content: knowledgeContent }, (response) => {
                    showPopup({ message: "수정 완료 되었습니다.", confirm: onClose });
                    onChanged();
                },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                    }
                )
            },
        });
    }

    const onDeleteClick = () => {
        showPopup({
            type: 'confirm',
            title: '확인',
            message: '삭제 하시겠습니까?',
            confirmText: "삭제",
            cancelText: "취소",
            confirm: () => {
                deleteKnowledgeItem({ id: id }, (response) => {
                    showPopup({ message: "삭제 완료 되었습니다.", confirm: onClose });
                    onChanged();
                },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                    }
                )
            },
        });
    }

    return (
        <Layer isShow={true}>
            <div className='knowledge_edit_background'>
                <div className='product_popup_title'>상세
                    <CloseButton style={{ width: '20px', height: '20px', cursor: 'pointer' }} onClick={onClose} />
                </div>
                <div className='knowledge_edit_content' style={{ border: 'none' }}>
                    <div className='edit_text_layout'>
                        <div className='origin_area'>원본<textarea className='popup_edit_area' maxLength={1000} value={knowledgeBeforeContent} disabled /></div>
                        <div>수정<textarea className='popup_edit_area' maxLength={1000} value={knowledgeContent} onChange={(e) => { setKnowledgeContent(e.target.value); }} /></div>
                    </div>
                </div>
                <div className='product_popup_button_layer'>
                    <button className="product_popup_button add_product_popup_button" onClick={onEditClick}>{"수정"}</button>
                    <button className="product_popup_button delete_product_popup_button" onClick={onDeleteClick}>{"삭제"}</button>
                </div>
            </div>
        </Layer>
    );
};