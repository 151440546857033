import { useState } from 'react';
import Layer from '../../components/Layer';
import usePopup from '../../hooks/popup/usePopup';

import {
    addProduct,
} from '../../api/product/ProductApi';

import './productPopup.css'

interface ProductAddPopupProps {
    customer_id: string;
    service_id: string;
    isShow: boolean; 
    onClose: (isAdded : boolean, type: string) => void;
}

export default  function ProductAddPopup({isShow, customer_id, service_id, onClose} : ProductAddPopupProps) {

    const { showPopup } = usePopup();

    const [addName, setAddName] = useState('');


    const addProductClick = () => {
        addProduct({
            customer_id: customer_id,
            service_id: service_id,
            knowledge_set_name: addName
        },
            (response) => {
                closePopup(true);
            },
            (status, detail) => {
                showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                closePopup(false)
            });
    }

    const closePopup = (isAdded: boolean) => {
        setAddName("");
        onClose(isAdded, 'add');
    }

    return (
        <Layer isShow={isShow}>
        <div className='product_popup_background'>
            <div className='product_popup_title'>Product 추가</div>
            <div className='product_popup_content' style={{ border: 'none' }}>
                <div className='product_popup_input_line'>
                    <div className='product_popup_input_label'>이름</div>
                    <input className='product_popup_input' value={addName || ''} onChange={(e) => { setAddName(e.target.value) }} />
                </div>
            </div>
            <div className='product_popup_button_layer'>
                <button className="product_popup_button add_product_popup_button" onClick={() => { addProductClick();}}>{"추가"}</button>
                <button className="product_popup_button close_product_popup_button" onClick={() => { closePopup(false) }}>{"닫기"}</button>
            </div>
        </div>
    </Layer>
    )
}