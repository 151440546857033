// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_contents {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.home_title {
    width: fit-content;
    margin-left: 15px;
    font-size: 25px;
    font-weight: 700;
}

.home_summary {
    display: flex;
    padding: 15px 40px;
    justify-content: space-between;
}

.home_graph_area {
    display: flex;
}

.home_pie_area {
    width: fit-content;
}

.home_line_area {
    flex-grow: 1;
}

.home_pie_title {
    width: fit-content;
    margin-left: 15px;
    margin-top: 15px;
    font-size: 18px;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/css/home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".home_contents {\n    width: 100%;\n    height: 100%;\n    overflow-y: scroll;\n}\n\n.home_title {\n    width: fit-content;\n    margin-left: 15px;\n    font-size: 25px;\n    font-weight: 700;\n}\n\n.home_summary {\n    display: flex;\n    padding: 15px 40px;\n    justify-content: space-between;\n}\n\n.home_graph_area {\n    display: flex;\n}\n\n.home_pie_area {\n    width: fit-content;\n}\n\n.home_line_area {\n    flex-grow: 1;\n}\n\n.home_pie_title {\n    width: fit-content;\n    margin-left: 15px;\n    margin-top: 15px;\n    font-size: 18px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
