import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";
import usePopup from "../hooks/popup/usePopup";
import { useInterval } from '../utils/timerUtils'

import { ReactComponent as Logo } from '../assets/pdf_chat/logo.svg';
import { ReactComponent as CheckOn } from '../assets/pdf_chat/intro_check_on.svg';
import { ReactComponent as CheckOff } from '../assets/pdf_chat/intro_check_off.svg';
import { ReactComponent as CheckIcon } from '../assets/pdf_chat/check_icon.svg';
import { ReactComponent as BotIcon } from '../assets/pdf_chat/bot.svg';
import { ReactComponent as UserIcon } from '../assets/pdf_chat/profile.svg';

import landing_bg from '../assets/pdf_chat/landing_bg.png';
import second_bg_1 from '../assets/pdf_chat/second_bg_1.png';
import second_bg_2 from '../assets/pdf_chat/second_bg_2.png';
import second_bg_3 from '../assets/pdf_chat/second_bg_3.png';
import second_bg_4 from '../assets/pdf_chat/second_bg_4.png';
import startButton from '../assets/pdf_chat/start_button.svg';

function PdfChatLanding() {
    sessionStorage.setItem('service_type', "pdf_chat");
    const { showPopup } = usePopup();
    const navigate = useNavigate();

    const [currentItem, setCurrentItem] = useState(0);

    const [isBusinessSelected, setIsBusinessSelected] = useState(false);

    interface UsageData {
        type: string;
        title: string;
        usage: string[];
        question: string;
        answer: String;
    }
    const UsageItem = ({ data }: { data: UsageData }) => {
        return (
            <div className='usage_item_layout'>
                <div className='usage_item_type'>{data.type}</div>
                <div className='usage_item_title'>{data.title}</div>

                {
                    data.usage.map((item, index) => {
                        return <div className='usage_item_data'><CheckIcon /><div key={"data_" + index}>{item}</div></div>
                    })
                }

                <div className='horizontal_divider' />
                <div className='usage_item_question'><div style={{ width: "26px" }}><UserIcon style={{ width: "26px", height: "26px" }} /></div><div>{data.question}</div></div>
                <div className='usage_item_answer'><div style={{ width: "26px" }}><BotIcon style={{ width: "26px", height: "26px" }} /></div><div>{data.answer}</div></div>
            </div>
        )
    }

    const personalUsageData: UsageData[] = [
        {
            type: "학생/연구원",
            title: "국내외 논문분석",
            usage: ["방대한 학술 논문, 도서의 요약", "연구에 필요한 정보 신속 검색"],
            question: "논문에서 리튬과 상관관계가 높은 광물은?",
            answer: "리튬과 상관도가 높은 광물은 세슘 입니다."
        },
        {
            type: "직장인",
            title: "계약서, 보고서 요약/분석",
            usage: ["주요 업무용 문서에 대한 빠른 검색/요약", "계약서, 각종 보고서, 업무 매뉴얼 분석"],
            question: "계약서상 업무 범위에대해 정리해 주고 주의 할 점은?",
            answer: "이 계약서는 소프트웨어 개발에 관한 표준계약서로서 ..."
        }
    ]
    const businessUsageData: UsageData[] = [
        {
            type: "상품/서비스 판매자",
            title: "쇼핑 플랫폼 고객 상담",
            usage: ["제품 브로셔, FAQ, Q&A 문서 업로드", "365/24 자동 고객 상담", "카카오톡 채널을 통한 답변"],
            question: "How long does shipping to the United States take",
            answer: "Overseas shipping usually takes 1 week."
        },
        {
            type: "성형외과, 정신과 등 병원",
            title: "병원 고객 상담",
            usage: ["자주하는 질문 문서화 후 업로드", "외국어 사용 고객 상담", "야간 / 휴일 상담"],
            question: "눈 성형 수술후 회복기간은?",
            answer: "개인차가 있지만 초기 1주일이면 아물고 2주차에 …"
        },
        {
            type: "공공 기관",
            title: "민원인 상담",
            usage: ["고객 상담 매뉴얼 및 관련 문서 업로드", "상담원 도우미로 활용", "카카오톡 연동 민원 서비스"],
            question: "국민연금 수령액 알고싶어요.",
            answer: "가입자 번호를 입력하시면 바로 알려 드리겠습니다. 아래…"
        }
    ]

    useInterval(() => {
        setCurrentItem((currentItem + 1) % 4);
    }, 2000);

    return (
        <div className="landing_layout">
            <div className="landing_first">
                <div className="first_header">
                    <Logo style={{ width: "44px", height: "44px", marginLeft: "30px" }} />&nbsp;&nbsp;&nbsp;DreamChat
                </div>
                <div className="first_title">Chat with your documents</div>
                <div className="first_content">당신이 가지고 있는 문서를 업로드하고 AI와 대화해 보세요.<br />AI가 모든 질문에 근거를 제시하고 즉시 답변합니다.</div>
                {/* <div className="start_button" onClick={()=>{{navigate('/pdf_chat_login')}}}/> */}
                <div>
                    <img className="start_button" src={startButton} onClick={() => { { navigate('/pdf_chat_login') } }} />
                </div>
                <div>
                    <img className='first_login_image' src={landing_bg} onClick={() => { { navigate('/pdf_chat_login') } }}/>
                </div>
            </div>
            <div className="landing_second">
                <div className="second_header" />
                <div className="second_contents">
                    <div className="second_text">
                        <div className="second_contents_01">Product</div>
                        <div className="second_contents_02">Key Point</div>
                        <div className="second_contents_03">
                            <div className={"second_contents_header" + (currentItem === 0 ? " second_content_selected" : "")}>
                                {currentItem === 0 ? <CheckOn /> : <CheckOff />}&nbsp;&nbsp;쉽고 빠른 서비스
                            </div>
                            <p>파일을 업로드 하는 즉시 문서를 요약하고<br />필요한 정보를 검색할수 있습니다.</p>
                            <div className={"second_contents_header" + (currentItem === 1 ? " second_content_selected" : "")}>
                                {currentItem === 1 ? <CheckOn /> : <CheckOff />}&nbsp;&nbsp;정확한 답변 제공
                            </div>
                            <p>DreamChat의 강력한 문서추출기능으로 정확한 답변을 제공합니다.</p>
                            <div className={"second_contents_header" + (currentItem === 2 ? " second_content_selected" : "")}>
                                {currentItem === 2 ? <CheckOn /> : <CheckOff />}&nbsp;&nbsp;답변근거 확인 기능
                            </div>
                            <p>AI가 생성한 답변의 근거를 확인할 수 있습니다.</p>
                            <div className={"second_contents_header" + (currentItem === 3 ? " second_content_selected" : "")}>
                                {currentItem === 3 ? <CheckOn /> : <CheckOff />}&nbsp;&nbsp;챗봇 연동
                            </div>
                            <p>손쉽게 LLM 챗봇을 여러분의 시스템에 연동할 수 있습니다.</p>
                        </div>
                    </div>
                    <div>
                        {
                            currentItem === 0 &&
                            <img src={second_bg_1} style={{ width: "600px", height: "570px" }} />
                        }
                        {
                            currentItem === 1 &&
                            <div style={{ width: "600px", height: "570px" }} >
                                <img src={second_bg_2} style={{ width: "440px", height: "440px", marginTop: "80px" }} />
                            </div>
                        }
                        {
                            currentItem === 2 &&
                            <div style={{ width: "600px", height: "570px" }} >
                                <img src={second_bg_3} style={{ width: "440px", height: "440px", marginTop: "80px" }} />
                            </div>
                        }
                        {
                            currentItem === 3 &&
                            <div style={{ width: "600px", height: "570px" }} >
                                <img src={second_bg_4} style={{ width: "386px", height: "468px", marginTop: "70px" }} />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="landing_third">
                <div className='third_header'>DreamChat 활용 예</div>
                <div className='third_tabs'>
                    <div className={isBusinessSelected ? 'third_tab_normal' : 'third_tab_selected'} onClick={() => { setIsBusinessSelected(false) }}>For Personal</div>
                    <div className={isBusinessSelected ? 'third_tab_selected' : 'third_tab_normal'} onClick={() => { setIsBusinessSelected(true) }}>For Business</div>
                </div>
                <div className='third_usage'>
                    {
                        isBusinessSelected ?
                            (
                                businessUsageData.map((item, index) => {
                                    return <UsageItem key={"item_" + index} data={item} />
                                })
                            )
                            :
                            (
                                personalUsageData.map((item, index) => {
                                    return <UsageItem key={"item_" + index} data={item} />
                                })
                            )
                    }
                </div>
            </div>
        </div>
    );
}
export default PdfChatLanding;