import { useState, useLayoutEffect, } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import { ReactComponent as Icon } from '../assets/icon/market.svg';
import { ReactComponent as Back } from '../assets/icon/back.svg';
import usePopup from "../hooks/popup/usePopup";

import { UpdateResettedPasswordParams } from '../dto/RequestParams'
import { resetCustomerPassword, updateResettedPassword, verifyResettedPassword } from '../api/auth/AuthApi';

import '../css/login.css'

export default function ChangePassword() {

    const location = useLocation();
    const queryString = location.search;
    const [searchParams, setSearchParams] = useSearchParams(queryString);

    const email = searchParams.get('email');
    const key = searchParams.get('key');

    const { showPopup } = usePopup();
    const navigate = useNavigate();

    const [customer_id, setCustomerId] = useState('');
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onReset(e);
        }
    };

    const onReset = (e: any) => {
        e.preventDefault();
        if (password?.length > 0 && retypePassword?.length > 0) {
            if (password !== retypePassword) {
                showPopup({ message: `두 비밀번호가 일치하지 않습니다.` });
                return;
            }
            showPopup({
                type: 'confirm',
                message: '비밀번호를 변경하시겠습니까?',
                confirm: () => {
                    const resetPasswordParams: UpdateResettedPasswordParams = {
                        id: customer_id,
                        key: key || '',
                        new_password: password
                    }
                    updateResettedPassword(resetPasswordParams,
                        (response) => {
                            if (response) {
                                showPopup({ message: '비밀번호가 변경되었습니다.\n다시 로그인 해 주세요.', confirm: () => { navigate('/'); } });
                            }
                        },
                        (status, detail) => {
                            showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });

                        }
                    )
                }
            });
        }
        else {
            showPopup({ message: '필수 정보를 입력 해 주세요.' });
        }
    }

    useLayoutEffect(() => {
        verifyResettedPassword(
            { email: email || '', key: key || '' },
            (response) => {
                if (response) {

                    setCustomerId(response.data.id);
                }
            },
            (status, detail) => {
                    showPopup({
                        message: detail ? detail.message : `Error(${status})\n실패 했습니다.`,
                        confirm: () => {
                            navigate('/');
                        }
                    });
            }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);

    return (
        <div className="login">
            <Icon className="icon_bg" />
            <div className="background_box">
                <div>
                    <Back className="back_button" onClick={() => { navigate('/'); }} />
                </div>
                <div className="logo"><Icon className="logo_icon" /> Market Buddy</div>
                <div className="guide_text">변경하실 비밀번호를<br /><br />입력해 주세요.</div>
                <div className="login_box">
                    <div className="password_area" >
                        <div className="input_header">Password</div>
                        <input className="text_input" type="password" placeholder="New password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                    </div>
                    <div className="password_area" >
                        <div className="input_header">Retype password</div>
                        <input className="text_input" type="password" placeholder="Retype password" value={retypePassword} onKeyDown={handleKeyDown} onChange={(e) => { setRetypePassword(e.target.value) }} />
                    </div>
                    <div className="button_area" >
                        <div className="registration_button" onClick={onReset}>비밀번호 변경</div>
                    </div>
                </div>
            </div>
        </div>
    )
}