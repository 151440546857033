import { popupState } from '../hooks/atom/AtomState';
import { useRecoilValue } from 'recoil';
import { BasicPopup } from './BasicPopup';
import { ConfirmPopup } from './ConfirmPopup';

export interface PopupProperty {
    type?: string;
    title?: string;
    message: string;
    confirm?: any;
    confirmText?: string;
    cancel?: any;
    cancelText?: string;

}

function PopupJs() {
    const popupList = useRecoilValue<Array<PopupProperty>>(popupState);
    const filterPopup = (props: PopupProperty, index: number) => {
        switch(props.type) {
            case 'confirm':
                return <ConfirmPopup key={index} {...props} />;                    
            case 'basic':
            default:
                return <BasicPopup key={index} {...props}  />;
        }
    };
    return <div style={{
            display: popupList.length > 0? 'flex' : 'none',
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: '999'
        }} >
            {
                popupList && popupList.map((props: PopupProperty, index: number) => filterPopup(props, index))
            }
        </div>;
}
export default PopupJs;