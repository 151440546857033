import {useState} from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Icon } from '../assets/icon/market.svg';
import { ReactComponent as Back } from '../assets/icon/back.svg';
import usePopup from "../hooks/popup/usePopup";

import { ResetPasswordParams } from '../dto/RequestParams'
import { resetCustomerPassword } from '../api/auth/AuthApi';

import '../css/login.css'

export default function ResetPassword() {

    const { showPopup } = usePopup();
    const navigate = useNavigate();

    const [loginEmail, setLoginEmail] = useState('');
    const [loginName, setLoginName] = useState('');

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onReset(e);
        }
      };

    const onReset = (e: any) => {
        e.preventDefault();
        if (loginEmail?.length > 0 && loginName?.length > 0 ) {
            showPopup({ 
                type: 'confirm',
                message: '비밀번호 초기화를 진행 하시겠습니까?\n초기화 요청은 이메일로 발송 됩니다.', 
                confirm:()=>{
                    const resetPasswordParams: ResetPasswordParams = {
                        name: loginName,
                        email: loginEmail
                    }
                    resetCustomerPassword(resetPasswordParams, 
                        (response)=>{
                            if(response) {
                                showPopup({ message: '확인용 Email이 발송 되었습니다.\n메일을 확인 해 주세요.', confirm:()=>{navigate('/');} });
                            }
                        },
                        (status, detail)=>{
                            showPopup({ message: detail? detail.message : `Error(${status})\n실패 했습니다.` });

                        }
                    )
                }
            });
        }
        else {
            showPopup({ message: '필수 정보를 입력 해 주세요.'});
        }
    }

    return (
        <div className="login">
            <Icon className="icon_bg" />
            <div className="background_box">
                <div>
                    <Back className="back_button" onClick={() => { navigate('/'); }} />
                </div>
                <div className="logo"><Icon className="logo_icon" /> Market Buddy</div>
                <div className="guide_text">회원 확인을 위한 기본 정보를<br /><br />입력해 주세요.</div>
                <div className="login_box">
                <div className="email_area" >
                        <div className="input_header">Name</div>
                        <input className="text_input" placeholder="서비스에 가입된 사용자의 이름" value={loginName} onChange={(e) => { setLoginName(e.target.value) }}/>
                    </div>
                    <div className="password_area" >
                        <div className="input_header">Email</div>
                        <input className="text_input" placeholder="가입 시 적용한 이메일" value={loginEmail} onKeyDown={handleKeyDown} onChange={(e) => { setLoginEmail(e.target.value) }}/>
                    </div>
                    <div className="button_area" >
                        <div className="registration_button" onClick={onReset}>비밀번호 초기화</div>
                    </div>
                </div>
            </div>
        </div>
    )
}