import { useNavigate } from "react-router-dom";

interface BreadCrumbItem {
    name: string;
    path?: string;
    state?: object;
}

interface BreadCrumbProps {
    items: BreadCrumbItem[];
}

export default function BreadCrumb(props: BreadCrumbProps) {
    const navigate = useNavigate();

    return (
        <div style={{padding: '15px'}}>
            <div style={{ display: 'flex'}}>
                {
                    props.items.map((item, index) => {
                        return <div key={'bread_crumb_' + index} >
                            {
                                item.path ? 
                                <span style={{cursor:'pointer'}} onClick={()=>navigate(item.path!, {state: item.state})}>{item.name}</span>
                                : 
                                <span>{item.name}</span>    
                            }
                            {
                                index < props.items.length - 1 ? <span>&nbsp;&gt;&nbsp;</span> : ""
                            }
                        </div>
                    })
                }
            </div>
            <div style={{width: "100%", height: "1px", background: "#000000", marginTop: "5px"}}/>
        </div>
    );
}