import { useState } from 'react';
import Layer from '../../components/Layer';
import usePopup from '../../hooks/popup/usePopup';

import {
    updateCustomerInfo,
} from '../../api/auth/AuthApi';

import './passwordPopup.css'

interface PasswordPopupProps {
    customer_id: string;
    name: string;
    businessNumber: string;
    isShow: boolean;
    onClose: () => void;
}

export default function PasswordPopup({ isShow, customer_id, businessNumber, name, onClose }: PasswordPopupProps) {

    const { showPopup } = usePopup();

    const [password, setPassword] = useState('');


    const updateClick = () => {
        if (!password || password.length === 0) {
            showPopup({message: '비밀번호를 입력 해 주세요.'})
            return;
        }
        updateCustomerInfo({
            id: customer_id,
            name: name,
            business_number: businessNumber,
            password: password
        },
            (response) => {
                if (response) {
                    sessionStorage.setItem('customer', JSON.stringify(response.data));
                }
                showPopup({ message: '변경 되었습니다.' });
                closePopup();
            },
            (status, detail) => {
                showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                closePopup()
            });
    }

    const closePopup = () => {
        setPassword("");
        onClose();
    }

    return (
        <Layer isShow={isShow}>
            <div className='password_popup_background'>
                <div className='password_popup_title'>사용자 정보 변경</div>
                <div className='password_popup_content' style={{ border: 'none' }}>
                    <div className='password_popup_description'>사용자 정보를 변경 하시려면<br />비밀번호를 입력 해 주세요.</div>
                    <div className='password_popup_input_line'>
                        <div className='password_popup_input_label'>비밀번호</div>
                        <input type='password' className='password_popup_input' value={password || ''} onChange={(e) => { setPassword(e.target.value) }} />
                    </div>
                </div>
                <div className='password_popup_button_layer'>
                    <button className="password_popup_button confirm_popup_button" onClick={() => { updateClick(); }}>{"변경"}</button>
                    <button className="password_popup_button cancel_popup_button" onClick={() => { closePopup() }}>{"닫기"}</button>
                </div>
            </div>
        </Layer>
    )
}