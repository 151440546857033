import { useState, useRef, useEffect } from 'react';
import Markdown from 'react-markdown'
// import { ReactComponent as SendIcon } from '../../assets/icon/send.svg';
import { ReactComponent as SendIcon } from '../../assets/pdf_chat/send.svg';
import { ReactComponent as ResultIcon } from '../../assets/pdf_chat/result.svg';
import { ReactComponent as CopyIcon } from '../../assets/pdf_chat/copy.svg';

import { ReactComponent as BotIcon } from '../../assets/pdf_chat/bot.svg';
import { ReactComponent as UserIcon } from '../../assets/pdf_chat/profile.svg';

import Loading from '../../components/iconify/loading';
import { sendFileChatMessage } from '../../api/chatbot/ChatbotApi'
import usePopup from "../../hooks/popup/usePopup";
import { gridDateTime } from '../../utils/TimeFomatter';
import { copyToClipboard } from '../../utils/Clipboard';
import { SessionConversationItem } from '../../dto/Items';
import {
    getSessionConversation,
} from '../../api/analytics/ChatLogApi';
import { KnowledgeItem } from '../../dto/Items';

import '../pdfchat.css'

interface ChatMessage {
    message: string;
    isUser: boolean;
    time: Date;
    isloading?: boolean;
    id?: number;
    knowledge?: KnowledgeItem[];
}

interface FileChatProps {
    session_id: string;
    knowledge_set_id: number;
    file_name: string | undefined;
    file_status: number | undefined;
    isReferenceOpen: boolean;
    onKnowledgeClick: (knowledge?: KnowledgeItem[]) => void;
    onPointChange: () => void;
}

function FileChat({ session_id, knowledge_set_id, file_name, file_status, onKnowledgeClick, onPointChange,isReferenceOpen }: FileChatProps) {
    const service_id = sessionStorage.getItem('service_id');
    const customer = JSON.parse(sessionStorage.getItem('customer') || "{}");
    const userName = customer.name;
    const { showPopup } = usePopup();

    const [messageList, setMessageList] = useState<ChatMessage[]>([]);
    const [message, setMessage] = useState('');
    const [selectedChat, setSelectedChat] = useState<number | undefined>();
    const [inputStatus, setInputStatus] = useState(true);

    const [isInputFocus, setIsInputFocus] = useState(false);

    sessionStorage.setItem("currentSessionId", session_id);

    const scrollRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const addMessage = (message: string, isUser: boolean, time: Date) => {
        let temp = [...messageList];
        temp.push({ message: message, isUser: isUser, time: time })
        temp.push({ message: "응답을 처리 중입니다.", isUser: false, isloading: true, time: new Date() })
        if (isUser) setMessage('');
        setMessageList(temp);
    }

    const addResultMessage = (userMessage: string, id: string, userTime: Date, botMessage: string, knowledge?: any[]) => {
        let temp = [...messageList];
        temp.push({ message: userMessage, isUser: true, time: userTime })
        temp.push({ message: botMessage, id: +id, isUser: false, isloading: false, time: new Date(), knowledge: knowledge })
        setMessageList(temp);
    }
    const setMessages = (messages: SessionConversationItem[]) => {
        let temp = [];
        for (let item of messages) {
            temp.push({ message: item.question, isUser: true, time: new Date(item.startedAt) })
            temp.push({ message: item.answer, isUser: false, id: item.id, knowledge: item.knowledge, isloading: false, time: new Date(item.endedAt) })
        }
        setMessageList(temp);
    }

    const activeEnter = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            sendChatBotMessage();
        }
    }

    const sendChatBotMessage = async () => {
        const utterance = `${message}`;
        let userTime = new Date();
        if (!inputStatus || utterance.length == 0) return;
        setInputStatus(false)
        addMessage(utterance, true, userTime);
        scrollToBottom();
        sendFileChatMessage(
            {
                utterance: utterance,
                user_name: userName!,
                service_id: service_id!,
                knowledge_set_id: String(knowledge_set_id), // TODO
                session_id: session_id
            },
            (response) => {
                if (response) {
                    const returnText = response.data?.template?.outputs[0]?.simpleText?.text || '';
                    const knowledgeList = response.data?.template?.outputs[1].listCard?.items
                    let knowledges = []
                    for (let content of knowledgeList) {
                        knowledges.push({ content: content.description })
                    }
                    let currentSessionId = sessionStorage.getItem("currentSessionId");
                    if (currentSessionId === response.data.session_id) {
                        addResultMessage(utterance, response.data.answer_id, userTime, returnText, knowledges);
                        inputRef.current?.focus();
                        scrollToBottom();
                        sessionStorage.setItem('sessionId', response.data.session_id);
                    }
                    setInputStatus(true);
                    onPointChange();
                }
            }
            ,
            (status) => {
                addResultMessage(utterance, "-1", userTime, "오류가 발생 했습니다. 잠시 후 다시 시도해 주세요.");
                inputRef.current?.focus();
                scrollToBottom();
                setInputStatus(true)
                console.log("=========================== FAIL : " + status)
            }
        )
        inputRef.current?.focus();
    }

    const KnowledgeLink = ({ id, knowledge }: { id?: number, knowledge?: KnowledgeItem[] }) => {
        return (
            knowledge && knowledge.length > 0 ?
                <div className='pdf_knowledge' onClick={() => {setSelectedChat(id); onKnowledgeClick(knowledge); }}><ResultIcon style={{width: '20px', height:'20px'}} />&nbsp;출처</div>
                :
                <></>
        )
    }

    const KnowledgeCopy = ({ text }: { text: string}) => {
        return (
                <div className='pdf_knowledge' onClick={() => {copyTo(text);}}><CopyIcon style={{width: '20px', height:'20px'}} />&nbsp;복사</div>
        )
    }

    const copyTo = async (text : string) => {
        copyToClipboard(text).then((result) =>{
            if (result) {
                showPopup({ message: '복사 되었습니다.' });
            }
            else {
                showPopup({ message: '복사에 실패 했습니다.' });
            }
        })
    }

    const Bot = ({ text, time, id, knowledge, isloading }: { text: string, time: Date, id?: number, knowledge?: KnowledgeItem[], isloading: boolean | undefined }) => {
        return (
            <div>
                <div className='pdf_bot_text'>
                    <div className='bot_icon_layout'>
                        <BotIcon className='bot_icon'/>&nbsp;&nbsp;DreamChat Bot
                    </div>
                    {
                        isloading ?
                            <div className='textbox' style={{ display: 'flex' }} ><div><Markdown>{text}</Markdown></div><Loading width='20px' /></div>
                            :
                            <>
                                <div className='textbox'><Markdown>{text}</Markdown></div>
                                <div className='pdf_knowledge_layout'>
                                    <KnowledgeLink id={id} knowledge={knowledge} />
                                    &nbsp;&nbsp;<KnowledgeCopy text={text}/>
                                </div>
                            </>
                    }
                </div>
                {/* <div className='bot chat_time'>{gridDateTime(time, 'yyyy-MM-dd hh:mm:ss')}</div> */}
            </div>
        );
    }

    const User = ({ text, time }: { text: string, time: Date }) => {
        return (
            <div>
                <div className='user_icon_layout'>
                    <UserIcon className='user_icon' />&nbsp;나
                </div>
                <div className='pdf_user_text'>
                    <div className='textbox'>{text}</div>
                </div>
                {/* <div className='user chat_time'>{gridDateTime(time, 'yyyy-MM-dd hh:mm:ss')}</div> */}
            </div>
        );
    }

    const scrollToBottom = () => {
        if (scrollRef.current) {
            setTimeout(() => { scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight; }, 200);
        }
    };
    const getConversation = () => {
        setMessageList([]);
        onKnowledgeClick([]);
        getSessionConversation({
            id: session_id,
        }, (response) => {
            if (response) {
                setMessages(response.data);
                scrollToBottom();
            }
            // if (response) setConversationList(response.data);
        }, (status, detail) => {
            // showPopup({ message: detail? detail.message :  `Error(${status})\n실패 했습니다.` });
        });
    }
    useEffect(() => {
        if (session_id) {
            sessionStorage.setItem("currentSessionId", session_id);
            getConversation();
        }
        else {
            sessionStorage.removeItem("currentSessionId");
            setMessages([]);
            onKnowledgeClick([]);
        }
    }, [session_id])

    useEffect(()=>{
        if (!isReferenceOpen) {
            setSelectedChat(undefined);
        }
    }, [isReferenceOpen])


    useEffect(()=>{
        setInputStatus(file_status === 0);
    }, [file_status])
    return (
        <div className='file_chat_content' >
            <div className='file_chat_layout' ref={scrollRef}>
                <div className='pdf_file_info'>
                    Current file : <span className='pdf_file_name'>&nbsp;{file_name}</span>
                </div>
                {
                    messageList && messageList.length > 0 ?
                        <>
                            {
                                messageList.map((data, index) => (
                                    <div key={'chat_item_' + index} style={{background : data.id !== undefined && data.id === selectedChat  ? "#fdf0d5" : "#FFFFFF"}}>
                                        {
                                            data.isUser ?
                                                <User text={data.message} time={data.time} />
                                                :
                                                <Bot text={data.message} time={data.time} id={data.id} knowledge={data.knowledge} isloading={data.isloading} />
                                        }
                                    </div>
                                ))
                            }
                        </>
                        :
                        <>
                            <div className='pdf_chat_guide'>
                                하단 입력창을 이용해 질문을 해 보세요.
                            </div>
                        </>
                }
            </div>
            <div className={`pdf_chat_input_box${isInputFocus ? ' select' : ''}`}>
                <input className={`pdf_chat_input`} ref={inputRef}
                    placeholder={inputStatus ? '문서에 관해서 무엇이든 물어보세요.' : '응답을 생성 중입니다.'}
                    disabled={!inputStatus} value={message}
                    onFocus={()=>{setIsInputFocus(true)}}
                    onBlur={()=>{setIsInputFocus(false)}}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => activeEnter(e)} />
                <div className='pdf_send_box'
                    onClick={sendChatBotMessage}>
                    <SendIcon className='pdf_send_button' />
                </div>
            </div>
        </div>
    );
}
export default FileChat;