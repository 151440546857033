import React, { useLayoutEffect, useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import PointDataGrid from '../components/grid/PointDataGrid'
import { TransactionSearchParam } from '../dto/Search';
import {TransactionItem} from '../dto/Items'
import { gridDateTime } from "../utils/TimeFomatter"

import {
    getBalance,
    getTransactions
} from '../api/payment/PaymentApi';
import '../css/point.css'

export default function Point() {
    const customer = JSON.parse(sessionStorage.getItem('customer') || '{}');

    const [balance, setBalance] = useState(customer.balance || 0);
    const [transactionList, setTransactionList] = useState<TransactionItem[]>([]);
    const [totalCount, setTotalCount] = useState(0);


    const getTransactionDatas = async (page?: number, limit?: number, search?: TransactionSearchParam) => {
        await getTransactions({
            customer_id: customer.id,
            page: page || 1,
            limit: limit || 10,
            search: search || {
                period: {
                    start: "",
                    end: ""
                },
                category: ""
            }
        },
            (response) => {
                if (response) {
                    setTransactionList(response.data.list);
                    setTotalCount(response.data.total_count)
                }
            },
            () => {

            }
        )
    }

    const amountCellRenderer = (props: any) => {
        return <div className='grid_amount_cell' style={{color: props.data.type === 'add' ? 'blue' : "red"}}>{props.data.amount + " " + (props.data.type === 'add' ? '▲' : '▼')}</div>
    }

    const pointColDefs = [
        { field: "id", hide: true },
        { headerName: 'Date', field: "create_at", cellRenderer: (props: any) => gridDateTime(props.data.create_at)},
        { headerName: 'Amount', field: "amount", cellRenderer: amountCellRenderer },
        { headerName: 'remain', field: "remain" },
        { headerName: 'description', field: "description", flex: 1 },
        { headerName: 'note', field: "note" },
    ];

    const onPageChange = (page: number, limit: number, search: TransactionSearchParam) => {
        getTransactionDatas(page, limit, search);
    }

    useLayoutEffect(() => {
        getBalance({ customer_id: customer.id },
            (response) => {
                if (response?.data?.balance) {
                    setBalance(response.data.balance)
                }
            },
            () => { }
        );
        getTransactionDatas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='home_contents'>
            <BreadCrumb items={[{ name: "홈", path: "/" }, { name: "포인트 정보" }]} />
            <div className='page_title'>포인트 정보</div>
            <div className='point_info_layout'>
                <div>
                    <div>현재 포인트</div>
                    <div className='ponit_balance_viewer'>{balance}</div>
                </div>
                <div className='point_charge_button request'>충전요청</div>
            </div>
            <div className='point_grid_layout'>
                <PointDataGrid rowData={transactionList} colDefs={pointColDefs} totalCount={totalCount} onRowClicked={()=>{}} onPageChange={onPageChange} />
            </div>
        </div>
    );

}