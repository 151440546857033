import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import './dataGrid.css'

interface PagenationProps {
    pageCount: number;
    onPageChange: (params: any)=> void; 
    currentPage: number; 
    totalCount: number;
}

const Pagination = ({ pageCount, onPageChange, currentPage, totalCount = 0 }: PagenationProps) => {

    const [page, setPage] = useState(0);

    useEffect(() => {
        setPage(currentPage - 1);
    }, [currentPage]);
    return (
        <div className="pagination_root ">
            <div className="total_count">총 개수 : (<span className="count_number">{totalCount}</span>)</div>
            <div className="pagination_wrapper">
                <ReactPaginate
                    forcePage={page}
                    previousLabel={<FiChevronLeft />}
                    nextLabel={<FiChevronRight />}
                    pageCount={pageCount}
                    onPageChange={onPageChange}
                    containerClassName={"pagination"}
                    pageLinkClassName={"pagination__link"}
                    activeLinkClassName={"pagination__link__active"}
                />
            </div>

        </div>
    );
};

export default Pagination;