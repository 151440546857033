import '../../css/kakao.css';
import skill from '../../assets/guide/skill.png';
import createSkill from '../../assets/guide/create_skill.png';
import skillName from '../../assets/guide/skill_name.png';
import skillUrl from '../../assets/guide/skill_url.png';

export default function Page3() {
    return (
        <div className='page_content'>
            <div className='sub_header'>스킬 생성</div>
            <div className='conter_contents'>
                <ul>
                    <li>1. 좌측 메뉴에서 스킬 선택 후 스킬 목록 클릭 <img className='guide_image' src={skill} width='200px' alt='select skill list' /></li>
                    <li>2. 우측 상단 생성 클릭 하고 스킬명을 '기본스킬'로 설정
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <img className='guide_image' src={createSkill} width='200px' alt='create skill' />
                            <img className='guide_image' src={skillName} width='450px' alt='skill name setting' />
                        </div>
                    </li>
                    <li>4. URL에https://dreamx.urdream.ai/dreamx/chatbot/qa/kakao 입력 <img className='guide_image' src={skillUrl} width='450px' alt='skill url setting' /></li>
                    <li>{'   '}</li>
                </ul>
            </div>
        </div>
    )
}