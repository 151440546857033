import React, { useEffect, useState } from "react";
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { serviceNameState } from '../hooks/atom/AtomState';
import { Home, Inventory, Chat, Analytics, /*AdminPanelSettings, ExpandMore,*/ ContentCopy } from '@mui/icons-material';
import Loading from '../components/iconify/loading';
import { ReactComponent as Close } from '../assets/icon/close.svg';
import '../css/side.css'
import {
    ChatMessageResponse,
    sendChatMessage
} from '../api/chatbot/ChatbotApi'

function Side() {
    const service_id = sessionStorage.getItem('service_id');
    const tempName = sessionStorage.getItem('service_name');
    const navigate = useNavigate();
    const customer = JSON.parse(sessionStorage.getItem('customer')|| '{}');

    const serviceName = useRecoilValue(serviceNameState) || tempName;
    const [submenuOpen, setSubmenuOpen] = useState([false, false, false, false]);
    const [helpMessage, setHelpMessage] = useState('');
    const [helpResponse, setHelpResponse] = useState('');
    const [isShowHelp, setIsShowHelp] = useState(false);
    const [isInputDisabled, setIsInputDisabled] = useState(false);

    const sendChatBotMessage = async () => {
        setIsInputDisabled(true)
        const item = findCurrentMenuItem()
        const utterance = `${"상태: 현재 페이지 - " + item.title + " \n 질문 :" + helpMessage}`;
        await sendChatMessage(
            {
                utterance: utterance,
                user_name: customer.id,
                service_id: process.env.REACT_APP_GUIDE_SERVICE_ID || ""
            },
            (response: AxiosResponse<ChatMessageResponse, any> | undefined) => {
                if (response) {
                    const returnText = response.data?.template?.outputs[0]?.simpleText?.text || '';
                    setHelpMessage('');
                    setHelpResponse(returnText);
                    setIsShowHelp(true);
                    setIsInputDisabled(false);
                }
            }
            ,
            (status) => {
                setHelpMessage('');
                setHelpResponse("오류가 발생 했습니다. 잠시 후 다시 시도해 주세요.");
                setIsShowHelp(true);
                setIsInputDisabled(false);
                console.log("=========================== FAIL : " + status)
            }
        )
    }

    const findCurrentMenuItem = () => {
        let path = window.location.pathname.substring(1);
        if (path.length === 0) {
            path = "/"
        }
        for (const index in Menus) {
            var menuItem = Menus[index];
            if (menuItem.path === path) {
                return menuItem;
            }
        }

        return { id: 1, title: "Home", icon: <Home />, path: "/" }
    }

    const isSelected = (index: number /*, subIndex: number | undefined = undefined*/) => {
        const path = window.location.pathname.substring(1);
        const menuPath = Menus[index].path.length > 1 ? Menus[index].path : ""
        const submenuItems = Menus[index].submenuItems;
        let isSelected = menuPath === path;
        if (!isSelected && submenuItems && submenuItems.length > 0) {
            for (const item of submenuItems) {
                if (path === item.path) {
                    isSelected = true;
                    break;
                }
            }
        }
        return isSelected ? "menu_item_selected" : "menu_item_unselected";
    }

    const activeEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            if (helpMessage?.length > 0) {
                sendChatBotMessage();
            }
        }
    }

    const onSubmenuClick = (index: number, flag: boolean) => {
        let temp = [...submenuOpen]
        temp[index] = flag ? true : !submenuOpen[index]
        setSubmenuOpen(temp);
    }

    const Menus = process.env.REACT_APP_GUIDE_SERVICE_ID == service_id ?
    [
        { id: 1, title: "홈", icon: <Home />, path: "/", submenuItems: undefined },
        {
            id: 2, title: "상품정보", icon: <Inventory />, path: "product",
            submenuItems: [
                { title: "지식정보", path: "knowledge" },
                { title: "지식정보 상세", path: "knowledge_detail" },
            ]
        },
        {
            id: 3, title: "챗봇설정", icon: <Chat />, path: "chatbotsetting",
        },
        {
            id: 4,
            title: "상담분석", icon: <Analytics />, path: "analytics",
        },
        {
            id: 4,
            title: "상담내용", icon: <Analytics />, path: "chatlog",
            submenuItems: [
                { title: "상담내용 상세", path: "conversation" },
            ]
        },
        {
            id: 5,
            title: "사용자정보", icon: <Analytics />, path: "profile",
        },
        {
            id: 6,
            title: "포인트정보", icon: <Analytics />, path: "point",
        },
        {
            id: 7,
            title: "환경설정", icon: <Analytics />, path: "settings",
        },
        
        {
            id: 8,
            title: "요청관리", icon: <Analytics />, path: "point_manage",
        },
    ]
    :
    [
        { id: 1, title: "홈", icon: <Home />, path: "/", submenuItems: undefined },
        {
            id: 2, title: "상품정보", icon: <Inventory />, path: "product",
            submenuItems: [
                { title: "지식정보", path: "knowledge" },
                { title: "지식정보 상세", path: "knowledge_detail" },
            ]
        },
        {
            id: 3, title: "챗봇설정", icon: <Chat />, path: "chatbotsetting",
        },
        {
            id: 4,
            title: "상담분석", icon: <Analytics />, path: "analytics",
        },
        {
            id: 4,
            title: "상담내용", icon: <Analytics />, path: "chatlog",
            submenuItems: [
                { title: "상담내용 상세", path: "conversation" },
            ]
        },
        {
            id: 5,
            title: "사용자정보", icon: <Analytics />, path: "profile",
        },
        {
            id: 6,
            title: "포인트정보", icon: <Analytics />, path: "point",
        },
        {
            id: 7,
            title: "환경설정", icon: <Analytics />, path: "settings",
        }
    ]

    useEffect ( ()=>{
    }, [serviceName])
    return (
        <div className="side">
            <div>
                <div className="service_name">{serviceName}</div>
                <ul className="sidebar_list">
                    {Menus.map((menu, index) => (
                        <div key={index} onClick={() => { onSubmenuClick(index, true); navigate(menu.path); }}>
                            <li className={`${isSelected(index)} sidebar_list_item`}>
                                {menu.icon ? menu.icon : <ContentCopy />}&nbsp;&nbsp;
                                <div > {menu.title} </div>
                            </li>
                        </div>
                    ))}
                </ul>
                <div className="kakao_setting_button" onClick={()=>navigate('/kakao_setting')}>
                    카카오톡<br/>설정방법
                </div>
            </div>
            <div>
                <div className="help_message_area" >
                    {
                        isShowHelp &&
                        <div className="help_message">
                            <div style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}>
                                <Close style={{ width: '15px', height: '15px' }} onClick={() => setIsShowHelp(false)} />
                            </div>
                            {helpResponse}
                        </div>
                    }
                </div>
                <div className="help_guide">도움이 필요하시면 여기에 질문 해 보세요.</div>
                <div>
                    <input className="help_input" value={helpMessage} disabled={isInputDisabled} onChange={(e) => setHelpMessage(e.target.value)} onKeyDown={(e) => activeEnter(e)} />
                    {
                        isInputDisabled &&
                        <div style={{ position: "absolute", left: "250px", bottom: "25px"}}>
                            <Loading width='20px' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default Side;