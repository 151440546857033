import { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as CheckIcon } from '../../assets/icon/check.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icon/close.svg';
import Loading from '../iconify/loading';

import { AxiosResponse } from 'axios';
import { TaskResponse } from '../../dto/ResponseData'
import { getRunningServiceTask } from '../../api/service/ServiceApi';

// ----------------------------------------------------------------------

export default function TaskIndicator({ status }: { status: number }) {
    const [openAnchor, setOpenAnchor] = useState<HTMLElement | undefined>(undefined);
    const [tasks, setTasks] = useState<TaskResponse[]>([]);

    const service_id = sessionStorage.getItem('service_id');

    const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (tasks && tasks.length > 0) {
            setOpenAnchor(event.currentTarget);
        }
    };

    useEffect(() => {
        if (status !== 0) {
            getRunningServiceTask({ id: service_id! },
                (response: AxiosResponse<TaskResponse[], any> | undefined) => {
                    if (response) {
                        setTasks(response.data)
                    }
                },
                () => {

                }
            )
        }
        else {
            setOpenAnchor(undefined);
        }
    }, [status]);

    return (
        <div>
            <div className='icon_circle task_background' onClick={(e) => { if (status !== 0) handleOpen(e); }}>
                {
                    status === 0 ?
                        <CheckIcon style={{ stroke: "#74c69d", fill: "#52b788", width: '24px', height: '24px' }} />
                        :
                        <>
                            {
                                status === 999 ?
                                    <ErrorIcon style={{ stroke: "#FF0000", fill: "#FF0000", width: '24px', height: '24px' }} />
                                    :
                                    <div style={{ marginTop: "-5px", marginLeft: '-5px' }}><Loading width='20px' /></div>
                            }
                        </>
                }
            </div>
            <Popover
                open={!!openAnchor}
                anchorEl={openAnchor}
                onClose={() => { setOpenAnchor(undefined); }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1,
                        ml: 0.75,
                        width: 200,
                    },
                }}
            >
                {
                    tasks.map((task) => {
                        const isFailed = task.status === 'failed';
                        const progress = task.progress;
                        return <MenuItem
                            key={task.task_id}
                            disableRipple
                            disableTouchRipple
                            sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div style={{ flexGrow: 1, width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', color: isFailed ? "#FF0000" : "#000000" }}>{progress?.file_name}</div>
                                {
                                    isFailed ?
                                        <ErrorIcon style={{ width: '15px', height: '15px', stroke: "#FF0000", fill: "#FF0000" }} />
                                        :
                                        <Loading width='20px' />
                                }
                            </div>

                        </MenuItem>
                    })
                }
            </Popover>
        </div>
    );
}
