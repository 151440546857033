import '../../css/kakao.css';
import chatbot from '../../assets/guide/move_chatbot.png';
import createBot from '../../assets/guide/create_chatbot.png';
import botName from '../../assets/guide/chatbot_name.png';

export default function Page2() {
    return (
        <div className='page_content'>
            <div className='sub_header'>챗봇 만들기</div>
            <div className='conter_contents'>
                <ul>
                    <li>1. 좌측 상단 채널 아래 챗봇 클릭 <img className='guide_image' src={chatbot} width='350px' alt='select chatbot' /></li>
                    <li>2. 봇 만들기 클릭 후 카카오톡 챗봇 선택 <img className='guide_image' src={createBot} width='300px' alt='select kakao chatbot' /></li>
                    <li>3. 챗봇 이름 설정 <img className='guide_image' src={botName} width='450px' alt='chatbot name setting' /></li>
                    <li>{'   '}</li>
                </ul>
            </div>
        </div>
    )
}