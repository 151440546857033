// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_contents {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.slider-container {
    display: block;
    width: calc(100vw - 325px);
    background-color: white;
}

.sub_page {
    display: flex;
}

.sub_header {
    font-size: 18px;
    font-weight: 600;
    margin: 25px;
}

.nav_header {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 5px;
}

.nav_button {
    padding: 5px 15px;
    border: solid 1px #d2d2d2;
    border-radius: 5px;
}

.nav_button.selected {
    background-color: #bdedf3;
}

.guide_image {
    border: solid 1px #d2d2d2;
    margin-top: 5px;
    margin-left: 10px;
    object-fit: contain;
}

.page_content {
    padding: 0px;
    margin: 0px;

}

.conter_contents ul {
    margin-left: 15px;
    width: fit-content;
}

.conter_contents li {
    margin-top: 15px;
    min-height: 20px;
    text-align: start;
}

.conter_contents {
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/css/kakao.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;;AAEf;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".home_contents {\n    width: 100%;\n    height: 100%;\n    overflow-y: scroll;\n    overflow-x: hidden;\n}\n\n.slider-container {\n    display: block;\n    width: calc(100vw - 325px);\n    background-color: white;\n}\n\n.sub_page {\n    display: flex;\n}\n\n.sub_header {\n    font-size: 18px;\n    font-weight: 600;\n    margin: 25px;\n}\n\n.nav_header {\n    display: flex;\n    justify-content: center;\n    gap: 8px;\n    margin-top: 5px;\n}\n\n.nav_button {\n    padding: 5px 15px;\n    border: solid 1px #d2d2d2;\n    border-radius: 5px;\n}\n\n.nav_button.selected {\n    background-color: #bdedf3;\n}\n\n.guide_image {\n    border: solid 1px #d2d2d2;\n    margin-top: 5px;\n    margin-left: 10px;\n    object-fit: contain;\n}\n\n.page_content {\n    padding: 0px;\n    margin: 0px;\n\n}\n\n.conter_contents ul {\n    margin-left: 15px;\n    width: fit-content;\n}\n\n.conter_contents li {\n    margin-top: 15px;\n    min-height: 20px;\n    text-align: start;\n}\n\n.conter_contents {\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
