//=============================== React ========================
import { useState, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//=============================== UI ===========================
import BreadCrumb from '../../components/BreadCrumb';
import DataGrid from '../../components/grid/DataGrid';
import { ArrowBack } from '@mui/icons-material';
//================================ External Library ==============

//=============================== API ==========================
import { KnowledgeItem } from '../../dto/Items';
import { SearchParam } from '../../dto/Search';
import {
    getKnowledgeList
} from '../../api/product/KnowledgeItemApi';

import { gridDateTime } from "../../utils/TimeFomatter"

import '../../css/product.css';

export default function Knowledge() {
    const location = useLocation();
    const fileId = location.state.fileId;
    const service_id = sessionStorage.getItem('service_id');

    const [knowledgeList, setKnowledgeList] = useState<KnowledgeItem[]>([]);
    const [currentTotalCount, setCurrentTotalCount] = useState(0);

    const navigate = useNavigate();

    const knowledgeColDefs = [
        { field: "id", hide: true },
        { headerName: 'Content', field: "content", flex: 6, cellClass: 'product_filename_cell' },
        { headerName: 'CreateAt', field: "date.create_at", flex: 1, cellRenderer: (props: any) => gridDateTime(props.data.date.create_at) },
        { headerName: 'UpdateAt', field: "date.update_at", flex: 1, cellRenderer: (props: any) => gridDateTime(props.data.date.update_at) },
    ];

    const getKnowledgeItemList = (page?: number, limit?: number, search?: SearchParam) => {
        getKnowledgeList({
            file_id: fileId,
            service_id: service_id!,
            page: page || 1,
            limit: limit || 10,
            search: search || {
                period: {
                    start: "",
                    end: ""
                },
                string: ""
            }
        },
            (filesResponse) => {
                if (filesResponse) {
                    setKnowledgeList(filesResponse.data?.list || []);
                    setCurrentTotalCount(filesResponse.data?.total_count);
                }
            }
            ,
            () => {
                setKnowledgeList([]);
                setCurrentTotalCount(0);
            }
        )
    }

    useLayoutEffect(() => {
        getKnowledgeItemList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='home_contents'>
            <BreadCrumb items={[
                { name: "홈", path: "/" },
                { name: "상품정보", path: "/product" },
                { name: "지식정보" }
            ]} />
            <div className='page_title'><div style={{cursor: "pointer"}} onClick={()=>navigate('/product')}><ArrowBack /></div>지식정보</div>
            <div className='product_grid_wrapper'>
                <DataGrid colDefs={knowledgeColDefs} rowData={knowledgeList} totalCount={currentTotalCount}
                    onPageChange={getKnowledgeItemList}
                    onRowClicked={(event) => { console.log(event); navigate('/knowledge_detail', { state: { id: event.node.data.id, content: event.node.data.content, fileId: fileId } }); }}
                />
            </div>

        </div>
    );
}