import React, { useState, useLayoutEffect, useEffect } from 'react';
import usePopup from "../../hooks/popup/usePopup";

import FileUploder from '../components/FileUploder'
import FileItem from '../components/FileItem'
import {
    getProductList,
    deleteProduct,
} from '../../api/product/ProductApi';
import {
    getPdfFileList
} from '../../api/product/KnowledgeFileApi';
import { PdfFileItem } from '../../dto/Items';

import '../pdfchat.css'

interface LeftProps {
    className: string;
    onSessionSelect: (knowledge_set_id: number | undefined, session_id: string | undefined, file_name: string | undefined, file_status: number | undefined) => void;
    onStatusChange : (file_status: number | undefined) => void;
    onPointChange: ()=> void;
}


function Left(props: LeftProps) {
    const { showPopup } = usePopup();
    const customer_id = sessionStorage.getItem('customer_id');
    const service_id = sessionStorage.getItem('service_id');

    const [fileList, setFileList] = useState<PdfFileItem[]>([]);
    const [canUpload, setCanupload] = useState(true);
    const [selectedFile, setSelectedFile] = useState<PdfFileItem | undefined>();

    const getAllProductList = async () => {
        setFileList([]);
        getProductList({ customer_id: customer_id, service_id: service_id },
            (response) => {
                if (response?.data) {
                    let setList: number[] = [];
                    for (let item of response.data?.list) {
                        setList.push(item.id);
                    }
                    getPdfFileList({ knowledge_set_ids: setList },
                        (fileResponse) => {
                            if (fileResponse?.data) {
                                setFileList(fileResponse.data?.list || [])
                                if (fileResponse.data?.list.length > 0) {
                                    const firstFile = fileResponse.data?.list[0];
                                    console.log("===================== list id : " + firstFile?.id)
                                    if(!selectedFile) {
                                        setSelectedFile(firstFile);
                                        props.onSessionSelect(firstFile.set_id, firstFile.session_id, firstFile.name, firstFile.status);
                                    } 
                                    var check = true;
                                    for (let file of fileResponse.data?.list) {
                                        if (file.status !== 0 && file.status !== 999) {
                                            check = false;
                                            setTimeout(()=>{
                                                fileStatusCheck(setList, selectedFile? selectedFile : firstFile);
                                            }, 5000);
                                            break;
                                        }
                                        props.onPointChange();
                                    }
                                    setCanupload(check);
                                }
                                else {
                                    setSelectedFile(undefined);
                                    props.onSessionSelect(undefined, undefined, undefined, undefined);
                                }
                            }
                        },
                        (status, detail) => {
                            showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                        })
                }
            },
            (status, detail) => {
                showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
            });

    }

    const fileStatusCheck = (ids: number[], file: PdfFileItem | undefined) => {
        if (ids.length > 0) {
            getPdfFileList({ knowledge_set_ids: ids },
                (fileResponse) => {
                    if (fileResponse?.data && fileResponse.data?.list.length > 0) {
                        setFileList(fileResponse.data?.list || [])
                        console.log("===================== selectedFile : " + file?.id)
                        props.onStatusChange(fileResponse.data?.list.find((item) => file?.id ==item.id )?.status);
                        for (let file of fileResponse.data?.list) {
                            if (file.status !== 0 && file.status !== 999) {
                                setCanupload(false);
                                setTimeout(()=>{
                                    fileStatusCheck(ids, file);
                                }, 5000);
                                return;
                            }
                        }
                        setCanupload(true);
                        props.onPointChange();
                    }
                },
                (status, detail) => {
                    showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                })
        }
    }

    const handleFileDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, knowledge_set_id: number) => {
        e.preventDefault();
        e.stopPropagation();
        showPopup({
            title: "확인",
            type: 'confirm',
            message: `해당 파일을 삭제하시겠습니까?`,
            confirm: () => {
                deleteProduct(
                    {
                        id: knowledge_set_id,
                        customer_id: customer_id!,
                        service_id: service_id!,
                    },
                    (response) => {
                        showPopup({ message: '삭제 되었습니다.' });
                        getAllProductList();
                    },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                    }
                )
            }
        });
    }

    const handleFileSelect = (id: number | undefined) => {
        const file = fileList.find((element) => element.id === id);
        if (file?.status === 0) {
            props.onSessionSelect(file.set_id, file.session_id, file.name, file.status);
            setSelectedFile(file)
        }
        else if (file?.status === 999){
            showPopup({message: "에러가 발생한 파일입니다."})
        }
        else {
            showPopup({message: "파일내용을 아직 처리 중입니다."})
        }
    }

    useLayoutEffect(() => {
        getAllProductList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(()=>{
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! : " + selectedFile?.id)
    },[selectedFile]);
    return (
        <div className={`${props.className}`}>
            <FileUploder onUploaded={getAllProductList} status={canUpload} />
            <div>
                {
                    fileList.map((item, index) => (
                        <FileItem key={index}
                            file={item}
                            isSelected={(selectedFile && selectedFile.id === item.id) || false}
                            onClick={(id) => { handleFileSelect(id) }}
                            onDeleteClick={(e, id) => { handleFileDelete(e, id) }}
                        />
                    ))
                }
            </div>
        </div>
    )
}
export default Left;