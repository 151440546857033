import React, { useState } from 'react';
import Layer from '../../components/Layer';
import './pointPopup.css'

import {
    updateChargeRequest,
} from '../../api/payment/PaymentApi'
import { StringLiteralLike } from 'typescript';

interface KnowledgeAddPopupProps {
    isShow: boolean;
    requestId: number;
    onClose: (isChanged: boolean) => void;
}

const RequestApprovePopup = ({ isShow, requestId, onClose }: KnowledgeAddPopupProps) => {
    const [declineNote, setDeclineNote] = useState<string>('');
    const [isApprove, setIsApprove] = useState(true);
    const popupClose = (isChanged: boolean) => {
        setDeclineNote("");
        onClose(isChanged);
    }

    const executeApproval = async (type: number, note?:string) => {
        await updateChargeRequest({id: requestId, type: type, note: note}, 
            (response) => {
                popupClose(true);
            }, 
            (status, detail) => {

            })
    }

    

    return (
        <Layer isShow={isShow}>
            <div className='point_approval_layout'>
                <div className='point_approval_popup_title'>포인트 요청 관리</div>
                <div>
                    <div className='point_approval_select'>
                        <input type="radio" value="option1" checked={isApprove} onClick={() => { setIsApprove(true) }} />승인 &nbsp;&nbsp;&nbsp;
                        <input type="radio" value="option2" checked={!isApprove} onClick={() => { setIsApprove(false) }} />거부
                    </div>
                    {
                        !isApprove &&
                        <div className='point_approval_popup_input_layout'>
                            <div className='point_decline_title'>거부 사유</div>
                            <textarea className='popup_edit_area' maxLength={1000} value={declineNote} onChange={(e) => { setDeclineNote(e.target.value); }} />
                        </div>
                    }
                </div>
                <div className='product_popup_button_layer'>
                    <button className={"popup_button " + (isApprove ? "popup_confirm" : "popup_decline")} onClick={() => {executeApproval((isApprove ? 0 : 2), (isApprove ? "" : declineNote));}}>{isApprove ? "승인" : "거부"}</button>
                    <button className="popup_button popup_cancel" onClick={() => popupClose(false)}>{"취소"}</button>
                </div>
            </div>
        </Layer>
    );
};
export default RequestApprovePopup;