interface AsyncResult {
    text: string | undefined;
    referance: any[] | undefined;
    isDone: boolean;
}
export class AsyncManager {

    private static parseJsonObjects = (input: string): any[] => {
        try {
            let objects: string[] = [];
            let buffer: string = "";
            let depth: number = 0;

            for (let char of input) {
                buffer += char;
                if (char === "{") depth++;      // 중괄호 열기 감지
                if (char === "}") depth--;      // 중괄호 닫기 감지
                if (depth === 0 && buffer.trim() !== "") {
                    objects.push(buffer.trim());  // 개별 JSON 객체 저장
                    buffer = "";  // 버퍼 초기화
                }
            }
            // JSON 객체를 파싱하여 배열로 변환
            let jsonArray: any[] = objects.map(obj => JSON.parse(obj));
            return jsonArray;
        } catch (error) {
            console.error("JSON 변환 오류:", error);
            return [];
        }
    }

    static readAsync = (reader: ReadableStreamDefaultReader, session_id: string, text: string, arr: any[]): Promise<AsyncResult|void> => {
        return new Promise<AsyncResult|void>((resolve, reject) => {
            let result = text;
            reader.read().then(({ done, value }) => {
                if (value) {
                    try {
                        const objArray = AsyncManager.parseJsonObjects(value);
                        objArray.forEach((obj: { type: string; session_id: string; value: string; knowledge: any }) => {
                            if (obj.type === 'final') {
                                if (obj.session_id) {
                                    session_id = obj.session_id;
                                }
                                if (obj.knowledge) {
                                    arr.push(obj.knowledge);
                                }
                            }
                            if (obj.type === 'token') {
                                result += obj.value;
                            }
                        });
                        resolve({ text: result, referance: arr, isDone: false });
                    }
                    catch (error) {
                        resolve({ text: "오류가 발생 했습니다. 잠시 후 다시 시도해 주세요.", referance: undefined, isDone: true });
                    }
                }
                if (done) {
                    resolve ({ text: result, referance: arr, isDone: true });
                }
            });
        
        })
    }

}