//=============================== React ========================
import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";
//=============================== Utils ========================
import { useRecoilValue } from 'recoil';
import { serviceState } from '../../hooks/atom/AtomState';
import { useInterval } from '../../utils/timerUtils'
//=============================== UI ===========================
import BreadCrumb from '../../components/BreadCrumb';
import Loading from '../../components/iconify/loading';
import DataGrid from '../../components/grid/DataGrid';
import usePopup from "../../hooks/popup/usePopup";
import ProductAddPopup from '../../popup/product/ProductAddPopup'
import ProductEditPopup from '../../popup/product/ProductEditPopup'
import FileUploadPopup from '../../popup/product/FileUploadPopup'
import KnowledgeAddPopup from '../../popup/product/KnowledgeAddPopup'

import { ReactComponent as Trash } from '../../assets/icon/trash-bin.svg';
import { ReactComponent as CloseButton } from '../../assets/icon/close.svg';
import { ReactComponent as CheckIcon } from '../../assets/icon/check.svg';

import '../../css/product.css';
//================================ External Library ==============
import type { ColDef } from 'ag-grid-community'
//=============================== API ==========================
import { KnowledgeFileItem, ProductItem } from '../../dto/Items';
import {
    getProductList,
    deleteProduct,
} from '../../api/product/ProductApi';
import {
    getKnowledgeFileList,
    deleteKnowledgeFile,
} from '../../api/product/KnowledgeFileApi';


import { gridDateTime } from "../../utils/TimeFomatter"
import { SearchParam } from '../../dto/Search';

//---------------------------------------------------------------

export default function Product() {
    const customer_id = sessionStorage.getItem('customer_id');
    const service_id = sessionStorage.getItem('service_id');

    const { showPopup } = usePopup();
    const navigate = useNavigate();

    const currentServiceState = useRecoilValue(serviceState);

    const [productList, setProductList] = useState<ProductItem[]>([]);
    const [productId, setProductId] = useState<number>(-1);
    const [knowledgeFileList, setKnowledgeFileList] = useState<KnowledgeFileItem[]>([]);
    const [currentFileTotalCount, setCurrentfileTotalCount] = useState(0);

    const [addPopupShow, setAddPopupShow] = useState(false);
    const [editPopupShow, setEditPopupShow] = useState(false);
    const [uploadPopupShow, setUploadPopupShow] = useState(false);
    const [knowledgeAddPopupShow, setKnowledgeAddPopupShow] = useState(false);
    const [editProductName, setEditProductName] = useState('');

    const onProductChanged = (isChanged: boolean, type: string) => {
        switch (type) {
            case 'add':
                setAddPopupShow(false);
                if (isChanged) showPopup({ message: `상품이 추가 되었습니다.` });
                break;
            case 'edit':
                setEditPopupShow(false);
                if (isChanged) showPopup({ message: `상품명이 수정 되었습니다.` });
                break;
            case 'delete':
                if (isChanged) {
                    showPopup({ message: `상품명이 삭제 되었습니다.` });
                }
                break;
        }
        if (isChanged) getAllProductList();
    }

    const onKnowledgeChanged = (isChanged: boolean) => {
        setKnowledgeAddPopupShow(false);
        if (isChanged) getAllProductList();
    }

    const onUploaded = () => {
        getAllProductList();
    }
    const dateRenderer = (dateString: string) => {
        return gridDateTime(new Date(dateString));
    }

    const statusRenderer = (status: number) => {
        let statusStr = "";
        let statusIcon = null;
        switch (status) {
            case 0:
                statusStr = "완료";
                statusIcon = <CheckIcon style={{ width: '20px', height: '20px', stroke: "#74c69d", fill: "#52b788" }} />
                break;
            case 1:
                statusStr = "chunking";
                statusIcon = <Loading width='20px' />
                break;
            case 2:
                statusStr = "embedding";
                statusIcon = <Loading width='20px' />
                break;
            case 3:
                statusStr = "저장중";
                statusIcon = <Loading width='20px' />
                break;
            case 999:
            default:
                statusStr = "실패";
                statusIcon = <CloseButton style={{ width: '20px', height: '20px', stroke: "#FF0000", fill: "#FF0000" }} />
        }
        return <div style={{ display: 'flex', alignItems: "center" }}>{statusStr}&nbsp;&nbsp;{statusIcon}</div>
    }

    const DeleteButtonComponent = (props: any) => {
        return <Trash className='product_delete_icon'
            ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e: MouseEvent) => {
                    console.log("native event handler");
                    fileDeleteClick(e, props);
                };
            }}
        />;
    };

    const fileDeleteClick = (e: MouseEvent, props: any) => {
        e.preventDefault();
        e.stopPropagation();
        showPopup({
            type: 'confirm',
            title: ' 확인',
            message: '삭제 하시겠습니까?',
            confirmText: "삭제",
            cancelText: "취소",
            confirm: () => {
                deleteKnowledgeFile({ id: props.data.id }, () => {
                    getProductFileList(productId);
                    showPopup({ message: "삭제 완료 되었습니다." });
                },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : "삭제 실패 했습니다." });
                    });
            },
        });
    }

    const onFilePageChange = (page: number, limit: number, search: SearchParam) => {
        getProductFileList(productId, page, limit, search);
    };

    const colDefs: ColDef[] = [
        { field: "id", hide: true },
        { headerName: 'FileName', field: "name", flex: 5, cellClass: 'product_filename_cell' },
        { headerName: 'Status', field: "status", flex: 1, cellRenderer: (props: any) => statusRenderer(props.data.status), cellClass: 'product_filename_cell' },
        { headerName: 'UploadDate', field: "date", flex: 1, cellRenderer: (props: any) => dateRenderer(props.data.date), cellClass: 'product_filename_cell' },
        { headerName: 'Delete', field: "delete", cellRenderer: DeleteButtonComponent, flex: 1 },
    ];

    const getAllProductList = () => {
        getProductList({ customer_id: customer_id, service_id: service_id },
            (response) => {
                if (response?.data) {
                    setProductList(response.data.list)
                    if (!response.data.list.some((item) => {
                        return item.id === productId
                    })) {
                        setProductId(response.data?.list[0]?.id || -1);
                        if (response.data?.list[0]?.id > 0) {
                            getProductFileList(response.data?.list[0]?.id);
                        }
                    }
                    else {
                        getProductFileList(productId);
                    }
                }
            },
            (status) => {
                console.log("======= fail ========= : " + status);
            });
    }

    const onProductSelectChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedProductId = Number(event.target.value);
        setProductId(selectedProductId);
        getProductFileList(selectedProductId);
    }

    const getProductFileList = (id: number, page?: number, limit?: number, search?: SearchParam) => {
        getKnowledgeFileList({
            knowledge_set_id: id,
            page: page || 1,
            limit: limit || 10,
            search: search || {
                period: {
                    start: undefined,
                    end: undefined
                }
            }
        },
            (filesResponse) => {
                if (filesResponse) {
                    setKnowledgeFileList(filesResponse.data?.list || []);
                    setCurrentfileTotalCount(filesResponse.data?.total_count);
                }
            }
            ,
            () => {
                setKnowledgeFileList([]);
                setCurrentfileTotalCount(0);
            }
        )
    };

    const showEditPopup = () => {
        for (let item of productList) {
            if (item.id === productId) {
                setEditProductName(item.name);
            }
        }

        setEditPopupShow(true)
    }

    const showDeletePopup = () => {
        showPopup({
            title: "확인",
            type: 'confirm',
            message: `해당 상품을 삭제하시겠습니까?`,
            confirm: () => {
                deleteProduct(
                    {
                        id: productId,
                        customer_id: customer_id!,
                        service_id: service_id!,
                    },
                    (response) => {
                        onProductChanged(true, 'delete')
                    },
                    (status, detail) => {
                        onProductChanged(false, 'delete')
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                    }
                )
            }
        });
    }

    useLayoutEffect(() => {
        getAllProductList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(() => {
        if (currentServiceState !== 0 && productId > 0) {
            getProductFileList(productId);
        }
    }, 5000);

    return (
        <div className='home_contents'>
            <BreadCrumb items={[{ name: "홈", path: "/" }, { name: "상품정보" }]} />
            <div className='page_title'>상품정보</div>
            <div className='product_header'>
                <select className='product_selector' value={productId} onChange={onProductSelectChanged}>
                    <option disabled hidden value={-1}>상품을 추가 해 주세요</option>
                    {
                        productId < 0 ?
                            <option className='product_option' value={-1}>상품을 추가 해 주세요</option>
                            :

                            productList.map((item, index) => {
                                return <option key={"product_select_" + index} className='product_option' value={item.id} >{item.name}</option>
                            })

                    }
                </select>
                <div className='product_button_area'>
                    {
                        productId >= 0 ?
                            <>
                                <button className='product_button product_delete' onClick={() => showDeletePopup()}>상품 삭제</button>
                                <button className='product_button product_edit' onClick={() => showEditPopup()}>상품명 수정</button>
                            </>
                            :
                            <></>
                    }
                    <button className='product_button product_add' onClick={() => setAddPopupShow(true)} >상품 추가</button>
                </div>
            </div>
            <div className='product_file_upload_button_area'>
                <button className='product_button product_knowledge_add' onClick={() => setKnowledgeAddPopupShow(true)}>지식 추가</button>
                <button className='product_button product_add' onClick={() => setUploadPopupShow(true)}>파일 업로드</button>
            </div>
            <div className='product_grid_wrapper'>
                <DataGrid colDefs={colDefs} rowData={knowledgeFileList} totalCount={currentFileTotalCount} onRowClicked={(event) => { navigate('/knowledge', { state: { productId: productId, fileId: event.node.data.id } }); }} onPageChange={onFilePageChange} />
            </div>
            <ProductAddPopup isShow={addPopupShow} service_id={service_id || ""} customer_id={customer_id || ""} onClose={onProductChanged} />
            <ProductEditPopup isShow={editPopupShow} product_id={productId} product_name={editProductName} onClose={onProductChanged} />
            <FileUploadPopup isShow={uploadPopupShow} productId={productId} onClose={() => { setUploadPopupShow(false) }} onUploaded={onUploaded} />
            <KnowledgeAddPopup isShow={knowledgeAddPopupShow} productId={productId} onClose={onKnowledgeChanged} />
        </div>
    )
}