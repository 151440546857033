import { useEffect, useRef } from 'react';

interface FileOptionProps {
    isError: boolean;
    onClose: () => void;
    onDelete: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onCodeCopy: () => void;
}

const FileOption = ({ isError, onClose, onCodeCopy, onDelete }: FileOptionProps) => {
    const modalRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
                onClose();
            }
        };
        window.addEventListener('mousedown', handleClick);
        return () => window.removeEventListener('mousedown', handleClick);
    }, [modalRef]);

    return (
        <div className="more_list" ref={modalRef}>
            {
                !isError &&
                <>
                    <div className='more_item' onClick={onCodeCopy} style={{ borderRadius: "8px 8px 0px 0px" }}>Embed 코드 복사</div>
                    <div style={{ background: "#CCCCCC", width: "100%", height: "1px" }} />
                </>
            }
            <div className='more_item' onClick={onDelete} style={{ borderRadius: "0px 0px 8px 8px" }}>삭제</div>
        </div>
    );
}
export default FileOption;