import axios, { AxiosInstance, CreateAxiosDefaults, AxiosResponse } from 'axios';

const API_CONFIG: CreateAxiosDefaults = {
    baseURL: process.env.REACT_APP_BASE_URL + "/dreamx",
    responseType: 'json',
};

const DEFAULT_VALUE_FOR_ERROR_RESPONSE = {
    data: { message: '알수없는 에러' },
    status: 500,
};

export interface ApiError {
    data: any;
    status: number;
    statusText: ApiErrorText;
}
export interface ApiErrorText {
    code: number;
    detail: string;
}

export default class APIService {
    private axiosClient: AxiosInstance;
    private static instance: APIService;

    private controller;

    private constructor() {
        this.axiosClient = axios.create(API_CONFIG);
        this.configureInterceptors();
        this.controller = new AbortController();
    }

    getFetchController = () => {
        return this.controller;
    }

    static getInstance = () => {
        if (!this.instance) {
            this.instance = new APIService();
        }
        return this.instance;
    };

    errorHandler = async (error: any): Promise<ApiError> => {
        const { response } = error;
        // TODO refresh Token 구현
        return response || DEFAULT_VALUE_FOR_ERROR_RESPONSE;
    };

    successHandler = (response: AxiosResponse) => {
        // const { data, status, statusText, headers } = response;

        // TODO refresh Token 구현
        return response;
    };

    configureInterceptors = () => {
        this.axiosClient.interceptors.response.use(
            (response) => this.successHandler(response),
            (error) => this.errorHandler(error)
        );
        this.axiosClient.interceptors.request.use((request) => request);
    };

    updateAuthToken = (token: string) => {
        this.axiosClient.defaults.headers.common.Authorization = `${token}`;
    };

    get = async (url: string) => {
        const res = await this.axiosClient.get(url).then((response) => response);
        return res;
    };

    delete = async (url: string) => {
        const res = await this.axiosClient.delete(url).then((response) => response);
        return res;
    };

    post = async (url: string, payload: any, config: any = undefined) => {
        let res;
        let access_token = sessionStorage.getItem('access_token');
        if (access_token) {
            access_token = "Bearer " + access_token;
        }
        if (config) {
            if (config.headers) {
                config.headers["Authorization"] = access_token;
            }
            else {
                config["headers"] = { Authorization: access_token }
            }
            res = await this.axiosClient.post(url, payload, config).then((response) => response);
        } else {
            res = await this.axiosClient.post(url, payload, { headers: { Authorization: access_token } }).then((response) => response);
        }
        if (res.status === 403) {
            sessionStorage.removeItem('customer_id');
            sessionStorage.removeItem('service_id');
            sessionStorage.removeItem('customer');
            sessionStorage.removeItem('access_token');
            window.location.reload();
            return undefined;
        }
        return res;
    };

    put = async (url: string, payload: any) => {
        const res = await this.axiosClient.put(url, payload).then((response) => response);
        return res;
    };

    patch = async (url: string, payload: any) => {
        const res = await this.axiosClient.patch(url, payload).then((response) => response);
        return res;
    };

    fetch = async (url: string, payload: any) => {
        let access_token = sessionStorage.getItem('access_token');
        if (access_token) {
            access_token = "Bearer " + access_token;
        }
        const signal = this.controller.signal;
        const results = await fetch(url, {
            method: 'post',
            signal: signal,
            headers: {
                Authorization: access_token || "",
                mode: 'no-cors',
                'Accept': 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload),
        })
        .then(res => { 
            return res
        })
        .catch((e)=>{
            console.log(e)
        })
        return(results);

    }
}
