import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { gridDateTime } from "../../utils/TimeFomatter"

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './dataGrid.css'

const DataSearch = ({ onSearch, onReset }: { onSearch: (searchString: string | null, startDate: string | null, endDate: string | null, isReset: boolean) => void; onReset?: ()=>void}) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [searchString, setSearchString] = useState<string | null>(null);

    const convertDate = (date: Date | null, isStart: boolean): Date | null => {
        if (!date) return null;
        console.log("date : " + date)
        let dateStr = gridDateTime(date, 'yyyy-MM-dd' + (isStart ? ' 00:00:00' : ' 23:59:59'))
        console.log("dateStr : " + dateStr)
        return new Date(dateStr);
    }

    const reset = () => {
        setStartDate(null);
        setEndDate(null);
        setSearchString('');
        if (onReset) onReset();
        onSearch(null, null, null, true);
    }

    return (
        <div className='data_serarch_wrapper'>
            <div className='label_text'>검색:</div>
            <input className='search_input' value={searchString || ''} onChange={(e) => setSearchString(e.target.value)} />
            &nbsp;&nbsp; <div className='label_text'>기간: </div>
            <div className='date_picker_wrapper'>
                <DatePicker showIcon isClearable dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date: Date | null) => setStartDate(convertDate(date, true))} />
            </div>
            -
            <div className='date_picker_wrapper'>
                <DatePicker showIcon isClearable dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => setEndDate(convertDate(date, false))} />
            </div>
            <button className='search_button' onClick={() => { onSearch(searchString, startDate ? gridDateTime(startDate) : null, endDate ? gridDateTime(endDate) : null, false) }}>검색</button>
            <button className='search_button' onClick={reset}>초기화</button>
        </div>
    );
};
export default DataSearch;