
export function copyToClipboard(data: string) : Promise<boolean> {
    if (typeof (navigator.clipboard) === 'undefined') {
        var textArea = document.createElement("textarea");
        textArea.value = data;
        textArea.style.position = "fixed";  //avoid scrolling to bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((resolve)=> {
            try {
                document.execCommand('copy');
                resolve(true);
            }
            catch (err) {
                resolve(false);
            }
            finally {
                document.body.removeChild(textArea)
            }
        });
    }
    return navigator.clipboard.writeText(data).then(()=>true, (err)=>false);
}

