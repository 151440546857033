import usePopup from "../hooks/popup/usePopup";
import { PopupProperty } from "./Popup";

import './popup.css';

export function ConfirmPopup(props: PopupProperty) {
    const { dismissPopup } = usePopup();

    const onConfirm = () => {
        dismissPopup();
        if (props.confirm) {
            props.confirm();
        }
    }
    const onCancel = () => {
        dismissPopup();
        if (props.cancel) {
            props.cancel();
        }
    }

    return (
        <div className='popup_wrapper'>
            <div className='popup_content'>
                <div className="popup_title">
                    {props.title || "알림"}
                </div>
                <div className="popup_message">{props.message || ""}</div>
                <div className="popup_button_layer">
                    <button className="popup_button popup_cancel" onClick={onCancel}>
                        {
                            props.cancelText || "취소"
                        }
                    </button>
                    <button className="popup_button popup_confirm" onClick={onConfirm}>
                        {
                            props.confirmText || "확인"
                        }
                    </button>
                </div>
            </div>
        </div>
    );
}