interface LoadingIconProps {
    width: string;
}

const Loading = (props: LoadingIconProps) => {
    const { width } = props;
    const boxStyle = {
        width: '70px',
        height:'20px',
    }
    return (
        <div style={boxStyle}>
            <div className='chat_textbox' style={{ display: 'flex' }} >
                <div className="loading_area">
                    <div className="dot1"></div>
                    <div className="dot2"></div>
                    <div className="dot3"></div>
                </div>
            </div>
        </div>
    );
}
export default Loading;