import './layer.css';

interface LayerProps {
    isShow: boolean;
    children: React.ReactNode[] | React.ReactNode;
}

const Layer = ({ children, isShow }: LayerProps) => {
    return (
        <div className='layer-base' style={{display: isShow? 'flex' : 'none'}}>
            <div className='layer-wrapper'>
                <div className='layer-content'>
                    {children}
                </div>
            </div>
        </div>
    );
};
export default Layer;