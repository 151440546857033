import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";

import BreadCrumb from '../../components/BreadCrumb';
import ChatLogDataGrid from '../../components/grid/ChatLogDataGrid';
import Loading from '../../components/iconify/loading';

import { gridDateTime } from "../../utils/TimeFomatter"

import { ConversationItem } from '../../dto/Items';
import { ChatLogSearchParam } from '../../dto/Search';
import {
    getConversationList,
} from '../../api/analytics/ChatLogApi';

import '../../css/chatlog.css';

export default function ChatLog() {
    const customer_id = sessionStorage.getItem('customer_id');
    const service_id = sessionStorage.getItem('service_id');
    const navigate = useNavigate();

    const [sessionList, setSessionList] = useState<ConversationItem[]>([]);
    const [sessionTotalCount, setSessionTotalCount] = useState<number>(0);

    const renderStstusCell = (status: number) => {
        switch (status) {
            default:
            case 0:
                return "대화 중";
            case 1:
                return "대화 종료";
            case 2:
                return <div style={{ display: "flex", alignItems: "center" }}>요약 중&nbsp;&nbsp;<Loading width='20px' /></div>;
            case 999:
                return "에러 발생";
        }
    };

    const answerColDefs = [
        { field: "id", hide: true },
        { headerName: 'date', field: "date", flex: 1.2, cellRenderer: (props: any) => gridDateTime(props.data.date) },
        { field: "session_id", hide: true },
        { headerName: 'Category', field: "category", flex: 1 },
        { headerName: 'Summary', field: "summary", flex: 5 },
        { headerName: 'status', field: "status", flex: 1, cellRenderer: (props: any) => renderStstusCell(props.data.status) },
        { field: "end", hide: true },
    ];

    const getSessionList = (id: string, page?: number, limit?: number, search?: ChatLogSearchParam) => {
        const nullSearch = { period: { end: undefined, start: undefined }, string: undefined, category: undefined, product: undefined };
        getConversationList({
            id: id,
            customer_id: customer_id!,
            page: page || 1,
            limit: limit || 10,
            search: search || nullSearch
        },
            (response) => {
                if (response) {
                    setSessionList(response.data?.list || []);
                    setSessionTotalCount(response.data?.total_count);
                }
            }
            ,
            () => {
                setSessionList([]);
                setSessionTotalCount(0);
            }
        )
    };

    const handleSessionPageChange =  (page: number, limit: number, search: ChatLogSearchParam)  => {
        getSessionList(service_id!, page, limit, search);
    };

    useLayoutEffect(() => {
        getSessionList(service_id!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='home_contents'>
            <BreadCrumb items={[{ name: "홈", path: "/" }, { name: "상담내용" }]} />
            <div className='chatlog_grid_wrapper'>
                <ChatLogDataGrid colDefs={answerColDefs} rowData={sessionList} totalCount={sessionTotalCount} 
                onRowClicked={(event) => { navigate('/conversation', { state: { data: JSON.stringify(event.node.data)} }); }} 
                onPageChange={handleSessionPageChange} />
            </div>
        </div>
    )

}