import React, { useState } from 'react';
import { ReactComponent as Close } from '../../assets/pdf_chat/close_2.svg';
import { ReactComponent as More } from '../../assets/pdf_chat/more.svg';
import { ReactComponent as PdfIcon } from '../../assets/pdf_chat/pdf.svg';
import LoadingBar from '../../components/iconify/LoadingBar';

import { PdfFileItem } from '../../dto/Items';
import FileOption from '../components/FileOption';

import { gridDateTime } from '../../utils/TimeFomatter'
import { copyToClipboard } from '../../utils/Clipboard';
import usePopup from "../../hooks/popup/usePopup";

import '../pdfchat.css'
import Layer from '../../components/Layer';

interface FileItemProps {
    file: PdfFileItem;
    isSelected: boolean;
    onClick: (file_id: number) => void;
    onDeleteClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, fileId: number, set_id: number) => void;
}

function FileItem({ file, isSelected, onClick, onDeleteClick }: FileItemProps) {
    const { showPopup } = usePopup();
    const [isMenuShow, setIsMenuShow] = useState(false);
    const [isHelpShow, setIsHelpShow] = useState(false);
    const [clipboardStr, setClipboardStr] = useState('');

    // const customer_id = sessionStorage.getItem('customer_id');
    const service_id = sessionStorage.getItem('service_id');

    const onCodeCopy = async () => {
        setIsMenuShow(false);
        let chatbot_url = `${process.env.REACT_APP_CHAT_URL}/external/pdf_chat?service_id=${service_id}&knowledge_set_id=${file.set_id}&user_name=사용자이름`;
        const str = `<embed src="${chatbot_url}" />`;
        copyToClipboard(str).then((result) => {
            if (result) {
                showPopup({
                    type: "confirm",
                    message: '클립보드에 복사 되었습니다.\n사용 방법을 보시겠습니까?',
                    confirmText: "확인하기",
                    cancelText: "취소",
                    confirm: () => {
                        setClipboardStr(str)
                        setIsHelpShow(true);
                    }
                });
            }
            else {
                showPopup({message: "복사에 실패 했습니다."});
            }
        })
    }
    const onMobileCodeCopy = async () => {
        setIsMenuShow(false);
        let chatbot_url = `${process.env.REACT_APP_CHAT_URL}/external/mobile/pdf_chat?service_id=${service_id}&knowledge_set_id=${file.set_id}&user_name=사용자이름`;
        // const str = `<embed src="${chatbot_url}" />`;
        copyToClipboard(chatbot_url).then((result) => {
            if (result) {
                showPopup({
                    message: '모바일 주소가 클립보드에 복사 되었습니다.',
                    confirmText: "확인",
                });
            }
            else {
                showPopup({message: "복사에 실패 했습니다."});
            }
        })
    }
    return (
        <div>
            <div className={`file_item_bg ${isSelected ? 'file_selected' : ''} ${file.status === 999 && "file_error"} ${file.status !== 0 && file.status !== 999 && "file_loading"}`} 
                onClick={() => { onClick(file.id) }}
                >
                <div className='file_item_info'>
                    <PdfIcon style={{ width: "24px", height: "24px" }} />
                    <div className='file_item_inner'>
                        <div className='file_item_name'>{file.name}</div>
                        <div className='file_item_date' >{gridDateTime(new Date(file.date), 'yyyy-MM-dd')}</div>
                    </div>
                    <More className='more_icon' onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsMenuShow(true) }} />
                </div>

                {
                    file.status !== 0 && file.status !== 999 &&
                    <div style={{ marginTop: "5px", marginLeft: "12px" }}><LoadingBar /></div>
                }

            </div>
            {isMenuShow &&
                <FileOption isError={file.status === 999} onClose={() => { setIsMenuShow(false) }} onCodeCopy={onCodeCopy} onMobileCodeCopy={onMobileCodeCopy} onDelete={(e) => { setIsMenuShow(false); onDeleteClick(e, file.id, file.set_id); }} />
            }
            {
                isHelpShow &&
                <Layer isShow={isHelpShow}>
                    <div className='pdf_code_popup'>
                        <div className='pdf_code_header'>
                            <div>{"Embed Code 사용법"}</div>
                            <Close onClick={() => { setIsHelpShow(false) }} />
                        </div>
                        <div className='pdf_terms_body'>
                            <div>복사된 코드는 아래와 같은 형태입니다.</div>

                            <div className='embed_code'>{clipboardStr}</div>

                            <div>이 코드를 사용하기 원하시는 웹페이지 내용에 추가 하시면 해당 화면 우측 하단에 버튼이 생성되며</div>
                            <div>그 버튼을 통해서 채팅 인터페이스를 사용 하실 수 있습니다.</div>
                            <br />
                            <div>코드에서 사용자 이름 부분은 생략 하실 수 있으며, 해당 서비스를 사용하는 사용자의 이름 또는 ID 를 입력하시면 됩니다.</div>

                        </div>
                    </div>
                </Layer>
            }
        </div>
    )
}
export default FileItem;