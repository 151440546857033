import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import usePopup from "../../hooks/popup/usePopup";

import { customerSocialLogin, customerSocialRegistration } from '../../api/auth/AuthApi';
import { CustomerResponse } from '../../dto/ResponseData'
import { SocialRegistrationParams } from '../../dto/RequestParams'
import { AxiosResponse } from "axios";
import google_icon from '../../assets/pdf_chat/google_login.png'

import '../../css/login.css'
import '../../pdfchat/pdfchat.css'

interface GoogleLoginProps {
    state: string;
    type: string;
}

const GoogleLoginButton = ({ state, type }: GoogleLoginProps) => {
    const { showPopup } = usePopup();
    const navigate = useNavigate();

    const login = useGoogleLogin({
        onSuccess: codeResponse => onLoginSuccess(codeResponse),
        onError: () => {
            showPopup({ message: "로그인에 실패 했습니다." });
        }
    });

    const onLoginSuccess = async (response: any) => {
        customerSocialLogin({
            type: "google",
            code: response.access_token,
            state: undefined
        },
            (response) => {
                if (response) {
                    if (response) {
                        const data: CustomerResponse = response.data;
                        if (data.access_token) {
                            sessionStorage.setItem('customer_id', data.id);
                            sessionStorage.setItem('service_id', data.service_id);
                            sessionStorage.setItem('service_name', data.service_name);
                            sessionStorage.setItem('customer', JSON.stringify(data));
                            sessionStorage.setItem('access_token', data.access_token);
                            sessionStorage.setItem('account_type', "google");
                            sessionStorage.setItem('user_email', data.email);
                            sessionStorage.setItem('service_type', state);
                            if (window.location.pathname.indexOf('/mobile/') >= 0 ) {
                                navigate('/mobile/pdf_chat');
                            }
                            else {
                                navigate('/pdf_chat');
                            }
                            window.location.reload();
                        }
                        else {
                            onRegistration(data.email, "google");
                        }
                    }
                }
            },
            (status, detail) => {
                if (detail) {
                    showPopup({ message: detail.message });
                }
                else {
                    showPopup({ message: `Error(${status})\n실패 했습니다.` });
                }
            }
        )
    }

    const onRegistration = (email: string, type: string) => {

        const registrationParams: SocialRegistrationParams = {
            name: email,
            email: email,
            type: type
        }
        customerSocialRegistration(registrationParams,
            (response: AxiosResponse<CustomerResponse, any> | undefined) => {
                if (response) {
                    const data: CustomerResponse = response.data;
                    if (data.access_token) {
                        sessionStorage.setItem('customer_id', data.id);
                        sessionStorage.setItem('service_id', data.service_id);
                        sessionStorage.setItem('service_name', data.service_name);
                        sessionStorage.setItem('customer', JSON.stringify(data));
                        sessionStorage.setItem('access_token', data.access_token);
                        sessionStorage.setItem('account_type', "google");
                        sessionStorage.setItem('user_email', data.email);
                        navigate('/pdf_chat');
                        // window.location.reload();
                    }
                    else {
                        showPopup({ message: `에러가 발생 했습니다.` });
                    }
                }
            },
            (status, detail) => {
                if (detail) {
                    showPopup({ message: detail.message });
                }
                else {
                    showPopup({ message: `Error(${status})\n실패 했습니다.` });
                }
            });
    }

    return (
        <button onClick={() => login()} className={type === "pdf_chat" ? "pdf_login_button" : "social_login_button"}>
            {
                type === "pdf_chat" ?
                    <img src={google_icon} alt="구글 로그인 버튼"/>
                    :
                    <img src="https://www.svgrepo.com/show/355037/google.svg" className="social_login_icon" alt="구글 로그인 버튼"/>
            }
        </button>
    );
};
export default GoogleLoginButton