import '../../css/kakao.css';
import setting from '../../assets/guide/chatbot_setting.png';
import aiSetting from '../../assets/guide/chatbot_ai.png';

export default function Page4() {
    return (
        <div className='page_content'>
            <div className='sub_header'>AI 챗봇 설정</div>
            <div className='conter_contents'>
                <ul>
                    <li>1. 좌측 설정 클릭<img className='guide_image' src={setting} width='150px' alt='setting click' /></li>
                    <li>2. AI 챗봇 관리 클릭 후 <span style={{ fontWeight: '700' }}>'AI 챗봇 전환'</span> 클릭<img className='guide_image' src={aiSetting} width='400px' alt='select chatbot managing' /></li>
                    <li>3. 목적과 요청 사유 작성<br />
                        (ex:<br />
                        &nbsp;&nbsp;목적: 챗봇에 생성 모델로 구현된 ai api 적용을 위해<br />
                        &nbsp;&nbsp;사유: ai api서버에서 응답속도가 모델을 거쳐 나와 5초를 초과하여 필요)</li>
                    <li>4. ai 챗봇으로 전환 승인이 날 때 까지 <span style={{ fontWeight: '700', color: 'red' }}>1~2일</span> 정도 대기</li>
                    <li>{'   '}</li>
                </ul>
            </div>
        </div>
    )
}
