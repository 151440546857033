import React, { useState, useRef } from 'react';
import Layer from '../../components/Layer';
import { ReactComponent as CloseButton } from '../../assets/icon/close.svg';
import Loading from '../../components/iconify/loading';
import './productPopup.css'

import usePopup from '../../hooks/popup/usePopup';
import { knowledgeFileUpload, knowledgeFileUploadConfirm } from '../../api/product/KnowledgeFileApi';

interface FileUploadPopupProps {
    isShow: boolean;
    productId: number;
    onClose: () => void;
    onUploaded: (fileId: number) => void;
}


interface FileItem {
    id: number;
    name: string;
    object: File;
}

export default function FileUploadPopup({ isShow, productId, onUploaded, onClose }: FileUploadPopupProps) {
    const { showPopup } = usePopup();

    const customer_id = sessionStorage.getItem('customer_id');
    const service_id = sessionStorage.getItem('service_id');

    const inputRef = useRef<HTMLInputElement>(null);
    // const [isDragging, setIsDragging] = useState(false);
    const [files, setFiles] = useState<FileItem[]>([]);
    const [uploadfiles, setUploadFiles] = useState<File[]>([]);

    const [isUploading, setIsUploading] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);

    const onFileDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onFileDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // setIsDragging(false);
    }

    const onFileDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // if (e.dataTransfer && e.dataTransfer.files) {
        //     setIsDragging(true);
        // }
    }

    const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer && e.dataTransfer.files) {
            onChangeFiles(e);
            // setIsDragging(false);
        }
    }

    const handleUploadButtonClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
        let selectFiles: FileList | null;
        let tempFiles = files;
        let tempUploadFile = uploadfiles;
        if (e.type === "drop") {
            selectFiles = (e as React.DragEvent<HTMLDivElement>).dataTransfer.files;
        } else {
            selectFiles = (e.target as HTMLInputElement).files;
        }
        if (selectFiles) {
            let index = 0;
            for (const file of Array.from(selectFiles)) {
                tempFiles = [
                    ...tempFiles,
                    {
                        id: tempFiles.length + index,
                        name: file.name,
                        object: file
                    }
                ];
                tempUploadFile.push(file);
                index++;
            }
        }
        setFiles(tempFiles);
        setUploadFiles(tempUploadFile);
        if (inputRef?.current?.value) inputRef.current.value = '';
    }

    const deleteFile = (id: number) => {
        let temp = [];
        let tempupload: File[] = [];
        let index = 0;
        for (let file of files) {
            if (file.id !== id) {
                file.id = index;
                temp.push(file);
                tempupload.push(file.object);
                index++;
            }
        }
        if (inputRef?.current?.value) inputRef.current.value = '';
        setFiles(temp);
        setUploadFiles(tempupload);
    };

    const onUploadPopupClose = () => {
        setFiles([]);
        setUploadFiles([]);
        if (inputRef?.current?.value) inputRef.current.value = '';
        onClose();
    }

    const onUploadProgress = (event: ProgressEvent) => {
        const per = (event.loaded / event.total) * 100;
        if (per >= 100) {
            setProgressPercent(0);
        }
        else {
            setProgressPercent(parseFloat(per.toFixed(2)));
        }
    };

    const fileUpload = (uploadfiles: File[]) => {
        if(uploadfiles.length === 0) {
            showPopup({ message: "업로드 하실 파일을 선택 해 주세요." });
            return;
        }
        const formData = new FormData();
        formData.append("id", String(productId));
        uploadfiles.forEach(file => {
            formData.append("files", file);
        });
        setIsUploading(true);

        knowledgeFileUpload(formData, onUploadProgress,
            (response) => {
                if (response?.data) {
                    setProgressPercent(100);
                    knowledgeFileUploadConfirm({
                        customer_id: customer_id!,
                        service_id: service_id!,
                        knowledge_set_id: productId,
                        file_list: response.data
                    },
                        (confirmResponse) => {
                            showPopup({ message: "업로드가 완료 되었습니다." });
                            setIsUploading(false);
                            setProgressPercent(0);
                            onUploadPopupClose();
                            onUploaded(response.data[response.data.length - 1].file_id);
                        },
                        (confirmStatus, detail) => {
                            showPopup({ message: detail ? detail.message : `Error(${confirmStatus})\n업로드에 실패 했습니다.` });
                        }
                    )
                }
            },
            (status, detail) => {
                showPopup({ message: detail ? detail.message : `Error(${status})\n업로드에 실패 했습니다.` });
            });
    }
    return (
        <Layer isShow={isShow}>
            <div className='file_upload_popup'>
                <div className='product_popup_title'>카달로그 업로드 <CloseButton style={{ width: '24px', height: '24px', cursor: 'pointer' }} onClick={onUploadPopupClose} /></div>
                <div className='file_upload_contents'>
                    <div className='file_drop_box'
                        // htmlFor="fileUpload"
                        onDragEnter={onFileDragEnter}
                        onDragLeave={onFileDragLeave}
                        onDragOver={onFileDragOver}
                        onDrop={onFileDrop}
                        onClick={handleUploadButtonClick}
                    >
                        <div>파일 선택<br /><span style={{ fontSize: '15px' }}>또는 파일 여기에 두기</span></div>
                    </div>
                    <div className='file_upload_info'>
                        {'파일명'}
                        <div className='file_upload_list'>
                            {
                                files.map((file, index) =>
                                    <div key={"file_" + index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>{file.name}</div>
                                        <CloseButton style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => { deleteFile(file.id) }} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <progress style={{ display: `${isUploading ? "block" : "none"}`, width: '100%' }} max="100" value={progressPercent} />
                    </div>
                    {
                        progressPercent === 100 &&
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px', width: '100%', textAlign: 'center' }}>
                            <Loading width='20px' /> 데이터를 처리 중입니다.
                        </div>
                    }

                    <input type="file" ref={inputRef} style={{ display: "none" }} onChange={onChangeFiles} />
                </div>
                <div className='upload_button_layer'>
                    <div className='upload_button' onClick={() => fileUpload(uploadfiles)} >파일 업로드</div>
                </div>
            </div>
        </Layer>
    );
};