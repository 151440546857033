import { AxiosResponse } from 'axios';
import APIService from '../APIService';
import { FileIdItem, KnowledgeItem } from '../../dto/Items'
import {GetKnowledgeListParams, GetKnowledgeParams, AddKnowledgeParams, UpdateKnowledgeParams, DeleteKnowledgeParams } from '../../dto/RequestParams'
import {KnowledgeListResponse} from '../../dto/ResponseData'

const prefix = 'knowledge/';

export async function  getKnowledgeList (params: GetKnowledgeListParams, success: (res: AxiosResponse<KnowledgeListResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix+ 'get_list', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getKnowledgeItem (params: GetKnowledgeParams, success: (res: AxiosResponse<KnowledgeItem, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function updateKnowledgeItem (params: UpdateKnowledgeParams, success: (res: AxiosResponse<string, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'update', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function deleteKnowledgeItem (params: DeleteKnowledgeParams, success: (res: AxiosResponse<string, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'delete', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function addKnowledgeItem (params: AddKnowledgeParams, success: (res: AxiosResponse<FileIdItem, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'add', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}