import { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Markdown from 'react-markdown'
import { ReactComponent as Logo } from '../../assets/pdf_chat/logo.svg';
import { ReactComponent as SendIcon } from '../../assets/pdf_chat/send.svg';
import { ReactComponent as BotIcon } from '../../assets/pdf_chat/bot.svg';

import ko_flag from '../../assets/icon/KO.jpg';
import us_flag from '../../assets/icon/US.gif';
import jp_flag from '../../assets/icon/JP.gif';
import cn_flag from '../../assets/icon/CN.gif';
import vn_flag from '../../assets/icon/VN.gif';
import th_flag from '../../assets/icon/TH.gif';

import { sendFileChatMessageStream } from '../../api/chatbot/ChatbotApi'

import { gridDateTime } from '../../utils/TimeFomatter';
import "../../pdfchat/pdfchat.css"
import { AsyncManager } from '../../pdfchat/manager/AsyckManager';

interface ChatMessage {
    message: string;
    isUser: boolean;
    time: Date;
    isloading?: boolean;
}

export default function Chat() {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const serviceId = params.get("service_id");
    const knowledge_set_id = params.get("knowledge_set_id");
    let userName = params.get("user_name");
    if (!userName) {
        userName = crypto.randomUUID();
    }

    const [inputStatus, setInputStatus] = useState(true);
    const [messageList, setMessageList] = useState<ChatMessage[]>([]);
    const [message, setMessage] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState<number>(0);
    const [isFlagListShow, setIsFlagListShow] = useState<boolean>(false);

    const flags = [
        { name: "한국어", icon: ko_flag },
        { name: "영어", icon: us_flag },
        { name: "일본어", icon: jp_flag },
        { name: "중국어", icon: cn_flag },
        { name: "베트남어", icon: vn_flag },
        { name: "태국어", icon: th_flag }
    ]

    const welcome_message = [
        "안녕하세요. 무엇을 도와드릴까요?",
        "Hello. How may I help you?",
        "こんにちは。何をお手伝いしますか？",
        "你好。我怎么帮你？",
        "Xin chào. Tôi có thể giúp gì cho bạn?",
        "สวัสดี. ฉันจะช่วยคุณได้อย่างไร?"
    ]

    const working_message = [
        "응답을 처리 중입니다.",
        "Processing response.",
        "応答を処理中です。",
        "正在处理响应。",
        "Phản hồi đang được xử lý.",
        "กำลังประมวลผลการตอบกลับ."
    ];

    const placeholder = [
        "문서에 관해 무엇이든 물어보세요.",
        "Ask anything about the document.",
        "文書について何でも聞いてください。",
        "询问有关您的文件的任何问题。",
        "Hỏi bất cứ điều gì về tài liệu của bạn.",
        "ถามอะไรก็ได้เกี่ยวกับเอกสารของคุณ"
    ]

    const sended = [
        "전송됨",
        "Sent",
        "送信済み",
        "发送",
        "Đã gửi",
        "ส่งแล้ว"
    ]

    const inputRef = useRef<HTMLInputElement>(null);

    const scrollRef = useRef<HTMLDivElement>(null);

    const addMessage = (message: string, isUser: boolean, time: Date) => {
        if (isUser) setMessage('');
        const botMessage = { message: working_message[selectedLanguage], isUser: false, isloading: true, time: new Date() };
        setMessageList(prevState => [...prevState, { message: message, isUser: isUser, time: time }, botMessage]);
        return botMessage;
    }

    const addResultMessage = (userMessage: string, id: string, userTime: Date, botMessage: string) => {
        setMessageList(prevState => {
            let temp = prevState;
            temp.splice(prevState.length - 2, 2)
            return [...temp, { message: userMessage, isUser: true, time: userTime }, { message: botMessage, id: +id, isUser: false, isloading: false, time: new Date() }]
        });
    }

    const selectFlag = (value: number) => {
        setSelectedLanguage(value);
        setIsFlagListShow(false);
    }

    const activeEnter = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            sendChatBotMessage();
        }
    }

    const scrollToBottom = () => {
        if (scrollRef.current) {
            setTimeout(() => { scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight; }, 200);
        }
    };

    const sendChatBotMessage = async () => {
        if (!inputStatus) return;
        const utterance = `${message}`;
        if (knowledge_set_id) {
            sendPdfChatMessage(utterance);
        }
    }

    const sendPdfChatMessage = async (question: string) => {
        if (!question) return;
        let sessionId = sessionStorage.getItem('sessionId') || undefined;
        const utterance = `${question}`
        let userTime = new Date();
        if (!inputStatus || utterance.length === 0) return;
        setInputStatus(false)
        const botMessage = addMessage(utterance, true, userTime);
        scrollToBottom();
        sendFileChatMessageStream({
            utterance: utterance,
            user_name: userName!,
            service_id: serviceId!,
            language: flags[selectedLanguage].name,
            knowledge_set_id: String(knowledge_set_id)!, // TODO
            session_id: sessionId
        },
            (chatResponse) => {
                if (chatResponse && chatResponse.body) {
                    const reader = chatResponse.body.pipeThrough(new TextDecoderStream()).getReader();
                    botMessage.message = '';
                    let currentSessionId = sessionStorage.getItem("currentSessionId");
                    readAsync(reader, currentSessionId || "", botMessage.message, []);
                }
            },
            (status, detail) => {
                addResultMessage(utterance, "-1", userTime, "오류가 발생 했습니다. 잠시 후 다시 시도해 주세요.");
                inputRef.current?.focus();
                scrollToBottom();
                setInputStatus(true)
            }
        )
    }

    const readAsync = async (reader: ReadableStreamDefaultReader, session_id: string, text: string, arr: any[]) => {
        AsyncManager.readAsync(reader, session_id, text, arr).then(result => {
            if (result) {
                if (result.text) {
                    setMessageList(prevState => {
                        prevState[prevState.length - 1].message = result.text!;
                        prevState[prevState.length - 1].isloading = !result.isDone;
                        return [...prevState]
                    });
                }
                scrollToBottom();
                if (result.isDone) {
                    inputRef.current?.focus();
                    setInputStatus(true);
                    return;
                }
                else {
                }
                setTimeout(() => { readAsync(reader, session_id, result && result.text ? result.text : "", arr); }, 20);
            }
        })
    }


    const Bot = ({ text, time, isloading }: { text: string, time: Date, isloading: boolean | undefined }) => {
        return (
            <div className='chat_bot_layout'>
                <div className='bot_icon_layout'>
                    <BotIcon className='bot_icon' />&nbsp;&nbsp;DreamChat Bot
                </div>
                <div className='chat_bot_text'>
                    <div className='chat_textbox'><Markdown>{text}</Markdown></div>
                </div>
                {
                    isloading &&
                    <div className="loading_area">
                        <div className="dot1"></div>
                        <div className="dot2"></div>
                        <div className="dot3"></div>
                    </div>
                }
                <div className='pdf_chat_time'>{gridDateTime(time, 'hh:mm')}&nbsp;{sended[selectedLanguage]}</div>
            </div>
        );
    }

    const User = ({ text, time }: { text: string, time: Date }) => {
        return (
            <div>
                <div className='chat_user_layout'>
                    <div className='chat_user_text'>
                        <div className='chat_textbox'>{text}</div>
                    </div>
                </div>
                <div className='chat_user_layout pdf_chat_time'>{gridDateTime(time, 'hh:mm')}&nbsp;{sended[selectedLanguage]}</div>
            </div>
        );
    }

    const onLogout = () => {
        sessionStorage.removeItem('customer_id');
        sessionStorage.removeItem('service_id');
        sessionStorage.removeItem('service_name');
        sessionStorage.removeItem('customer');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('account_type');
        sessionStorage.removeItem('user_email');
        navigate('/mobile/pdf_chat_login');
        window.location.reload();
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", position: "absolute", width: "100%", height: "100%", overflow: "hidden" }}>
            <div className='chat_header_layout' style={{ width: "100%" }}>
                <div style={{ marginTop: "20px" }}>
                    <Logo />
                    <div className='chat_bold_text'>&nbsp;&nbsp;DreamChat</div>
                </div>
            </div>
            <div className='chat_center_layout' style={{width: "100%" }} ref={scrollRef}>
                <Bot text={welcome_message[selectedLanguage]} time={new Date()} isloading={false} />
                {
                    messageList.map((data, index) => (
                        <div key={'chat_item_' + index}>
                            {
                                data.isUser ?
                                    <User text={data.message} time={data.time} />
                                    :
                                    <Bot text={data.message} time={data.time} isloading={data.isloading} />
                            }
                        </div>
                    ))
                }
            </div>
            <div className='chat_input_layout' style={{width: "100%"}}>
                <div className='chat_input_center' style={{width: "calc(100% - 10px)", margin:"auto"}}>
                    <div className="chat_view_flag_select">
                        {
                            isFlagListShow &&
                            <div style={{
                                position: "absolute",
                                bottom: "360px",
                                left: "5px",
                                listStyleType: "none",
                                paddingLeft: "0px",
                                fontSize: "15px",
                                backgroundColor: "transparent"
                            }}>
                                <ul>
                                    <li className="option" onClick={() => { selectFlag(0) }}><img className="chat_view_flag_icon" alt="korean flag" src={flags[0].icon} /></li>
                                    <li className="option" onClick={() => { selectFlag(1) }}><img className="chat_view_flag_icon" alt="us flag" src={flags[1].icon} /></li>
                                    <li className="option" onClick={() => { selectFlag(2) }}><img className="chat_view_flag_icon" alt="japan flag" src={flags[2].icon} /></li>
                                    <li className="option" onClick={() => { selectFlag(3) }}><img className="chat_view_flag_icon" alt="china flag" src={flags[3].icon} /></li>
                                    <li className="option" onClick={() => { selectFlag(4) }}><img className="chat_view_flag_icon" alt="vietnam flag" src={flags[4].icon} /></li>
                                    <li className="option" onClick={() => { selectFlag(5) }}><img className="chat_view_flag_icon" alt="thailand flag" src={flags[5].icon} /></li>
                                </ul>
                            </div>
                        }

                        <div onClick={() => { setIsFlagListShow(!isFlagListShow) }}>
                            <img className="chat_view_flag_icon" alt="selected flag" src={flags[selectedLanguage].icon} />
                        </div>
                    </div>
                    <div className='chat_input_box' style={{flex: 1}}>
                        <input ref={inputRef}
                            placeholder={inputStatus ? placeholder[selectedLanguage] : working_message[selectedLanguage]}
                            disabled={!inputStatus} value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => activeEnter(e)} />
                        <SendIcon className='chat_input_send_icon' onClick={sendChatBotMessage} />
                    </div>
                </div>
                <div className='chat_copyright'>Powerd by DreamChat</div>
            </div>
        </div>
    );
}