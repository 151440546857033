import { useState, useRef, useEffect } from 'react';
import './chatView.css';
import { ReactComponent as SendIcon } from '../../assets/icon/send.svg';
import Loading from '../../components/iconify/loading';
import { sendChatMessage, getChatbotInfo, closeCatbotSession } from '../../api/chatbot/ChatbotApi'
import { gridDateTime } from '../../utils/TimeFomatter';

interface ChatMessage {
    message: string;
    isUser: boolean;
    time: Date;
    isloading?: boolean;
}

export default function ChatView() {

    const params = new URLSearchParams(window.location.search);
    const serviceId = params.get("service_id");

    let userName = params.get("user_name");
    if (!userName) {
        userName = crypto.randomUUID();
    }
    const [width, setChatWidth] = useState(params.get("width"));
    const [height, setChatHeight] = useState(params.get("height"));
    const [background, setBackground] = useState('#FFFFFF');
    const [inputTextColor, setInputTextColor] = useState('#000000');
    const [sendButtonColor, setSendButtonColor] = useState('#2898ec');
    const [sendButtonIcon, setSendButtonIcon] = useState('');

    const [userIcon, setUserIcon] = useState('');
    const [botIcon, setBotIcon] = useState('');

    const [messageList, setMessageList] = useState<ChatMessage[]>([]);
    const [message, setMessage] = useState('');
    const [inputStatus, setInputStatus] = useState(true);

    const scrollRef = useRef<HTMLDivElement>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const addMessage = (message: string, isUser: boolean, time: Date) => {
        let temp = [...messageList];
        temp.push({ message: message, isUser: isUser, time: time })
        temp.push({ message: "응답을 처리 중입니다.", isUser: false, isloading: true, time: new Date() })
        if (isUser) setMessage('');
        setMessageList(temp);
    }

    const addResultMessage = (userMessage: string, userTime: Date, botMessage: string) => {
        let temp = [...messageList];
        temp.push({ message: userMessage, isUser: true, time: userTime })
        temp.push({ message: botMessage, isUser: false, isloading: false, time: new Date() })
        setMessageList(temp);
    }

    const activeEnter = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            sendChatBotMessage();
        }
    }

    const sendChatBotMessage = async () => {
        const utterance = `${message}`;
        let userTime = new Date();
        setInputStatus(false)
        addMessage(utterance, true, userTime);
        scrollToBottom();
        await sendChatMessage(
            {
                utterance: utterance,
                user_name: userName!,
                service_id: serviceId!
            },
            (response) => {
                if (response) {
                    const returnText = response.data?.template?.outputs[0]?.simpleText?.text || '';
                    addResultMessage(utterance, userTime, returnText);
                    inputRef.current?.focus();
                    scrollToBottom();
                    setInputStatus(true)
                    sessionStorage.setItem('sessionId', response.data.session_id);
                }
            }
            ,
            (status) => {
                addResultMessage(utterance, userTime, "오류가 발생 했습니다. 잠시 후 다시 시도해 주세요.");
                inputRef.current?.focus();
                scrollToBottom();
                setInputStatus(true)
                console.log("=========================== FAIL : " + status)
            }
        )
        inputRef.current?.focus();
    }

    const Bot = ({ text, time, isloading }: { text: string, time: Date, isloading: boolean | undefined }) => {
        return (
            <div>
                <div className='bot_text'>
                    {
                        botIcon && botIcon.length > 0 &&
                        <img className='icon' src={botIcon} width={'40px'} height={'40px'} alt='bot icon' />
                    }
                    {
                        isloading ?
                            <div className='textbox' style={{ display: 'flex' }} ><div>{text}</div><Loading width='20px' /></div>
                            :
                            <div className='textbox'>{text}</div>
                    }
                    {/* <div className='textbox'>{text}</div> */}
                </div>

                <div className='bot chat_time'>{gridDateTime(time, 'yyyy-MM-dd hh:mm:ss')}</div>
            </div>
        );
    }

    const User = ({ text, time }: { text: string, time: Date }) => {
        return (
            <div>
                <div className='user_text'>
                    {
                        userIcon && userIcon.length > 0 &&
                        <img className='icon' src={userIcon} width={'40px'} height={'40px'} alt='user icon' />
                    }
                    <div className='textbox'>{text}</div>
                </div>
                <div className='user chat_time'>{gridDateTime(time, 'yyyy-MM-dd hh:mm:ss')}</div>
            </div>
        );
    }

    // useEffect(()=>{
    //   // setMessageList([{message : '안녕하세요. 무엇을 도와드릴까요?', isUser: false}])
    // }, []);

    const scrollToBottom = () => {
        if (scrollRef.current) {
            setTimeout(() => { scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight; }, 200);
        }
    };

    const closeSession = async () => {
        let sessionId = sessionStorage.getItem('sessionId');
        if (sessionId && sessionId.length > 0) {
            await closeCatbotSession({ service_id: serviceId!, session_id: sessionId }, () => {
                sessionStorage.removeItem('sessionId');
            },
                () => {
                    sessionStorage.removeItem('sessionId');
                })
        }
    }

    useEffect(() => {
        getChatbotInfo({ id: serviceId! },
            (response) => {
                if (response) {
                    const data = response.data;
                    // setLlm(data.summary.LLM);
                    setChatWidth(data.width || '300px');
                    setChatHeight(data.height || '500px');
                    setBackground(data.background || '#ffffff');
                    setUserIcon(data.customer.icon);
                    setBotIcon(data.bot.icon);
                    setInputTextColor(data.input_text_color);
                    setSendButtonColor(data.send_button_color);
                    setSendButtonIcon(data.send_button_icon);

                    let sheets = document.styleSheets;
                    for (let sheet of Array.from(sheets)) {
                        for (let rule of Array.from(sheet.cssRules)) {
                            if (!(rule instanceof CSSStyleRule)) {
                                continue;
                            }
                            if (rule.selectorText === ".bot_text .textbox::before") {
                                rule.style.color = data.bot.background || '#c9eded';
                            }
                            else if (rule.selectorText === ".bot_text .textbox") {
                                rule.style.backgroundColor = data.bot.background || '#c9eded';
                                rule.style.color = data.bot.text || '#000000';
                            }
                            else if (rule.selectorText === ".user_text .textbox::before") {
                                rule.style.color = data.customer.background || '#ccd5ae';
                            }
                            else if (rule.selectorText === ".user_text .textbox") {
                                rule.style.backgroundColor = data.customer.background || '#ccd5ae';
                                rule.style.color = data.customer.text || '#000000';
                            }
                        }
                    }
                    setMessageList([{ message: data.summary.welcome_message || '안녕하세요. 무엇을 도와드릴까요?', isUser: false, time: new Date() }])
                }
            },
            (status) => {
            }
        );
        return () => {
            closeSession();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='chatview_content' style={{ width: width || '300px', height: height || '500px', minHeight: height || '500px', background: background }}>
            <div className='external_chatbot_box' style={{ width: width || '300px', height: height || '500px' }}>
                <div className='chat_layout' ref={scrollRef}>
                    <div className='chat_list'>
                        {
                            messageList.map((data, index) => (
                                <div key={'chat_item_' + index}>
                                    {
                                        data.isUser ?
                                            <User text={data.message} time={data.time} />
                                            :
                                            <Bot text={data.message} time={data.time} isloading={data.isloading} />
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='input_Layout'>
                    <input className='input_box' ref={inputRef} 
                        placeholder={inputStatus ? '입력해 주세요.' : '응답을 생성 중입니다.'} 
                        disabled={!inputStatus} value={message} 
                        style={{ 
                            color: inputTextColor && inputTextColor.length > 0 ? inputTextColor : "#000000",
                            backgroundColor: background && background.length > 0 ? background : "#FFFFFF"
                        }}
                        onChange={(e) => setMessage(e.target.value)} 
                        onKeyDown={(e) => activeEnter(e)} />
                    <div className='send_button'
                        style={{ backgroundColor: sendButtonColor && sendButtonColor.length > 0 ? sendButtonColor : "#2898ec" }}
                        onClick={sendChatBotMessage}>
                        {
                            sendButtonIcon && sendButtonIcon.length > 0 ?
                                <img className='send_icon' src={sendButtonIcon} alt='send button icon' />
                                :
                                <SendIcon className='send_icon' />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}