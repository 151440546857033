import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Icon } from '../assets/icon/market.svg';
import { ReactComponent as Back } from '../assets/icon/back.svg';
import usePopup from "../hooks/popup/usePopup";

import { customerRegistration } from '../api/auth/AuthApi';
import { RegistrationParams } from '../dto/RequestParams'
import { CustomerResponse } from '../dto/ResponseData'

import { AxiosResponse } from "axios";
import '../css/login.css'

function Registration() {
    const { showPopup } = usePopup();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');


    const onRegistration = (e: any) => {
        e.preventDefault();
        const registrationParams: RegistrationParams = {            
            name: name,
            email: email,
            password: password
        }
        customerRegistration(registrationParams,
            (response: AxiosResponse<CustomerResponse, any> | undefined) => {
                if(response) {
                    showPopup({ message: '확인용 Email이 발송 되었습니다.\n메일을 확인 해 주세요.', confirm:()=>{navigate('/');} });
                }
            },
            (status, detail) => {
                if (detail) {
                    showPopup({ message: detail.message });
                }
                else {
                    showPopup({ message: `Error(${status})\n실패 했습니다.` });
                }
            });
    }

    return (
        <div className="login">
            <Icon className="icon_bg"/>
            <div className="background_box">
                <div>
                    <Back className="back_button" onClick={()=>{navigate('/');}} />
                </div>
                <div className="logo"><Icon className="logo_icon" /> Market Buddy</div>
                <div className="guide_text">회원 가입을 위한 기본 정보를 <br/><br/>입력해 주세요.</div>
                <div className="registration_box">
                    <div className="email_area" >
                        <div className="input_header">Name</div>
                        <input className="text_input" placeholder="Your name" value={name} onChange={(e) => { setName(e.target.value) }}/>
                    </div>
                    <div className="password_area" >
                    <div className="input_header">Email</div>
                        <input className="text_input" placeholder="name@example.com" value={email} onChange={(e) => { setEmail(e.target.value) }}/>
                    </div>
                    <div className="password_area" >
                        <div className="input_header">Password</div>
                        <input className="text_input" type="password" placeholder="Your password" value={password} onChange={(e) => { setPassword(e.target.value) }}/>
                    </div>
                    <div className="password_area" >
                        <div className="input_header">Retype password</div>
                        <input className="text_input" type="password" placeholder="Retype password" value={retypePassword} onChange={(e) => { setRetypePassword(e.target.value) }}/>
                    </div>
                    <div className="button_area" >
                        <div className="registration_button" onClick={onRegistration}>회원가입</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Registration;