import React, { useState, MutableRefObject, useRef, useEffect } from "react";
import { pdfjs, Document, Page, Thumbnail } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import Loading from "../../components/iconify/loading";

import { ConversationKnowledgeItem } from '../../dto/Items';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

interface PdfViewerProps {
    pageRef: MutableRefObject<HTMLDivElement[]>;
    thumbRef: MutableRefObject<HTMLDivElement[]>;
    knowledgeList?: ConversationKnowledgeItem[];
    selectedKnowledgeSetId: number;
    selectedKnowledgeFileName: string;
}

const PdfViewer = ({ pageRef, thumbRef, knowledgeList, selectedKnowledgeSetId, selectedKnowledgeFileName }: PdfViewerProps) => {

    const [pdfPageCount, setPdfPageCount] = useState<number>(-1);
    const [scrollPage, setScrollPage] = useState<number>(0);
    const [pageHeight, setPageHeight] = useState<number>(0);
    const [isPageRendering, setIsPageRendering] = useState<boolean>(true);
    const scrollRef = useRef<HTMLDivElement>(null);

    const renderThumbnail = () => {
        let result = [];
        if (pdfPageCount > 0) {
            for (let i = 0; i < pdfPageCount; i++) {
                const check = knowledgeList?.findIndex((item) => item.metadata.page === (i + 1));
                result.push(
                    <div className={"pdf_viewer_item"} key={"thumbnail_" + i} style={{ border: check !== undefined && check >= 0 ? "solid 2px #FF0000" : "solid 1px #CCCCCC" }} ref={el => thumbRef.current[i] = el as HTMLDivElement}>
                        <Thumbnail width={100} pageNumber={i + 1} onItemClick={() => {
                            if (scrollRef.current) {
                                setTimeout(() => {
                                    scrollRef.current!.scrollTop = i * (pageHeight + 4);
                                }, 50);
                            }
                        }} />
                    </div>
                )
            }
        }
        return result;
    }

    const renderPage = () => {
        if (pdfPageCount > 0) {
            let result = [];
            const scrollTop = (scrollRef?.current?.scrollTop || 0);
            // const perPage = (scrollRef?.current?.scrollHeight || 0) / pdfPageCount;
            const currentPage = Math.floor(scrollTop / pageHeight);
            for (let i = 0; i < pdfPageCount; i++) {
                if ((!currentPage && (i === 0 || i === 1 || i === 2)) || (i >= currentPage && i <= currentPage + Math.ceil(window.innerHeight / pageHeight))) {
                    result.push(
                        <div className={"pdf_viewer_item"} key={"viewer_item_" + i} ref={el => pageRef.current[i] = el as HTMLDivElement}>
                            <Page width={600} pageNumber={i + 1} >
                                {
                                    knowledgeList && knowledgeList.length > 0 &&

                                    knowledgeList.map((item, index) => {
                                        if (item.metadata.page === (i + 1)) {
                                            const left = (item.metadata.coordinates[0].x * 100) + "%";
                                            const top = (item.metadata.coordinates[0].y * 100) + "%";
                                            const width = ((item.metadata.coordinates[1].x - item.metadata.coordinates[0].x) * 100) + "%";
                                            const height = ((item.metadata.coordinates[2].y - item.metadata.coordinates[0].y) * 100) + "%";
                                            return <div key={"pdf_page_" + i + "_" + index} className={"box item_" + (item.tree_id + 1)} style={{ position: "absolute", left: left, top: top, width: width, height: height, opacity: 0.5 }} />
                                        }
                                        return <></>
                                    })
                                }

                            </Page>
                            {/* <div style={{ width: "100%", textAlign: "center", borderTop: "solid 1px #EEEEEE", background: "#DDDDDD" }}> [ {i + 1} / {pdfPageCount} ]</div> */}
                        </div>
                    );
                }
                else {
                    result.push(
                        <div className={"pdf_viewer_item"} key={"viewer_item_" + i} ref={el => pageRef.current[i] = el as HTMLDivElement} style={{ height: "" + Math.ceil(pageHeight) + "px" }}>
                        </div>
                    );

                }
            }
            return result;
        }
        else {
            return <></>
        }
    }

    const onDocumentLoadSuccess = async (document: any) => {
        const pageObj = await document.getPage(1);
        // setTimeout(() => {
        setScrollPage(0);
        setPdfPageCount(document.numPages);
        setPageHeight(pageObj.view[3])
        setIsPageRendering(false);
        // }, 200)
    }

    const handleScroll = () => {
        const scrollTop = (scrollRef?.current?.scrollTop || 0);
        // const perPage = (scrollRef?.current?.scrollHeight || 0) / pdfPageCount;
        setScrollPage(Math.floor(scrollTop / pageHeight));
    };

    useEffect(() => {
        setIsPageRendering(true);
    }, [selectedKnowledgeSetId]);


    return (
        <div className={`v_section referance_layout `} >
            <div style={{ display: isPageRendering ? "block" : "none", width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "200px" }}>PDF Loading <Loading width='80px'></Loading></div>
            </div>
            <div style={{ display: isPageRendering ? "none" : "block" }}>
                <Document file={`${process.env.REACT_APP_BASE_URL}/dreamx/knowledge_file/${selectedKnowledgeSetId}/${selectedKnowledgeFileName}`} onLoadSuccess={onDocumentLoadSuccess}
                >
                    <div className="pdf_viewer_layout">
                        <div >
                            <div style={{ width: "100%", height: "30px", textAlign: "center", borderTop: "solid 1px #EEEEEE", background: "#DDDDDD", paddingTop: "3px" }}>[ {scrollPage + 1} / {pdfPageCount} ]</div>
                            <div className="pdf_viewer_page_layout" ref={scrollRef} onScroll={handleScroll}>
                                {renderPage()}
                            </div>
                        </div>
                        <div className="pdf_viewer_thumbnail_layout" >
                            {renderThumbnail()}
                        </div>
                    </div>
                </Document>
            </div>
        </div>
    )
}

export default PdfViewer;