import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from 'recoil';
import { serviceState } from '../hooks/atom/AtomState';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { ReactComponent as Icon } from '../assets/icon/market.svg';
import TaskIndicator from "../components/topBar/TaskIndicator";
import AccountIndicator from "../components/topBar/AccountIndicator";

import { AxiosResponse } from 'axios';
import { CheckServiceStatusRespopnse } from '../dto/ResponseData'
import { checkServiceStatus } from '../api/service/ServiceApi';

import { useInterval } from '../utils/timerUtils'

import '../css/top.css'

function Top() {
    const navigate = useNavigate();
    const setServiceState = useSetRecoilState(serviceState);
    const customer = JSON.parse(sessionStorage.getItem('customer') || "{}");
    const [status, setStatues] = useState(0);
    const [balance, setBalance] = useState(customer.balance);
    const service_id = sessionStorage.getItem('service_id');

    const getServiceStatus = () => {
        if (service_id) {
            checkServiceStatus({ id: service_id },
                (response: AxiosResponse<CheckServiceStatusRespopnse, any> | undefined) => {
                    if (response) {
                        const data = (response.data as CheckServiceStatusRespopnse)
                        setStatues(data.status);
                        setServiceState(data.status)
                        setBalance(data.balance)
                    }
                },
                (state) => {
                    console.log(`Error(${state})\n실패 했습니다.`);
                }
            )
        }
    }

    useInterval(getServiceStatus, 10000)

    return (
        <div className="top">
            <div className="topbar_wrapper">
                <div className="top_logo"><Icon className="logo_icon" /> Market Buddy</div>
                <div className="top_right">
                    <div className="balance_layout" onClick={()=>navigate('/point')}><MonetizationOnIcon />&nbsp;&nbsp;{balance}</div>
                    <TaskIndicator status={status} />
                    <AccountIndicator />
                </div>
            </div>
        </div>
    );
}
export default Top;