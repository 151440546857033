import { AxiosResponse } from 'axios';
import APIService from '../APIService';
import {AddProductParams, EditProductParams, DeleteProductParams, GetProductListParams } from '../../dto/RequestParams'
import {ProductAddResponse, ProductListResponse, FileChatKnowledgeSetAddResponse} from '../../dto/ResponseData'
import { ProductItem } from '../../dto/Items';
const prefix = 'knowledge_set/';

export async function  getProductList (params: GetProductListParams, success: (res: AxiosResponse<ProductAddResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_list', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  addProduct (params: AddProductParams, success: (res: AxiosResponse<ProductListResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'add', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}
export async function  addFileChatProduct (params: AddProductParams, success: (res: AxiosResponse<ProductItem, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'add_for_file_chat', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  editProduct (params: EditProductParams, success: (res: AxiosResponse<ProductListResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'update', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  deleteProduct (params: DeleteProductParams, success: (res: AxiosResponse<ProductListResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'delete', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}