// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top_logo {
    position: relative;
    display: flex;
    text-align: start;
    align-items: center;
    font-size: 25px;
    font-weight: 700;
    color: #6b8be2;
}

.topbar_wrapper {
    padding: 0px 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top_left {
    display: flex;
    align-items: center;
}

.top_right {
    display: flex;
    align-items: center;
    padding-right: 20px;
    gap: 20px;
}

.icon_circle {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.task_background {
    background: rgba(158, 158, 158, 0.08);
}
.point_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    /* border: 1px solid black;
    border-radius: 7px;
    padding: 5px 15px;
    background: white;
    color: #0077b6;
    font-weight: 700; */
}
.balance_layout {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 7px;
    padding: 5px 15px;
    background: white;
    color: #0077b6;
    font-size: 17px;
    font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/css/top.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,qCAAqC;AACzC;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf;;;;;uBAKmB;AACvB;AACA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".top_logo {\n    position: relative;\n    display: flex;\n    text-align: start;\n    align-items: center;\n    font-size: 25px;\n    font-weight: 700;\n    color: #6b8be2;\n}\n\n.topbar_wrapper {\n    padding: 0px 20px;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.top_left {\n    display: flex;\n    align-items: center;\n}\n\n.top_right {\n    display: flex;\n    align-items: center;\n    padding-right: 20px;\n    gap: 20px;\n}\n\n.icon_circle {\n    display: flex;\n    width: 40px;\n    height: 40px;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n}\n\n.task_background {\n    background: rgba(158, 158, 158, 0.08);\n}\n.point_wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 12px;\n    /* border: 1px solid black;\n    border-radius: 7px;\n    padding: 5px 15px;\n    background: white;\n    color: #0077b6;\n    font-weight: 700; */\n}\n.balance_layout {\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid black;\n    border-radius: 7px;\n    padding: 5px 15px;\n    background: white;\n    color: #0077b6;\n    font-size: 17px;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
