import { AxiosResponse } from 'axios';
import APIService from '../APIService';
import { CustomerResponse} from '../../dto/ResponseData'
import { 
    RegistrationParams, 
    SocialRegistrationParams,
    LoginParams, 
    SocialLoginParams,
    UpdateCustomerParams, 
    UpdatePasswordParams, 
    ResetPasswordParams, 
    VerifyResetPasswordParams,
    UpdateResettedPasswordParams,
} from '../../dto/RequestParams'

const prefix = 'customer/';

export async function  customerRegistration (params: RegistrationParams, success: (res: AxiosResponse<CustomerResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'join', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  customerSocialRegistration (params: SocialRegistrationParams, success: (res: AxiosResponse<CustomerResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'social_join', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  customerLogin (params: LoginParams, success: (res: AxiosResponse<CustomerResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'login', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  customerSocialLogin (params: SocialLoginParams, success: (res: AxiosResponse<CustomerResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'social_login', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  updateCustomerInfo (params: UpdateCustomerParams, success: (res: AxiosResponse<CustomerResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'update', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  updateCustomerPassword (params: UpdatePasswordParams, success: (res: AxiosResponse<CustomerResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'update_passsword', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  resetCustomerPassword (params: ResetPasswordParams, success: (res: AxiosResponse<CustomerResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'reset_passsword', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  updateResettedPassword (params: UpdateResettedPasswordParams, success: (res: AxiosResponse<CustomerResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'update_resetted_passsword', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  verifyResettedPassword (params: VerifyResetPasswordParams, success: (res: AxiosResponse<CustomerResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'reset_verify', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}