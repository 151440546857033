import { useState } from 'react';
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import type { RowClickedEvent, RowClassParams, ColDef, RowStyle } from 'ag-grid-community'

import Pagination from './Pagination';
import { gridDateTime } from "../../utils/TimeFomatter"
import './dataGrid.css'
import { TransactionSearchParam } from '../../dto/Search';

interface TransactionDataGridProps {
    rowData: any;
    colDefs: ColDef[];
    totalCount: number;
    getRowStyle?: (params: RowClassParams<any>) => RowStyle | undefined;
    onRowClicked: (event: RowClickedEvent) => void;
    onPageChange: (page: number, limit: number, search: TransactionSearchParam) => void;
}

export default function PointDataGrid({ rowData, colDefs, totalCount, getRowStyle, onRowClicked, onPageChange }: TransactionDataGridProps) {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageLimit, setCurrentPageLinmit] = useState(10);

    const [selectedCategory, setSelectedCategory] = useState("all");

    const convertDate = (date: Date | null, isStart: boolean): Date | null => {
        if (!date) return null;
        console.log("date : " + date)
        let dateStr = gridDateTime(date, 'yyyy-MM-dd' + (isStart ? ' 00:00:00' : ' 23:59:59'))
        console.log("dateStr : " + dateStr)
        return new Date(dateStr);
    }

    const onDataSearch = (startDate: Date | null, endDate: Date | null, isReset: boolean) => {
        setStartDate(startDate);
        setEndDate(endDate);
        onPageChange(1, currentPageLimit, 
            makeSearchParam(isReset ? undefined : selectedCategory, startDate ? gridDateTime(startDate) : null, endDate ? gridDateTime(endDate) : null)
        );
    };

    const handlePageChange = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1)
        onPageChange(selected + 1, currentPageLimit, makeSearchParam(selectedCategory, startDate ? gridDateTime(startDate) : null, endDate ? gridDateTime(endDate) : null));
    };

    const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let value = Number(e.target.value);
        setCurrentPageLinmit(value);
        onPageChange(1, value, makeSearchParam(selectedCategory, startDate ? gridDateTime(startDate) : null, endDate ? gridDateTime(endDate) : null))
    }

    const makeSearchParam = (category: string | undefined, startDate: string | null, endDate: string | null) => {
        return {
            period: {
                end: endDate || "",
                start: startDate || "",
            },
            category: category || "",
        }
    }

    const onReset = () => {
        setSelectedCategory('');
    }

    return (
        <div>
            <div className='search_wrapper_layout'>
                <div className='additional_data_serarch_wrapper'>
                    <div>분류선택:</div>
                    <select className="category_select" onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
                        <option value="all" >전체</option>
                        <option value="add">충전</option>
                        <option value="sub">사용</option>
                    </select>

                    <div className='data_serarch_wrapper'>
                        &nbsp;&nbsp; <div className='label_text'>기간: </div>
                        <div className='date_picker_wrapper'>
                            <DatePicker showIcon isClearable dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date: Date | null) => setStartDate(convertDate(date, true))} />
                        </div>
                        -
                        <div className='date_picker_wrapper'>
                            <DatePicker showIcon isClearable dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => setEndDate(convertDate(date, false))} />
                        </div>
                        <button className='search_button' onClick={() => { onDataSearch(startDate ? startDate : null, endDate ? endDate : null, false) }}>검색</button>
                        <button className='search_button' onClick={onReset}>초기화</button>
                    </div>
                    {/* <DataSearch onSearch={onDataSearch} onReset={onReset} /> */}
                </div>

                <div className='limit_wrapper'>
                    표시개수&nbsp;:&nbsp;
                    <select className="limit_select" onChange={handleLimitChange} value={currentPageLimit}>
                        <option value="10" >10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
            <div className='ag-theme-quartz'>
                {
                    rowData && rowData.length > 0 ?
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={colDefs}
                            getRowStyle={getRowStyle}
                            suppressCellFocus={true}
                            domLayout='autoHeight'
                            onRowClicked={onRowClicked}
                        />
                        :
                        <div className='empty-content'>
                            표시 할 내용이 없습니다.
                        </div>
                }
            </div>
            <div className='pagenation_layout'>
                {
                    rowData && rowData.length > 0 &&
                    <Pagination
                        currentPage={currentPage}
                        pageCount={Math.max(1, Math.ceil(totalCount / currentPageLimit))}
                        totalCount={totalCount}
                        onPageChange={handlePageChange}
                    />
                }
            </div>
        </div>
    );

}