//=============================== React ========================
import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//=============================== API ==========================
import { updateKnowledgeItem, deleteKnowledgeItem } from '../../api/product/KnowledgeItemApi';

//=============================== UI ===========================
import BreadCrumb from '../../components/BreadCrumb';
import { ArrowBack } from '@mui/icons-material';
import usePopup from "../../hooks/popup/usePopup";
import '../../css/product.css';

export default function KnowledgeDetail() {

    const location = useLocation();
    const id = location.state.id;
    const fileId = location.state.fileId;
    const contentState = location.state.content;
    const service_id = sessionStorage.getItem('service_id');

    const { showPopup } = usePopup();
    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);
    const [content, setContent] = useState(contentState);
    const [editContent, setEditContent] = useState(content);

    const onEditClick = () => {
        showPopup({
            type: 'confirm',
            title: '확인',
            message: '변경사항을 수정 하시겠습니까?',
            confirmText: "수정",
            cancelText: "취소",
            confirm: () => {
                updateKnowledgeItem({ id: id, service_id: service_id!, content: editContent }, (response) => {
                    setIsEdit(false); 
                    showPopup({ message: "수정 완료 되었습니다." });
                    setContent(editContent);
                },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                    }
                )
            },
        });
    }

    const onDeleteClick = () => {
        showPopup({
            type: 'confirm',
            title: '확인',
            message: '삭제 하시겠습니까?',
            confirmText: "삭제",
            cancelText: "취소",
            confirm: () => {
                deleteKnowledgeItem({ id: id }, (response) => {
                    showPopup({
                        message: "삭제 완료 되었습니다.", confirm: () => {
                            navigate("/knowledge", { state: { fileId: fileId } });
                        }
                    });
                },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                    }
                )
            },
        });
    }

    return (
        <div className='home_contents'>
            <BreadCrumb items={[
                { name: "홈", path: "/" },
                { name: "상품정보", path: "/product" },
                { name: "지식정보", path: "/knowledge", state: { fileId: fileId } },
                { name: "지식정보 상세" }
            ]} />
            <div className='page_title'><div style={{ cursor: "pointer" }} onClick={() => navigate('/knowledge', { state: { fileId: fileId } })}><ArrowBack /></div>지식정보 상세</div>
            <div className='knowledge_detail'>
                {
                    isEdit ?
                        <div>
                            <div className='knowledge_detail_edit_subtitle'>원본</div>
                            <textarea className='knowledge_detail_origin' disabled value={content} />
                            <div className='knowledge_detail_edit_subtitle'>수정</div>
                            <textarea className='knowledge_detail_edit' value={editContent} onChange={(e) => { setEditContent(e.target.value) }} />

                        </div>
                        :
                        <textarea className='knowledge_detail_viewer' disabled value={content} />
                }
            </div>
            <div className='product_button_area'>
                {
                    isEdit ?
                        <>
                            <button className='product_button product_edit' onClick={onEditClick}>수정</button>
                            <button className='product_button product_delete' onClick={() => { setEditContent(content); setIsEdit(false); }}>취소</button>
                        </>
                        :
                        <>
                            <button className='product_button product_edit' onClick={() => { setIsEdit(true) }}>수정</button>
                            <button className='product_button product_delete' onClick={onDeleteClick}>삭제</button>
                        </>
                }
            </div>
        </div>

    )

}