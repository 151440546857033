import React, { useState, useLayoutEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from 'react-router';
import { RecoilRoot } from 'recoil';


import Top from './screen/Top';
import Side from './screen/Side';
import Login from './screen/Login';
import PdfChatLanding from './pdfchat/PdfChatLanding';
import PdfChatLogin from './pdfchat/PdfChatLogin';
import Registration from './screen/Registration';
import SocialRegistration from './screen/SocialRegistration';
import ResetPassword from './screen/ResetPassword';
import ChangePassword from './screen/ChangePassword';

import KakaoLogin from './pages/auth/KakaoLogin';
import NaverLogin from './pages/auth/NaverLogin';

import PdfChat from './pdfchat/PdfChat'

import Home from './pages/Home'
import Product from './pages/product/Product'
import Knowledge from './pages/product/Knowledge'
import KnowledgeDetail from './pages/product/KnowledgeDetail';

import ChatView from "./pages/external/ChatView";
import PdfChatView from "./pages/external/PdfChatView";
import ChatbotSetting from './pages/ChatbotSetting';

import ChatLog from './pages/chatLog/ChatLog'
import Conversation from './pages/chatLog/Conversation'

import Analytics from './pages/Analytics';

import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Kakao from './pages/Kakao';
import Point from './pages/Point';
import PointManage from './pages/PointManage';

import Popup from './popup/Popup'


import './App.css';

function App() {
    let customer_id = sessionStorage.getItem('customer_id');
    let service_type: string | null = sessionStorage.getItem('service_type');

    const [currentServiceType, setCurrentServiceType] = useState<string>('');
    console.log("customer_id : " + customer_id);
    console.log("currentServiceType : " + service_type);

    const setServiceType = (type: string) => {
        setCurrentServiceType(type);
    }
    useLayoutEffect(()=>{
        customer_id = sessionStorage.getItem('customer_id');
        setCurrentServiceType(service_type || "");
    }, [])
    return (
        <RecoilRoot>
            <BrowserRouter>
                <div className="app">
                    {
                        window.location.pathname.indexOf('/external/') === 0 ?
                            <Routes>
                                <Route path="/external/chatbot" element={<ChatView />} />
                                <Route path="/external/pdf_chat" element={<PdfChatView />} />
                            </Routes>
                            :
                            (
                                customer_id ?
                                    (
                                        currentServiceType === "market" ?
                                            <>
                                                <Top />
                                                <div className="main_content">
                                                    <Side />
                                                    <div className="content">
                                                        <Routes>
                                                            <Route path="/" element={<Home />} />
                                                            <Route path="/product" element={<Product />} />
                                                            <Route path="/knowledge" element={<Knowledge />} />
                                                            <Route path="/knowledge_detail" element={<KnowledgeDetail />} />
                                                            <Route path="/chatbotsetting" element={<ChatbotSetting />} />
                                                            <Route path="/chatlog" element={<ChatLog />} />
                                                            <Route path="/conversation" element={<Conversation />} />
                                                            <Route path="/profile" element={<Profile />} />
                                                            <Route path="/settings" element={<Settings />} />
                                                            <Route path="/kakao_setting" element={<Kakao />} />
                                                            <Route path="/analytics" element={<Analytics />} />
                                                            <Route path="/point" element={<Point />} />
                                                            <Route path="/point_manage" element={<PointManage />} />
                                                            <Route path="/*" element={<Navigate to='/' />} />
                                                        </Routes>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <Routes>
                                                <Route path="/pdf_chat" element={<PdfChat />} />
                                                <Route path="/pdf_chat_login" element={<PdfChatLogin setServiceType={setServiceType} />} />
                                                <Route path="/introduce" element={<PdfChatLanding />} />
                                                <Route path="/*" element={<Navigate to='/pdf_chat_login' />} />
                                            </Routes>

                                    )
                                    :
                                    (
                                        currentServiceType === "pdf_chat" ?
                                            <Routes>
                                                <Route path="/pdf_chat_login" element={<PdfChatLogin setServiceType={setServiceType} />} />
                                                <Route path="/kakao_login" element={<KakaoLogin setServiceType={setServiceType} />} />
                                                <Route path="/naver_login" element={<NaverLogin setServiceType={setServiceType} />} />
                                                <Route path="/pdf_chat" element={<PdfChat />} />
                                                <Route path="/introduce" element={<PdfChatLanding />} />
                                                <Route path="/*" element={<Navigate to='/introduce' />} />
                                            </Routes>
                                            :
                                            <Routes>
                                                <Route path="/login" element={<Login setServiceType={setServiceType} />} />
                                                <Route path="/pdf_chat_login" element={<PdfChatLogin setServiceType={setServiceType} />} />
                                                <Route path="/introduce" element={<PdfChatLanding />} />
                                                <Route path="/registration" element={<Registration />} />
                                                <Route path="/social_registration" element={<SocialRegistration setServiceType={setServiceType} />} />
                                                <Route path="/password_reset" element={<ResetPassword />} />
                                                <Route path="/reset_verify" element={<ChangePassword />} />
                                                <Route path="/kakao_login" element={<KakaoLogin setServiceType={setServiceType} />} />
                                                <Route path="/naver_login" element={<NaverLogin setServiceType={setServiceType} />} />
                                                <Route path="/*" element={<Navigate to='/login' />} />
                                            </Routes>
                                    )
                            )
                    }
                </div>
            </BrowserRouter>
            <Popup />
        </RecoilRoot>
    );
}

export default App;