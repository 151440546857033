import React, { useEffect, useState } from "react";
import Markdown from 'react-markdown'
import FileChat from "../components/FileChat";
import { KnowledgeItem } from '../../dto/Items';

import { ReactComponent as Close } from '../../assets/pdf_chat/close.svg';
import { ReactComponent as Send } from '../../assets/pdf_chat/send.svg';
import '../pdfchat.css'

interface RightProps {
    className: string;
    session_id: string | undefined;
    knowledge_set_id: number | undefined;
    file_name: string | undefined;
    file_status: number | undefined;
    onPointChange: () => void;
}
function Right(props: RightProps) {

    const [isReferenceOpen, setIsReferenceOpen] = useState(false);

    const [knowledgeList, setKnowledgeList] = useState<KnowledgeItem[] | undefined>([]);
    const [selectedSession, setSelectedSession] = useState<string>();
    const [selectedKnowledgeSetId, setSelectedKnowledgeSetId] = useState<number>(-1);
    const onKnowledgeClick = (knowledge?: KnowledgeItem[]) => {
        setIsReferenceOpen(true);
        setKnowledgeList(knowledge || []);
    }

    useEffect(() => {
        if (!knowledgeList || knowledgeList?.length === 0) {
            setIsReferenceOpen(false)
        }
    }, [knowledgeList]);

    useEffect(() => {
        if (props.session_id) {
            setSelectedSession(props.session_id);
        }
        if (props.knowledge_set_id) {
            setSelectedKnowledgeSetId(props.knowledge_set_id);
        }
    }, [props.session_id, props.knowledge_set_id]);


    return (
        <div className={`${props.className}`}>
            <div className='v_section chat_layout'>
                {
                    selectedSession && props.knowledge_set_id ?
                        <FileChat session_id={selectedSession}
                            knowledge_set_id={selectedKnowledgeSetId}
                            file_name={props.file_name}
                            file_status={props.file_status}
                            onKnowledgeClick={onKnowledgeClick}
                            onPointChange={props.onPointChange}
                            isReferenceOpen={isReferenceOpen} />
                        :
                        <div className="pdf_chat_help">
                            <div>
                                <div className="pdf_chat_prompt">
                                    문서를 업로드하고 AI에게 무엇이든 물어보세요.
                                </div>
                                <div className="pdf_chat_input_box pdf_chat_input_box_help">
                                    <input className="pdf_chat_input" placeholder="문서에 관해 무엇이든 물어보세요." disabled />
                                    <div className="pdf_send_box">
                                        <Send className="pdf_send_button" />
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
            <div className={`v_section referance_layout ${isReferenceOpen ? 'referance_opened' : 'referance_closed'} `} >
                <Close className="reference_close_icon" onClick={() => { setIsReferenceOpen(false) }} />
                <div className="reference_title">답변근거</div>
                {
                    isReferenceOpen ?
                        <div>
                            {
                                knowledgeList && knowledgeList.map((item, index) => {
                                    return (
                                        <div className="pdf_chat_knowledge_content" key={index}>
                                            <Markdown>{item.content}</Markdown>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        :
                        <div className="pdf_chat_help">
                            응답에 대한 근거 내용을 선택 하시면 이곳에 표시 됩니다.
                        </div>
                }
            </div>
        </div>
    )
}
export default Right;