import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import Markdown from 'react-markdown'
import './pdfchat.css'
import { ReactComponent as Logo } from '../assets/pdf_chat/logo.svg';
import { ReactComponent as Close } from '../assets/pdf_chat/close_2.svg';
import NaverLogo from '../assets/pdf_chat/naver_login.png'
import KakaoLogo from '../assets/pdf_chat/kakao_login.png'
import GoogleLoginButton from '../pages/auth/GoogleLoginButton';
import { GoogleOAuthProvider } from "@react-oauth/google";
import Layer from '../components/Layer';

const termsFile = require('../assets/terms.md');
const personalFile = require('../assets/personal.md');

function PdfChatLogin({setServiceType}:{setServiceType : (type: string)=> void}) {

    const STATE = "pdf_chat";
    const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
    const NAVER_REDIRECT_URI = process.env.REACT_APP_NAVER_REDIRECT_URI;
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
    
    const location = useLocation();

    sessionStorage.setItem('service_type', "pdf_chat");

    const KAKAO_CLINET_ID = "0946dac268bbdb8d86a0da84b1bc4639";
    const KAKAO_REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;

    const [isTermsShow, setIsTermsShow] = useState(false);
    const [termsType, setTermsType] = useState('terms');

    const [terms, setTerms] = useState('');
    const [personals, setPersonals] = useState('');

    const naverLogin = () => {
        window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${NAVER_REDIRECT_URI}`;
    }

    const kakaoLogin = () => {
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLINET_ID}&state=${STATE}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code&scope=account_email`;
    }


    useEffect(() => {
        console.log(window.location.href);
        fetch(termsFile)
            .then((r) => r.text())
            .then(text => {
                setTerms(text);
            });

        fetch(personalFile)
            .then((r) => r.text())
            .then(text => {
                setPersonals(text);
            });
    }, []);
    return (
        <div className='login_layout'>
            <div className='login_left'>
                <div className='left_content'>
                    <div className='left_title_1'>Welcome to</div>
                    <div className='left_title_2'>Dreamchat</div>
                    <div className='left_title_3'>서비스를 이용하시려면<br />로그인이 필요합니다.</div>
                    <div className='login_button_layout'>
                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                            <GoogleLoginButton state={STATE} type="pdf_chat" setServiceType={setServiceType}/>
                        </GoogleOAuthProvider>

                        <button className="pdf_login_button" onClick={naverLogin}>
                            <img src={NaverLogo} />
                        </button>
                        <button className="pdf_login_button" onClick={kakaoLogin}>
                            <img src={KakaoLogo} />
                        </button>

                    </div>
                </div>
            </div>
            <div className='login_right'>
                <div className='right_box'>
                    <div style={{width: "80px", height: "86px", border:"solid 2px rgba(255, 255, 255, 0.4)", borderRadius: "18px"}}>
                    <Logo />
                    </div>
                    <div className='right_title'>DreamChat</div>
                </div>
                <div className='terms_layout'>
                    <div style={{ cursor: "pointer" }} onClick={() => { setTermsType('terms'); setIsTermsShow(true); }} >이용약관</div>
                    <div style={{ cursor: "pointer" }} onClick={() => { setTermsType('personal'); setIsTermsShow(true); }} >개인정보보호정책</div>
                </div>
            </div>
            {
                isTermsShow &&
                <Layer isShow={isTermsShow}>
                    <div className='pdf_terms_popup'>
                        <div className='pdf_terms_header'>
                            <div>{termsType === 'terms' ? "이용약관" : "개인정보 보호정책"}</div>
                            <Close onClick={() => { setIsTermsShow(false) }} />
                        </div>
                        <div className='pdf_terms_body'>
                            <Markdown>{termsType === 'terms' ? terms : personals}</Markdown>
                        </div>
                        <div style={{ height: "40px" }} />
                    </div>
                </Layer>
            }
        </div>

    );
}
export default PdfChatLogin;