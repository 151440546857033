import { useState } from 'react';

import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import type { RowClickedEvent, RowClassParams, ColDef, RowStyle } from 'ag-grid-community'

import DataSearch from './DataSearch';
import Pagination from './Pagination';
import './dataGrid.css'
import { ChatLogSearchParam } from '../../dto/Search';

interface ChatLogDataGridProps {
    rowData: any;
    colDefs: ColDef[];
    totalCount: number;
    getRowStyle?: (params: RowClassParams<any>) => RowStyle | undefined;
    onRowClicked: (event: RowClickedEvent) => void;
    onPageChange: (page: number, limit: number, search: ChatLogSearchParam) => void;
}

export default function ChatLogDataGrid({ rowData, colDefs, totalCount, getRowStyle, onRowClicked, onPageChange }: ChatLogDataGridProps) {
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [searchString, setSearchString] = useState<string | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageLimit, setCurrentPageLinmit] = useState(10);

    const [selectedCategory, setSelectedCategory] = useState("");


    const onDataSearch = (search: string | null, startDate: string | null, endDate: string | null, isReset: boolean) => {
        setSearchString(search);
        setStartDate(startDate);
        setEndDate(endDate);
        onPageChange(1, currentPageLimit, makeSearchParam(search, startDate, endDate, isReset ? undefined : selectedCategory));
    };

    const handlePageChange = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1)
        onPageChange(selected + 1, currentPageLimit, makeSearchParam(searchString, startDate, endDate, selectedCategory));
    };

    const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let value = Number(e.target.value);
        setCurrentPageLinmit(value);
        onPageChange(1, value, makeSearchParam(searchString, startDate, endDate, selectedCategory))
    }

    const makeSearchParam = (searchString: string | null, startDate: string | null, endDate: string | null, category: string | undefined) => {
        return {
            period: {
                end: endDate || "",
                start: startDate || "",
            },
            string: searchString || "",
            category: category || "",
        }
    }
    
    const onReset = () =>{
        setSelectedCategory('');
    }

    return (
        <div>
            <div className='search_wrapper_layout'>
                <div className='additional_data_serarch_wrapper'>
                    <div>분류선택:</div>
                    <select className="category_select" onChange={(e)=>setSelectedCategory(e.target.value)} value={selectedCategory}>
                        <option value="" hidden>분류선택</option>
                        <option value="제품 문의" >제품 문의</option>
                        <option value="배송 문의">배송 문의</option>
                        <option value="반품 문의">반품 문의</option>
                        <option value="결제 문의">결제 문의</option>
                        <option value="회원 문의">회원 문의</option>
                        <option value="기타 문의">기타 문의</option>
                        <option value="요약 불가">요약 불가</option>
                    </select>
                    <DataSearch onSearch={onDataSearch} onReset={onReset} />
                </div>

                <div className='limit_wrapper'>
                    표시개수&nbsp;:&nbsp;
                    <select className="limit_select" onChange={handleLimitChange} value={currentPageLimit}>
                        <option value="10" >10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
            <div className='ag-theme-quartz'>
                {
                    rowData && rowData.length > 0 ?
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={colDefs}
                            getRowStyle={getRowStyle}
                            suppressCellFocus={true}
                            domLayout='autoHeight'
                            onRowClicked={onRowClicked}
                        />
                        :
                        <div className='empty-content'>
                            표시 할 내용이 없습니다.
                        </div>
                }
            </div>
            <div className='pagenation_layout'>
                {
                    rowData && rowData.length > 0 &&
                    <Pagination
                        currentPage={currentPage}
                        pageCount={Math.max(1, Math.ceil(totalCount / currentPageLimit))}
                        totalCount={totalCount}
                        onPageChange={handlePageChange}
                    />
                }
            </div>
        </div>
    );

}