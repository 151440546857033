import React from "react";
import { useNavigate } from "react-router-dom";
import usePopup from "../../hooks/popup/usePopup";
import '../pdfchat.css'
import { ReactComponent as Logout } from '../../assets/pdf_chat/logout.svg';
import { ReactComponent as Icon } from '../../assets/pdf_chat/logo.svg';

import {
    requestCharge
} from '../../api/payment/PaymentApi';

interface TopProps {
    balance: string;
}

export default function Top(props: TopProps) {
    const { showPopup } = usePopup();
    const navigate = useNavigate();
    const customer_id = sessionStorage.getItem('customer_id');

    const onLogout = () => {
        sessionStorage.removeItem('customer_id');
        sessionStorage.removeItem('service_id');
        sessionStorage.removeItem('service_name');
        sessionStorage.removeItem('customer');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('account_type');
        sessionStorage.removeItem('user_email');
        navigate('/pdf_chat_login');
        window.location.reload();
    }

    const handleRequestCharge = () => {
        showPopup({
            type: 'confirm',
            title: '확인',
            message: '포인트 충전을 요청 하시겠습니까?',
            confirmText: "확인",
            cancelText: "취소",
            confirm: () => {
                requestCharge({ customer_id: customer_id! },
                    (response) => {
                        showPopup({ message: "충전 요청을 했습니다." });
                    },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                    }
                );
            },
        });
    }

    return (
        <div className="top_layer">
            <div className="top_contents">
                <div className='top_logo'><Icon className="top_icon" />&nbsp;&nbsp;DreamChat</div>
                <div className="top_right">
                    <div className="top_balance">
                        <div className="top_balance_text"><span style={{color: "#6b8be2"}}>{props.balance}</span>&nbsp;point</div>
                        <div className="top_request_button" onClick={handleRequestCharge}>충전하기</div>
                    </div>
                    <div className='top_logout' onClick={onLogout}>
                        <Logout className="top_logout_icon" />
                    </div>

                </div>
            </div>
        </div>
    )
}