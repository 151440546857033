import React, { useLayoutEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { PieChart, Pie, Legend, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

import {
    getBrandCount,
    getPurposeCount,
    getChatCount,
    getChannelCount,
} from '../api/analytics/AnalyticsApi';
import { BrandAnalyticsItem, ChannelAnalyticsItem, PurposeAnalyticsItem, ChatAnalyticsItem } from '../dto/Items';

import BreadCrumb from '../components/BreadCrumb';
import { convertDate, gridDateTime } from '../utils/TimeFomatter'

import '../css/analytics.css'

export default function Analytics() {
    const customer = JSON.parse(sessionStorage.getItem('customer') || "{}");

    const PIE_COLORS = ["#49b6ff", "#779be7", "#a480cf", "#d264b6", "#ff499e", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA"];
    const currentDate = new Date();
    const today = convertDate(currentDate, false);
    const yesterday = convertDate(new Date(currentDate.setDate(currentDate.getDate() - 1)), true);
    const weekAgo = convertDate(new Date(currentDate.setDate(currentDate.getDate() - 7)), true);
    const monthAgo = convertDate(new Date(currentDate.setDate(currentDate.getDate() - 30)), true);

    const [startDate, setStartDate] = useState<Date | null>(yesterday);
    const [endDate, setEndDate] = useState<Date | null>(today);

    const [categoryData, setCategoryData] = useState<PurposeAnalyticsItem[]>([]);
    const [brandData, setBrandData] = useState<BrandAnalyticsItem[]>([]);
    const [channelData, setChannelData] = useState<ChannelAnalyticsItem[]>([]);
    const [chatCount, setChatCount] = useState<ChatAnalyticsItem[]>([]);

    const [datePeriodKey, setDatePeriodKey] = useState<string>('day');

    const RADIAN = Math.PI / 180;
    interface ChartProps {
        cx: string;
        cy: string;
        midAngle: string;
        innerRadius: number;
        outerRadius: number;
        percent: number;
        index: string;
    }
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: ChartProps) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.43;
        const x = Number(cx) - 12 + radius * Math.cos(-midAngle * RADIAN);
        const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={'start'} dominantBaseline="central" style={{ fontSize: "13px" }}>
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const setDatePeriod = (type: string) => {
        setDatePeriodKey(type);
        let start;
        switch (type) {
            case 'week':
                setStartDate(weekAgo);
                start = weekAgo;
                break;
            case 'month':
                setStartDate(monthAgo);
                start = monthAgo;
                break;
            case 'day':
            default:
                setStartDate(yesterday);
                start = yesterday;
                break;
            }
        setEndDate(today);
        getData(gridDateTime(start!), gridDateTime(today!))
    }

    const onSearch = () => {
        setDatePeriodKey('month');
        getData(gridDateTime(startDate!), gridDateTime(endDate!));
    }

    const getData = async (startDate: string, endDate: string) => {
        const params = {
            service_id: customer.service_id,
            start_at: startDate,
            end_at: endDate
        }
        await getPurposeCount(params,
            (response) => {
                if (response) {
                    setCategoryData(response.data);
                }
            },
            (status) => {
            }
        )
        await getBrandCount(params,
            (response) => {
                if (response) {
                    setBrandData(response.data);
                }
            },
            (status) => {
            }
        );

        await getChannelCount(params,
            (response) => {
                if (response) {
                    setChannelData(response.data);
                }
            },
            (status) => {
            }
        );
        await getChatCount(params,
            (response) => {
                if (response) {
                    setChatCount(response.data);

                }
            },
            (status) => {
            }
        );
    }

    function formatXAxis(tickItem: string) {
        // If using moment.js
        return gridDateTime(new Date(tickItem), datePeriodKey === 'day' ? 'hh:mm' : 'yy/MM/dd')
    }

    useLayoutEffect(()=>{
        getData(gridDateTime(yesterday!), gridDateTime(today!))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='home_contents'>
            <BreadCrumb items={[{ name: "홈", path: "/" }, { name: "상담분석" }]} />
            <div className='page_title'>상담분석</div>
            <div className='data_serarch_wrapper'>
                <div className='analytics_search_title'>기간별 상담내역 </div>
                <button className='period_button' onClick={() => { setDatePeriod('day'); }}>일간</button>
                <button className='period_button' onClick={() => { setDatePeriod('week'); }}>주간</button>
                <button className='period_button' onClick={() => { setDatePeriod('month'); }}>월간</button>
                <div className='label_text'>기간: </div>
                <div className='date_picker_wrapper'>
                    <DatePicker showIcon isClearable dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date: Date | null) => setStartDate(convertDate(date, true))} />
                </div>
                -
                <div className='date_picker_wrapper'>
                    <DatePicker showIcon isClearable dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => setEndDate(convertDate(date, false))} />
                </div>
                <button className='analytics_search_button' onClick={onSearch}>검색</button>
            </div>
            <div className='analytics_pie_layout'>
                <div className='analytics_pie_item'>
                    <div>질문유형</div>
                    <PieChart width={250} height={250} >
                        <Legend layout="horizontal" verticalAlign="bottom" align="center" formatter={(value, entry, index) => <span className="analytics_legend_text">{value}</span>} />
                        {
                            categoryData.length === 0 ?
                                <Pie data={[{ count: 100 }]}  outerRadius={100} dataKey="count" name='데이터 없음' fill="#dddddd" isAnimationActive={false} />
                                :
                                <Pie data={categoryData} outerRadius={100} dataKey="count" nameKey="category" fill="#8884d8" label={renderCustomizedLabel} labelLine={false} >
                                    {categoryData.map((entry, index) => (<Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />))}
                                </Pie>
                        }

                    </PieChart>

                </div>
                <div className='analytics_pie_item'>
                    <div>채널별 내역</div>
                    <PieChart width={250} height={250} >
                        <Legend layout="horizontal" verticalAlign="bottom" align="center" formatter={(value, entry, index) => <span className="analytics_legend_text">{value}</span>} />
                        {
                            channelData.length === 0 ?
                                <Pie data={[{ count: 100 }]} outerRadius={100} dataKey="count" name='데이터 없음' fill="#dddddd" isAnimationActive={false} />
                                : 
                                <Pie data={channelData} outerRadius={100} dataKey="count" nameKey="channel_type" fill="#8884d8" label={renderCustomizedLabel} labelLine={false} >
                                    {channelData.map((entry, index) => (<Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />))}
                                </Pie>
                        }

                    </PieChart>

                </div>
                <div className='analytics_pie_item'>
                    <div>상품별 내역</div>
                    <PieChart width={250} height={250} outerRadius={200} >
                        <Legend layout="horizontal" verticalAlign="bottom" align="center" formatter={(value, entry, index) => <span className="analytics_legend_text">{value}</span>} />
                        {
                            brandData.length === 0 ?
                                <Pie data={[{ count: 100 }]} outerRadius={100} dataKey="count" name='데이터 없음' fill="#dddddd" isAnimationActive={false} />
                                :
                                <Pie data={brandData} outerRadius={100} dataKey="count" nameKey="name" fill="#8884d8" label={renderCustomizedLabel} labelLine={false} >
                                    {brandData.map((entry, index) => (<Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />))}
                                </Pie>
                        }
                    </PieChart>

                </div>
            </div>
            <div className='analytics_line_chart_layout'>
                <div>기간별 상담횟수</div>
                <ResponsiveContainer width='100%' aspect={2.8 / 1.0}>
                    <LineChart margin={{ top: 25, right: 50, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tickFormatter={formatXAxis} />
                        <YAxis />
                        <Legend />
                        <Line data={chatCount} type="monotone" dataKey="count" stroke="#ff7300" name="상담 수" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}