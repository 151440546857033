import { AxiosResponse } from 'axios';
import APIService from '../APIService';
const prefix = 'chatbot/';

export interface SendChatMessageParams {
    utterance: string;
    user_name: string;
    service_id: string;
}

export interface SendFileChatMessageParams {
    utterance: string;
    user_name: string;
    service_id: string;
    knowledge_set_id: string;
    language: string;
    session_id?: string;
}

export interface ChatMessageResponse {
    version: string;
    template: any;
    session_id: string;
}

export interface FileChatMessageResponse {
    version: string;
    template: any;
    session_id: string;
    knowledge_set_id: string;
    answer_id: string;
}

export interface GetChatbotInfoParams {
    id: string;
}

export interface ChatbotInfoSummary {
    LLM: string;
    welcome_message: string;
}

export interface ChatbotInfoCharacter {
    icon: string;
    background: string;
    text: string;
}

export interface UpdateChatbotInfoParams {
    id: string;
    background: string;
    width: string;
    height: string;
    input_text_color: string;
    send_button_color: string;
    send_button_icon: string;
    summary: ChatbotInfoSummary;
    customer: ChatbotInfoCharacter;
    bot: ChatbotInfoCharacter;
}

export interface ChatbotInfoResponse {
    background: string;
    width: string;
    height: string;
    input_text_color: string;
    send_button_color: string;
    send_button_icon: string;
    summary: ChatbotInfoSummary;
    customer: ChatbotInfoCharacter;
    bot: ChatbotInfoCharacter;

}

export interface SessionCheckParams {
    service_id : string;
}

export interface SessionDisconnectParams {
    service_id : string;
    session_id : string;
}

export interface SessionResponse {
    result : string;
}

export interface ImaguploadResponse {
    image_url: string;
}

export async function  sendChatMessage (params: SendChatMessageParams, success : (res: AxiosResponse<ChatMessageResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'qa/chatbot', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  sendFileChatMessage (params: SendFileChatMessageParams, success : (res: AxiosResponse<FileChatMessageResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'qa/pdf_chat', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  sendFileChatMessageStream (params: SendFileChatMessageParams, success : (res: Response | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().fetch(process.env.REACT_APP_BASE_URL + "/dreamx/" + prefix + 'qa/pdf_chat_stream', params);
    if (response?.ok) {
        success(response);
    }
    else fail(response?.status || 500, response?.text());
}

export async function  getChatbotInfo (params: GetChatbotInfoParams, success : (res: AxiosResponse<ChatbotInfoResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_info', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  updateChatbotInfo (params: UpdateChatbotInfoParams, success : (res: AxiosResponse<ChatbotInfoResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'update_info', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function chatbotImageUpload(params: FormData, onUploadProgress: (event:ProgressEvent) => void, success : (res: AxiosResponse<ImaguploadResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress
    }
    const response = await APIService.getInstance().post(prefix + `image_upload`, params, axiosConfig);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  cleanCatbotSession (params: SessionCheckParams, success : (res: AxiosResponse<SessionResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'session_check', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function closeCatbotSession (params: SessionDisconnectParams, success : (res: AxiosResponse<SessionResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'session_disconnect', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}