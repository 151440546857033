import React from 'react';
interface SummaryBoxProps {
    stroke?: string;
    background?: string | undefined;
    icon: any;
    title: string;
    titleColor?: string| undefined;
    count: number;
    countColor?: string| undefined;
}

interface ContainerProps {
    stroke: string;
    background: string;
    children: React.ReactNode[] | React.ReactNode;
}

export default function SummaryBox(props: SummaryBoxProps) {
    const Container = (props: ContainerProps) =>{
        const customStyle = {
            width: '200px',
            height: '100px',
            borderRadius: '10px',
            border: `2px solid ${props.stroke}`,
            boxShadow: `0 0 1px 0px ${props.stroke} inset, 0 0 1px 0px ${props.stroke}`, 
            backgroundColor: `${props.background}`,
            display: 'flex',
        }
        return(<div style={customStyle} >{props.children}</div>);
    }

    const iconStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '70px',
    }

    const infoStyle = {
        flexGrow: '1'
    }

    const titleStyle = {
        marginTop: '10px',
        fontSize: '20px',
        color: `${props.titleColor || '#000000'}`
    }

    const countStyle = {
        fontSize: '40px',
        fontWeight: '700',
        color: `${props.countColor || '#000000'}`
    }

    return (
        <Container stroke={props.stroke || '#000000'} background={props.background || '#ffffff'}>
            <div style={iconStyle}>{props.icon}</div>
            <div style={infoStyle}>
                <div style={titleStyle}>{props.title}</div>
                <div style={countStyle}>{props.count}</div>
            </div>
        </Container>
    );
}