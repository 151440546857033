import { useState } from 'react';

import Box from '@mui/material/Box';
// import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as UserCircle } from '../../assets/icon/user_circle.svg';

// import { account } from 'src/_mock/account';

import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Home',
        icon: 'eva:home-fill',
    },
    {
        label: 'Profile',
        icon: 'eva:person-fill',
    },
    {
        label: 'Settings',
        icon: 'eva:settings-2-fill',
    },
];

// ----------------------------------------------------------------------

export default function AccountIndicator() {
    const navigate = useNavigate();
    const [open, setOpen] = useState<HTMLElement | undefined>(undefined);

    const customer = JSON.parse(sessionStorage.getItem('customer') || "{}");
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = (label: string) => {
        setOpen(undefined);
        switch (label) {
            case 'Profile':
                navigate('/profile');
                break;
            case 'Settings':
                navigate('/settings');
                break;
            case 'Home':
                navigate('/');
                break;
            default:
                break;
            }
    };

    const handleLogout = () => {
        sessionStorage.removeItem('customer_id');
        sessionStorage.removeItem('service_id');
        sessionStorage.removeItem('customer');
        sessionStorage.removeItem('access_token');
        navigate('/')
        window.location.reload();
    }

    return (
        <div>
            <IconButton
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    ...(open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
            >
                <UserCircle style={{width: '36px', height: '36px', fill: "#0077b6"}}/>
            </IconButton>

            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1,
                        ml: 0.75,
                        width: 200,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2 }}>
                    <Typography variant="subtitle2" noWrap>
                        {customer?.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {customer?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                {MENU_OPTIONS.map((option) => (
                    <MenuItem key={option.label} onClick={() => handleClose(option.label)}>
                        {option.label}
                    </MenuItem>
                ))}

                <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

                <MenuItem
                    disableRipple
                    disableTouchRipple
                    onClick={handleLogout}
                    sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
                >
                    Logout
                </MenuItem>
            </Popover>
        </div>
    );
}
