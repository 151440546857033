import React, { useLayoutEffect, useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import { gridDateTime } from "../utils/TimeFomatter"
import { TransactionSearchParam } from '../dto/Search';
import { RequestChargeItem } from '../dto/Items'
import PointRequstDataGrid from '../components/grid/PointRequstDataGrid'
import RequestApprovePopup from '../popup/point/RequestApprovePopup'
import usePopup from '../hooks/popup/usePopup';
import {
    getRequestChargeList
} from '../api/payment/PaymentApi';

export default function PointManage() {

    const { showPopup } = usePopup();

    const [requestList, setRequestList] = useState<RequestChargeItem[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentLimit, setCurrentLimit] = useState(0);
    const [currentSearch, setCcurrentSearch] = useState<TransactionSearchParam | undefined>();

    const [isShowApprovePopup, setIsShowApprovePopup] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState(0);

    const getRequestDatas = async (page?: number, limit?: number, search?: TransactionSearchParam) => {
        await getRequestChargeList({
            page: page || 1,
            limit: limit || 10,
            search: search || {
                period: {
                    start: "",
                    end: ""
                },
                category: ""
            }
        },
            (response) => {
                if (response) {
                    setRequestList(response.data.list);
                    setTotalCount(response.data.total_count)
                }
            },
            () => {

            }
        )
    }

    const statusCellRenderer = (props: any) => {
        var text = "승인";
        var backgroundColor = "#FFFFFF";
        var textColor = "#000000";


        switch (props.data.request_status) {
            case 0:
                text = "승인";
                backgroundColor = "#FFFFFF";
                textColor = "#000000";
                break;
            case 1:
                text = "요청";
                backgroundColor = "#0077b6";
                textColor = "#FFFFFF";
                break;
            case 2:
                text = "반려";
                backgroundColor = "#FF7744";
                textColor = "#FFFFFF";
                break;
        }

        return (
            <div className='grid_request_cell' onClick={()=>{
                    if (props.data.request_status !== 0) {
                        setSelectedRequestId(props.data.id); 
                        setIsShowApprovePopup(true)}
                    }
                } 
                style={{cursor: props.data.request_status !== 0 ? "pointer" : "default"}}
                >
                <div className='grid_request_button' style={{
                    background: backgroundColor, color: textColor
                }}>
                    {text}
                </div>
            </div>
        );
    }

    const pointRequestColDefs = [
        { field: "id", hide: true },
        { headerName: 'Date', field: "updated_at", cellRenderer: (props: any) => gridDateTime(props.data.updated_at) },
        { headerName: 'Status', field: "request_status", cellRenderer: statusCellRenderer },
        { headerName: 'User ID', field: "email", flex: 1},
        { headerName: 'note', field: "note", flex: 2 },
    ];

    const onPageChange = (page: number, limit: number, search?: TransactionSearchParam) => {
        setCurrentPage(page);
        setCurrentLimit(limit);
        setCcurrentSearch(search)
        getRequestDatas(page, limit, search);
    }

    useLayoutEffect(() => {
        getRequestDatas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='home_contents'>
            <BreadCrumb items={[{ name: "홈", path: "/" }, { name: "포인트 관리" }]} />
            <div className='page_title'>포인트 관리</div>
            <div className='point_grid_layout'>
                <PointRequstDataGrid rowData={requestList} colDefs={pointRequestColDefs} totalCount={totalCount} onRowClicked={() => { }} onPageChange={onPageChange} />
            </div>
            <RequestApprovePopup isShow={isShowApprovePopup} requestId={selectedRequestId} 
                onClose={(isChanged)=>{
                    setIsShowApprovePopup(false)
                    if (isChanged) {
                        showPopup({message: "적용 되었습니다."})
                        onPageChange(currentPage, currentLimit, currentSearch)
                    }
                }} />
        </div>
    );
}