import React, { useState, useLayoutEffect } from 'react';

import { PieChart, Pie, Legend, LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

import { ReactComponent as Question } from '../assets/icon/question.svg'
// import { ReactComponent as Chat } from '../asset/icon/chat.svg';
// import { ReactComponent as Bubble } from '../asset/icon/chat_bubble.svg';
// import { ReactComponent as Emoji } from '../asset/icon/chat_emoji.svg';

import {
    getHomeData,
} from '../api/home/HomeApi';

// import usePopup from "../hooks/popup/usePopup";
import { gridDateTime } from '../utils/TimeFomatter';

import '../css/home.css'
import { ProductData, GraphByTimeData } from '../dto/Items';

import BreadCrumb from '../components/BreadCrumb';
import SummaryBox from '../components/home/SummaryBox';

export default function Home() {
    // const { showPopup } = usePopup();
    const customer = JSON.parse(sessionStorage.getItem('customer') || "{}");

    const currentDate = new Date();
    const todayStart = gridDateTime(currentDate, "yyyy-MM-dd 00:00:00");
    const todayEnd = gridDateTime(currentDate, "yyyy-MM-dd 23:59:59");
    // const todayStart = "2024-08-12 00:00:00.00"
    // const todayEnd = "2024-08-12 23:59:59.59"

    const [sessionOpenCount, setSessionOpenCount] = useState(0);
    const [sessionCloseCount, setSessionCloseCount] = useState(0);
    const [answerCount, setAnswerCount] = useState(0);
    const [kakaoCount, setKakaoCount] = useState(0);
    const [chatbotCount, setChatbotCount] = useState(0);
    const [categoryCount, setcategoryCount] = useState<object[]>([]);
    const [productCount, setproductCount] = useState<ProductData[]>([]);
    const [sessionCountByTime, setSessionCountByTime] = useState<GraphByTimeData[]>([]);
    const [answerCountByTime, setAnswerCountByTime] = useState<GraphByTimeData[]>([]);
    const [userCountByTime, setUserCountByTime] = useState<GraphByTimeData[]>([]);

    const getStatistics = async () => {
        await getHomeData({ id: customer.service_id, start_at: todayStart, end_at: todayEnd },
            (response) => {
                if (response) {
                    const data = response.data;
                    setSessionOpenCount(data.session_open_count);
                    setSessionCloseCount(data.session_close_count);
                    setAnswerCount(data.answer_count);
                    let kakaoCount = 0;
                    let chatbotCount = 0;
                    if (data.channel_count.length > 0) {
                        for (let i = 0; i < data.channel_count.length; i++) {
                            const channel = data.channel_count[i];
                            if (channel.channel_type === 'kakao') {
                                kakaoCount = channel.count || 0;
                            }
                            else if (channel.channel_type === 'chatbot') {
                                chatbotCount = channel.count || 0;
                            }
                        }
                    }
                    setKakaoCount(kakaoCount);
                    setChatbotCount(chatbotCount);
                    setcategoryCount(data.category_count.length > 0 ? data.category_count : []);
                    setproductCount(data.product_count.length > 0 ? data.product_count : []);
                    setSessionCountByTime(data.session_count_by_time.length > 0 ? data.session_count_by_time : []);
                    setAnswerCountByTime(data.answer_count_by_time.length > 0 ? data.answer_count_by_time : []);
                    setUserCountByTime(data.user_count_by_time.length > 0 ? data.user_count_by_time : []);
                }
            },
            (status) => {

            }
        );
    }

    useLayoutEffect(() => {
        getStatistics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const questionIcon = <Question style={{ width: '64px', height: '64px' }} />


    const RADIAN = Math.PI / 180;
    interface ChartProps {
        cx: string;
        cy: string;
        midAngle: string;
        innerRadius: number;
        outerRadius: number;
        percent: number;
        index: string;
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: ChartProps) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.43;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: "13px" }}>
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div className='home_contents'>
            <BreadCrumb items={[{ name: "홈", path: "/" }]} />
            <div className='home_title'>오늘의 상담 현황</div>
            <div className='home_summary'>
                <SummaryBox background='#ffffff' stroke="#000000" title="현재 상담중" icon={questionIcon} count={sessionOpenCount} />
                <SummaryBox background='#ffffff' stroke="#000000" title="상담 완료" icon={questionIcon} count={sessionCloseCount} />
                <SummaryBox background='#ffffff' stroke="#000000" title="전체 질문" icon={questionIcon} count={answerCount} />
                <SummaryBox background='#ffffff' stroke="#000000" title="카카오톡 질문" icon={questionIcon} count={kakaoCount} />
                <SummaryBox background='#ffffff' stroke="#000000" title="웹채팅 질문" icon={questionIcon} count={chatbotCount} />
            </div>
            <div className='home_graph_area'>
                <div className='home_pie_area'>
                    <div className='home_pie_title'>질문유형</div>
                    <PieChart width={250} height={250} >
                        <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                        {
                            categoryCount.length === 0 ?
                                <Pie data={[{ count: 100 }]} dataKey="count" name='데이터 없음' fill="#dddddd" isAnimationActive={false} />
                                :
                                <Pie data={categoryCount} dataKey="count" nameKey="category" fill="#8884d8" label={renderCustomizedLabel} labelLine={false} />
                        }

                    </PieChart>
                    <div className='home_pie_title'>상품별 상담비율</div>
                    <PieChart width={250} height={250}>
                        <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                        {
                            productCount.length === 0 ?
                                <Pie data={[{ count: 100 }]} dataKey="count" name='데이터 없음' fill="#dddddd" isAnimationActive={false} />
                                :
                                <Pie data={productCount} dataKey="count" nameKey="product_name" fill="#8884d8" label={renderCustomizedLabel} labelLine={false} />
                        }
                    </PieChart>
                </div>
                <div className='home_line_area'>
                    <div className='home_pie_title'>시간별 상담현황</div>
                    <ResponsiveContainer width='100%' aspect={2.5 / 1.0}>
                        <LineChart margin={{ top: 25, right: 50, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" type={'number'} allowDuplicatedCategory={false} ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]} />
                            <YAxis dataKey="count" tickCount={1} />
                            <Legend />
                            <Line data={sessionCountByTime} type="monotone" dataKey="count" stroke="#ff7300" name="세션 수" />
                            <Line data={answerCountByTime} type="monotone" dataKey="count" stroke="#82ca9d" name="응답 수" />
                            <Line data={userCountByTime} type="monotone" dataKey="count" stroke="#8884d8" name="사용자 수" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}
