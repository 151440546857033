import '../../css/kakao.css';
import chatbotChannelSetting from '../../assets/guide/chatbot_channel_setting.png';
import chatbotChannelSetting2 from '../../assets/guide/chatbot_channel_setting_2.png';
import select_channel from '../../assets/guide/select_channel.png';
import service from '../../assets/guide/service.png';
export default function Page6() {
    return (
        <div className='page_content'>
            <div className='sub_header'>채널 연결</div>
            <div className='conter_contents'>
                <ul>
                    <li>1. 왼쪽 설정 아래 운영 채널에 운영 채널 선택 후 저장
                        <img className='guide_image' src={chatbotChannelSetting} width='500px' alt='select chatbot setting' />
                        <img className='guide_image' src={chatbotChannelSetting2} width='650px' alt='select operation channel' />
                    </li>
                    <li>2. 운영 채널에 들어가서 서비스 연결에 챗봇 확인해서 연결 확인
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <img className='guide_image' src={select_channel} width='200px' alt='create skill' />
                            <img className='guide_image' src={service} width='200px' alt='skill name setting' />
                        </div>
                    </li>
                    <li>{'   '}</li>
                </ul>
            </div>
        </div>
    )
}
