import { AxiosResponse } from 'axios';
import APIService from '../APIService';
import {GetHomeDataPrams} from '../../dto/RequestParams'
import {HomeDataResponse} from '../../dto/ResponseData'

const prefix = 'home/';

export async function  getHomeData (params: GetHomeDataPrams, success: (res: AxiosResponse<HomeDataResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}