import React, { useState, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { useNavigate, useLocation } from "react-router-dom";
//=============================== UI ===========================
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import type { ColDef } from 'ag-grid-community'

import BreadCrumb from '../../components/BreadCrumb';
import { ArrowBack } from '@mui/icons-material';
import usePopup from "../../hooks/popup/usePopup";
import KnowledgeEditPopup from '../../popup/product/KnowledgeEditPopup';

import {
    // getConversationList,
    // setResultScore,
    // updateStatus,
    getSessionConversation,
} from '../../api/analytics/ChatLogApi';

import {getKnowledgeItem} from '../../api/product/KnowledgeItemApi'

import { gridDateTime } from "../../utils/TimeFomatter"

import '../../css/chatlog.css';
import { SessionConversationItem } from '../../dto/Items';

export default function Conversation() {
    const navigate = useNavigate();
    const location = useLocation();
    const sessionData = JSON.parse(location.state.data);
    const { showPopup } = usePopup();

    const [isKnowledgeDetailShow, setIsKnowledgeDetailShow] = useState(false);
    const [knowledgeId, setKnowledgeId] = useState<string>('');
    const [knowledgeContent, setKnowledgeContent] = useState<string>('');

    const [conversationList, setConversationList] = useState<SessionConversationItem[]>([]);
    const AccordionTrigger = React.forwardRef<any, any>(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Header className="AccordionHeader">
            <Accordion.Trigger
                className={classNames('AccordionTrigger', className)}
                {...props}
                ref={forwardedRef}
            >
                {children}
                <ChevronDownIcon className="AccordionChevron" aria-hidden />
            </Accordion.Trigger>
        </Accordion.Header>
    ));

    const AccordionContent = React.forwardRef<any, any>(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Content
            className={classNames('AccordionContent', className)}
            {...props}
            ref={forwardedRef}
        >
            <div className="AccordionContentText">{children}</div>
        </Accordion.Content>
    ));

    const conversationColDefs: ColDef[] = [
        { field: "knowledge_id", hide: true },
        { headerName: 'content', field: "content", flex: 3 },
        { headerName: 'file_name', field: "file_name", flex: 1 },
    ];

    const onEmbeddingClick = (props: any) => {
        getKnowledgeItem({ knowledge_id: props.data.knowledge_id },
            (response) => {
                if (response) {
                    setKnowledgeId(response.data.id);
                    setKnowledgeContent(response.data.content);
                    setIsKnowledgeDetailShow(true);
                }
            },
            (status, detail) => {
                showPopup({ message: detail? detail.message :  `Error(${status})\n실패 했습니다.` });
            }
        );
    }


    const getConversation = () => {
        getSessionConversation({
            id: sessionData.id,
        }, (response) => {
            if (response) setConversationList(response.data);
        }, (status, detail) => {
            showPopup({ message: detail? detail.message :  `Error(${status})\n실패 했습니다.` });
        });
    }

    useLayoutEffect(() => {
        getConversation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='home_contents'>
            <BreadCrumb items={[
                { name: "홈", path: "/" },
                { name: "상담내용", path: "/chatLog" },
                { name: "상담 상세" }
            ]} />
            <div className='page_title'><div style={{ cursor: "pointer" }} onClick={() => navigate('/chatLog')}><ArrowBack /></div>상담 상세</div>
            <div className='session_summary_layout'>
                <div className='session_time'>
                    <div className='session_summary_title'>상담 시간</div>
                    <div className='session_summary_content'>{gridDateTime(sessionData.date)}</div>
                    <div>~</div>
                    <div className='session_summary_content'>{gridDateTime(sessionData.end)}</div>
                </div>
                <div className='session_category'>
                    <div className='session_summary_title'>상담 분류</div>
                    <div className='session_summary_content'>{sessionData.category}</div>
                </div>
                <div className='session_summary'>
                    <div className='session_summary_title'>상담 요약</div>
                    <div className='session_summary_content'>{sessionData.summary}</div>

                </div>
            </div>
            <div>
                {
                    conversationList && conversationList.length > 0 ?
                        <div>
                            {
                                conversationList.map((data, index) => {

                                    return (
                                        <div key={'conversation_' + index} className='conversation'>
                                            <div className='question'>{data.question}</div>
                                            <div className='answer'>{data.answer}
                                                {
                                                    data.knowledge && data.knowledge.length > 0 ?
                                                        <Accordion.Root className="AccordionRoot" type="single" collapsible>
                                                            <Accordion.Item className="AccordionItem" value="item-1">
                                                                <AccordionTrigger>근거 데이터</AccordionTrigger>
                                                                <AccordionContent>
                                                                    <div className='ag-theme-quartz'>
                                                                        <AgGridReact
                                                                            rowData={data.knowledge}
                                                                            columnDefs={conversationColDefs}
                                                                            suppressCellFocus={true}
                                                                            domLayout='autoHeight'
                                                                            onRowClicked={onEmbeddingClick}
                                                                        />
                                                                    </div>
                                                                </AccordionContent>
                                                            </Accordion.Item>
                                                        </Accordion.Root>
                                                        :
                                                        <div className="AccordionRoot">
                                                            <div className='AccordionTrigger' style={{background: "#d2d2d2", borderRadius: "9px"}}>
                                                            {'근거 데이터 없음'}
                                                            </div>
                                                        </div>

                                                }
                                            </div>

                                            <div className='conversation_devider' />
                                        </div>
                                    );
                                })
                            }
                        </div>
                        :
                        <div>대화내용이 없습니다.</div>
                }
            </div>
            {
                isKnowledgeDetailShow && <KnowledgeEditPopup id={knowledgeId} content={knowledgeContent || ''} onChanged={getConversation} onClose={() => { setIsKnowledgeDetailShow(false) }} />
            }
        </div>
    )
}