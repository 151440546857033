import { AxiosResponse } from 'axios';
import APIService from '../APIService';
import { SessionConversationItem } from '../../dto/Items'
import { GetConverstaionListParam, GetTotalConversationParam, AdminEvaluationQaSetParam, AnswerStatusUpdateParam } from '../../dto/RequestParams'
import { ConversationListResponse } from '../../dto/ResponseData'

const prefix = 'conversation/';

export async function  getConversationList (params: GetConverstaionListParam, success: (res: AxiosResponse<ConversationListResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_list', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getSessionConversation (params: GetTotalConversationParam, success: (res: AxiosResponse<SessionConversationItem[], any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_total', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  setResultScore (params: AdminEvaluationQaSetParam, success: (res: AxiosResponse<string, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'admin_evaluation', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}
export async function  updateStatus (params: AnswerStatusUpdateParam, success: (res: AxiosResponse<string, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'answer_status_update', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}