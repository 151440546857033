import { useEffect, useState } from 'react';
import '../css/profile.css';

import {
    updateCustomerPassword
} from '../api/auth/AuthApi';

import BreadCrumb from '../components/BreadCrumb';
import usePopup from "../hooks/popup/usePopup";
import PasswordPopup from '../popup/profile/PasswordPopup';

import { gridDateTime } from '../utils/TimeFomatter'

export default function Profile() {
    const customer = JSON.parse(sessionStorage.getItem('customer') || '{}');
    const { showPopup } = usePopup();
    console.log(customer)

    const [email, setEmail] = useState(customer.email);
    const [name, setName] = useState(customer.name);
    const [businessNumber, setBusinessNumber] = useState(customer.business_number);

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newRetypePassword, setNewRetypePassword] = useState('');

    const [isShowPasswordPopup, setisShowPasswordPopup] = useState(false);

    const updatePassword = () => {
        if (!password) {
            showPopup({ message: `비밀번호를 입력 해 주세요.` });
            return;
        }
        if (!newPassword || newPassword.length === 0) {
            showPopup({ message: `변경 할 비밀번호를 입력 해 주세요.` });
            return;
        }
        if (newPassword !== newRetypePassword) {
            showPopup({ message: `변경 할 비밀번호가 일치하지 않습니다.` });
            return;
        }
        updateCustomerPassword({
            id: customer.id,
            password: password,
            new_password: newPassword
        },
            (response) => {
                if (response) {
                    sessionStorage.setItem('customer', JSON.stringify(response.data));
                }
                setPassword('');
                setNewPassword('');
                setNewRetypePassword('');
                showPopup({ message: `수정 되었습니다.` });
            },
            (status, detail) => {
                if (detail) {
                    showPopup({ message: detail.message });
                }
                else {
                    showPopup({ message: `Error(${status})\n실패 했습니다.` });
                }
            }
        )
    }

    useEffect(() => {
        sessionStorage.setItem('customer', JSON.stringify(customer));
    }, [customer])

    return (
        <div className="home_contents">
            <BreadCrumb items={[{ name: "홈", path: "/" }, { name: "사용자 정보" }]} />
            <div className="page_title">사용자 정보</div>
            <div className='ui_box'>
                {/* <div className='box_title'>UI 설정</div> */}
                <div className='profile_row'><div className='profile_label'> {'Email : '}</div>
                    <input value={email} disabled onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='profile_row'><div className='profile_label'> {'이름 : '}</div>
                    <input value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className='profile_row'><div className='profile_label'> {'서비스 ID : '}</div>
                    <input value={customer.service_id} disabled />
                </div>
                <div className='profile_row'><div className='profile_label'> {'사업자번호 : '}</div>
                    <input value={businessNumber} onChange={(e) => setBusinessNumber(e.target.value)} />
                </div>
                <div className='profile_row'><div className='profile_label'> {'가입일 : '}</div>
                    <input value={gridDateTime(customer.create_at, 'yyyy-MM-dd')} disabled />
                </div>
                <div className='profile_row'><div className='profile_label'> {'회원구분 : '}</div>
                    <input value={'베이직 Plan'} disabled />
                </div>
            </div>
            <button className='profile_apply_button' onClick={() => setisShowPasswordPopup(true)}>사용자 정보 수정</button>

            {
                customer.type == "web" ?
                    <>
                        <div className="page_title">비밀번호 변경</div>
                        <div className='ui_box'>
                            <div className='profile_row'><div className='profile_label'> {'비밀번호 : '}</div>
                                <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className='profile_row'><div className='profile_label'> {'변경 비밀번호 : '}</div>
                                <input type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            </div>
                            <div className='profile_row'><div className='profile_label'> {'변경 비밀번호 재 입력 : '}</div>
                                <input type='password' value={newRetypePassword} onChange={(e) => setNewRetypePassword(e.target.value)} />
                            </div>
                        </div>
                        <button className='profile_apply_button' onClick={() => updatePassword()}>비밀번호 변경</button>
                    </>
                    :
                    <></>
            }

            <PasswordPopup isShow={isShowPasswordPopup} customer_id={customer.id} businessNumber={businessNumber} name={name} onClose={() => setisShowPasswordPopup(false)} />
        </div>
    )
}