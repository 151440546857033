import { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../css/login.css'
import { ReactComponent as Icon } from '../assets/icon/market.svg';
import  NaverLogo from '../assets/icon/naver_logo.png';
import  KakaoLogo from '../assets/icon/kakao_logo.png';
import usePopup from "../hooks/popup/usePopup";
import { customerLogin } from '../api/auth/AuthApi';
import { LoginParams } from '../dto/RequestParams'
import { CustomerResponse } from '../dto/ResponseData'
import { AxiosResponse } from "axios";
import GoogleLoginButton from '../pages/auth/GoogleLoginButton';
import { GoogleOAuthProvider } from "@react-oauth/google";

function Login({setServiceType}:{setServiceType: (type: string) => void;}) {
    const { showPopup } = usePopup();
    const navigate = useNavigate();
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');

    const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
    const STATE = "market";
    const NAVER_REDIRECT_URI = process.env.REACT_APP_NAVER_REDIRECT_URI;
    const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${NAVER_REDIRECT_URI}`;
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;


    const KAKAO_CLINET_ID = "0946dac268bbdb8d86a0da84b1bc4639";
    const KAKAO_REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;

    const naverLogin = () => {
        window.location.href = NAVER_AUTH_URL;
    }
    
    const kakaoLogin = () => {
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLINET_ID}&state=${STATE}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code&scope=account_email`;
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onLogin(e);
        }
    };

    const onLogin = (e: any) => {
        e.preventDefault();
        const loginParams: LoginParams = {
            email: loginEmail,
            password: loginPassword
        }
        customerLogin(loginParams,
            (response: AxiosResponse<CustomerResponse, any> | undefined) => {
                if (response) {
                    const data: CustomerResponse = response.data;
                    sessionStorage.setItem('customer_id', data.id);
                    sessionStorage.setItem('service_id', data.service_id);
                    sessionStorage.setItem('service_name', data.service_name);
                    sessionStorage.setItem('customer', JSON.stringify(data));
                    sessionStorage.setItem('access_token', data.access_token);
                    sessionStorage.setItem('service_type', "market");
                    setServiceType("market");
                    navigate("/");
                    window.location.reload();
                }
            },
            (status, detail) => {
                if (detail) {
                    showPopup({ message: detail.message });
                }
                else {
                    showPopup({ message: `Error(${status})\n실패 했습니다.` });
                }
            });
    }

    return (
        <div className="login">
            <Icon className="icon_bg" />
            <div className="background_box">
                <div className="logo"><Icon className="logo_icon" /> Market Buddy</div>
                <div className="guide_text">서비스를 이용 하시려면<br /><br />로그인 해 주세요</div>
                <div className="login_box">
                    <div className="email_area" >
                        <div className="input_header">Email</div>
                        <input className="text_input" placeholder="name@example.com" value={loginEmail} onChange={(e) => { setLoginEmail(e.target.value) }} />
                    </div>
                    <div className="password_area" >
                        <div className="input_header">password</div>
                        <input className="text_input" type="password" placeholder="Your password" value={loginPassword} onKeyDown={handleKeyDown} onChange={(e) => { setLoginPassword(e.target.value) }} />
                    </div>
                    <div className="button_area" >
                        <div className="registration_guide_area">
                            <div className="registration_guide">계정이 없으신가요? <a href="/registration">회원가입</a></div>
                            <div className="registration_guide">비밀번호가 기억나지 않으시나요?<br /><a href="/password_reset">비밀번호 초기화</a></div>
                        </div>
                        <div className="login_button" onClick={onLogin}>로그인</div>
                    </div>
                    <div className="vertical_divider" />
                    <div className="social_login_area">
                        <div>소셜 로그인 : </div>
                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                            <GoogleLoginButton state={STATE} type={"market"} setServiceType={setServiceType}/>
                        </GoogleOAuthProvider>
                        <button className="social_login_button" onClick={naverLogin}>
                            <img src={NaverLogo} className="social_login_icon" />
                        </button>
                        <button className="social_login_button" onClick={kakaoLogin}>
                            <img src={KakaoLogo} className="social_login_icon" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;