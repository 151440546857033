import { AxiosResponse } from 'axios';
import APIService from '../APIService';
import {GetBalanceParams, PaymentParams, PaymentSearchParams, RequstChargeParams, RequstChargeSearchParams, RequstApprovalParams} from '../../dto/RequestParams'
import {PaymentBalanceResponse, TransactionsResponse, RequestChargeListResponse, RequestChargeResponse} from '../../dto/ResponseData'

const prefix = 'payment/';

export async function  getBalance (params: GetBalanceParams, success: (res: AxiosResponse<PaymentBalanceResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  changeBalance (params: PaymentParams, success: (res: AxiosResponse<PaymentBalanceResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'change', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getTransactions (params: PaymentSearchParams, success: (res: AxiosResponse<TransactionsResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_list', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  requestCharge (params: RequstChargeParams, success: (res: AxiosResponse<RequestChargeResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'request_charge', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getRequestChargeList (params: RequstChargeSearchParams, success: (res: AxiosResponse<RequestChargeListResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_request_list', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  updateChargeRequest (params: RequstApprovalParams, success: (res: AxiosResponse<RequestChargeResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'request_approval', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

