import React, { useState, forwardRef, LegacyRef, useImperativeHandle } from 'react';
import { ReactComponent as SendIcon } from '../../assets/pdf_chat/send.svg';

import '../pdfchat.css'


interface ChatInputProps {
    inputStatus: boolean;
    placeholder?: string;
    onEnter: (message: string) => void;
    inputRef: LegacyRef<HTMLInputElement>;
}

const ChatInput = forwardRef(({ inputStatus, placeholder, onEnter, inputRef }: ChatInputProps, ref) => {

    const [message, setMessage] = useState('');
    const [isInputFocus, setIsInputFocus] = useState(false);

    useImperativeHandle(ref, () => ({
        clearMessage
    }));

    const activeEnter = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            onEnter(message);
        }
    }

    const clearMessage = () => {
        setMessage('');
    }

    return (
        <div className={`pdf_chat_input_box${isInputFocus ? ' select' : ''}`}>
            <input className={`pdf_chat_input`} ref={inputRef}
                placeholder={placeholder}
                disabled={!inputStatus} value={message}
                onFocus={() => { setIsInputFocus(true) }}
                onBlur={() => { setIsInputFocus(false) }}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => activeEnter(e)} />
            <div className='pdf_send_box'
                onClick={() => onEnter(message)}>
                <SendIcon className='pdf_send_button' />
            </div>

        </div>
    );
});
export default ChatInput;