import { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
// import { ReactComponent as SendIcon } from '../../assets/icon/send.svg';
import { ReactComponent as ResultIcon } from '../../assets/pdf_chat/result.svg';
import { ReactComponent as CopyIcon } from '../../assets/pdf_chat/copy.svg';

import { ReactComponent as BotIcon } from '../../assets/pdf_chat/bot.svg';
import { ReactComponent as UserIcon } from '../../assets/pdf_chat/profile.svg';

import Loading from '../../components/iconify/loading';
import ChatInput from "./ChatInput";
import { sendFileChatMessageStream } from '../../api/chatbot/ChatbotApi'
import { getKnowledgePdfFileSummary } from '../../api/product/KnowledgeFileApi';
import usePopup from "../../hooks/popup/usePopup";
import { copyToClipboard } from '../../utils/Clipboard';
import { ConversationKnowledgeItem, SessionConversationItem } from '../../dto/Items';
import {
    getSessionConversation,
    getSessionConversationPage
} from '../../api/analytics/ChatLogApi';

import remarkGfm from 'remark-gfm'

import '../pdfchat.css'
import { FileManager } from '../manager/FileManager';
import { SummaryProps } from '../layout/Right';
import { AsyncManager } from '../manager/AsyckManager';
import { Message } from '@mui/icons-material';

interface ChatMessage {
    message: string;
    isUser: boolean;
    time: Date;
    isloading?: boolean;
    id?: number;
    knowledge?: ConversationKnowledgeItem[];
}

interface FileChatProps {
    knowledge_set_id: number;
    selected_file_status: number;
    isReferenceOpen: boolean;
    makedSummary: SummaryProps | undefined;
    language: string;
    onKnowledgeClick: (knowledge?: ConversationKnowledgeItem[]) => void;
    onPointChange: () => void;
}

function FileChat({ knowledge_set_id, selected_file_status, language, onKnowledgeClick, onPointChange, isReferenceOpen, makedSummary }: FileChatProps) {
    const service_id = sessionStorage.getItem('service_id');
    const customer = JSON.parse(sessionStorage.getItem('customer') || "{}");
    const userName = customer.name;
    const { showPopup } = usePopup();
    const fileManager = FileManager.getInstance();

    const [messageList, setMessageList] = useState<ChatMessage[]>([]);
    // const [message, setMessage] = useState('');
    const [selectedChat, setSelectedChat] = useState<number | undefined>();
    const [inputStatus, setInputStatus] = useState(true);
    const [summary, setSummary] = useState<string>("");

    const [page, setPage] = useState(1)
    const [isFetching, setFetching] = useState(false)
    const [hasNextPage, setNextPage] = useState(true)

    const scrollRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const inputComponentRef = useRef<any>();
    const heightBackup = useRef<number>();

    const addMessage = (message: string, isUser: boolean, time: Date) => {
        if (isUser) inputComponentRef.current!.clearMessage();
        const botMessage = { message: "응답을 처리 중입니다.", isUser: false, isloading: true, time: new Date() };
        setMessageList(prevState => [...prevState, { message: message, isUser: isUser, time: time }, botMessage]);
        return botMessage;
    }

    const addResultMessage = (userMessage: string, id: string, userTime: Date, botMessage: string, knowledge?: any[]) => {
        let convertedKnowledge: ConversationKnowledgeItem[] | undefined = undefined;
        if (knowledge) {
            convertedKnowledge = [];
            for (let item of knowledge) {
                convertedKnowledge.push({
                    content: item.content as string,
                    file_name: item.metadata.source as string,
                    metadata: item.metadata,
                    tree_id: item.metadata.tree_id as number
                });
            }
        }

        setMessageList(prevState => {
            let temp = prevState;
            temp.splice(prevState.length - 2, 2)
            return [...temp, { message: userMessage, isUser: true, time: userTime }, { message: botMessage, id: +id, isUser: false, isloading: false, time: new Date(), knowledge: convertedKnowledge }]
        });
    }
    const setMessages = (messages: SessionConversationItem[]) => {
        let temp: ChatMessage[] = [];
        for (let item of messages) {
            temp.push({ message: item.question, isUser: true, time: new Date(item.startedAt) })
            temp.push({ message: item.answer, isUser: false, id: item.id, knowledge: item.knowledge, isloading: false, time: new Date(item.endedAt) })
        }
        setMessageList(prevState => {
            return temp.concat(prevState);
        });
    }

    const fileCheck = useRef<number>(-1);
    const file_id_check = useRef<number>(-1);
    const sendChatBotMessageStream = async (question: string | null) => {
        if (!question) return;
        inputComponentRef.current!.clearMessage();
        onKnowledgeClick();
        fileCheck.current = file_id_check.current;
        const utterance = `${question}`
        let userTime = new Date();
        if (!inputStatus || utterance.length === 0) return;
        setInputStatus(false)
        const botMessage = addMessage(utterance, true, userTime);
        scrollToBottom();
        sendFileChatMessageStream({
            utterance: utterance,
            user_name: userName!,
            service_id: service_id!,
            language: language,
            knowledge_set_id: String(file_id_check.current), // TODO
            session_id: fileManager.getSelectedFile()?.session_id
        },
            (chatResponse) => {
                if (chatResponse && chatResponse.body) {
                    const reader = chatResponse.body.pipeThrough(new TextDecoderStream()).getReader();
                    botMessage.message = '';
                    let currentSessionId = sessionStorage.getItem("currentSessionId");
                    readAsync(reader, currentSessionId || "", botMessage.message, []);
                }
            },
            (status, detail) => {
                addResultMessage(utterance, "-1", userTime, "오류가 발생 했습니다. 잠시 후 다시 시도해 주세요.");
                inputRef.current?.focus();
                scrollToBottom();
                setInputStatus(true)
            }
        )
    }

    const readAsync = async (reader: ReadableStreamDefaultReader, session_id: string, text: string, arr: any[]) => {
        AsyncManager.readAsync(reader, session_id, text, arr).then(result => {
            if (fileCheck.current === file_id_check.current && result) {
                if (result.isDone) {
                    let currentSessionId = sessionStorage.getItem("currentSessionId");
                    if ((!currentSessionId || currentSessionId === session_id) && fileCheck.current === file_id_check.current) {
                        let convertedKnowledge: ConversationKnowledgeItem[] | undefined = undefined;
                        if (arr && arr.length > 0) {
                            convertedKnowledge = [];
                            for (let item of arr) {
                                convertedKnowledge.push({
                                    content: item.content as string,
                                    file_name: item.metadata.source as string,
                                    metadata: item.metadata,
                                    tree_id: item.metadata.tree_id as number
                                });
                            }
                        }
                        if (result.text) {
                            setMessageList(prevState => {
                                prevState[prevState.length - 1].message = result.text!;
                                prevState[prevState.length - 1].knowledge = convertedKnowledge;
                                prevState[prevState.length - 1].isloading = false;
                                return [...prevState]
                            });
                        }
                        inputRef.current?.focus();
                        scrollToBottom();
                        setInputStatus(true);
                        onPointChange();
                    }
                    return;
                }
                else {
                    if (result.text) {
                        setMessageList(prevState => {
                            prevState[prevState.length - 1].message = result.text!;
                            prevState[prevState.length - 1].isloading = true;
                            return [...prevState]
                        });

                    }
                    scrollToBottom();
                }
                setTimeout(() => { readAsync(reader, session_id, result && result.text ? result.text : "", arr); }, 20);
            }
        })
    }

    const KnowledgeLink = ({ id, knowledge }: { id?: number, knowledge?: ConversationKnowledgeItem[] }) => {
        let knowledgeArray: ConversationKnowledgeItem[][] = [];
        if (knowledge && knowledge.length > 0) {
            const grouped = knowledge.reduce((acc, item) => {
                (acc[item.tree_id] = acc[item.tree_id] || []).push(item);
                return acc;
            }, {} as { [key: string]: ConversationKnowledgeItem[] });
            Object.keys(grouped).sort().forEach((key, index) => {
                grouped[key].forEach(item => (item.tree_id = index));
                knowledgeArray.push(grouped[key]);
            });
        }
        return (
            knowledgeArray.length > 0 ?
                <div className='pdf_knowledge_item_layout'>
                    <div className='pdf_knowledge' onClick={() => { setSelectedChat(id); onKnowledgeClick(knowledge); }}>
                        <ResultIcon style={{ width: '20px', height: '20px' }} />&nbsp;출처
                    </div>
                    {
                        knowledgeArray.map((item, index) => {
                            return (
                                <div className={'pdf_knowledge_item item_' + (index + 1)} key={"knowledge_item_" + index} onClick={() => { setSelectedChat(id); onKnowledgeClick(item); }}>
                                    {index + 1}
                                </div>
                            )
                        })
                    }

                </div>
                :
                <></>
        )
    }

    const KnowledgeCopy = ({ text }: { text: string }) => {
        return (
            <div className='pdf_knowledge' onClick={() => { copyTo(text); }}><CopyIcon style={{ width: '20px', height: '20px' }} />&nbsp;복사</div>
        )
    }

    const copyTo = async (text: string) => {
        copyToClipboard(text).then((result) => {
            if (result) {
                showPopup({ message: '복사 되었습니다.' });
            }
            else {
                showPopup({ message: '복사에 실패 했습니다.' });
            }
        })
    }

    const Bot = ({ text, time, id, knowledge, isloading }: { text: string, time: Date, id?: number, knowledge?: ConversationKnowledgeItem[], isloading: boolean | undefined }) => {
        return (
            <div>
                <div className='pdf_bot_text'>
                    <div className='bot_icon_layout'>
                        <BotIcon className='bot_icon' />&nbsp;&nbsp;DreamChat Bot
                    </div>
                    {
                        isloading ?
                            <>
                                <div className='textbox' style={{ display: 'flex' }} ><div><ReactMarkdown>{text}</ReactMarkdown></div></div>
                                <Loading width='20px' />
                            </>
                            :
                            <>
                                <div className='textbox'><ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown></div>
                                <div className='pdf_knowledge_layout'>
                                    <KnowledgeLink id={id} knowledge={knowledge} />
                                    <KnowledgeCopy text={text} />
                                </div>
                            </>
                    }
                </div>
                {/* <div className='bot chat_time'>{gridDateTime(time, 'yyyy-MM-dd hh:mm:ss')}</div> */}
            </div>
        );
    }

    const User = ({ text, time }: { text: string, time: Date }) => {
        return (
            <div>
                <div className='user_icon_layout'>
                    <UserIcon className='user_icon' />&nbsp;나
                </div>
                <div className='pdf_user_text'>
                    <div className='textbox'>{text}</div>
                </div>
                {/* <div className='user chat_time'>{gridDateTime(time, 'yyyy-MM-dd hh:mm:ss')}</div> */}
            </div>
        );
    }

    const scrollToBottom = () => {
        if (scrollRef.current) {
            setTimeout(() => { scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight; }, 50);
        }
    };

    const scrollToPosition = (position: number) => {
        if (scrollRef.current) {
            setTimeout(() => { 
                scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight - position; 
            }, 50);
        }
    };
    // const getConversation = (summary: string) => {
    //     setMessageList([]);
    //     onKnowledgeClick([]);
    //     const session_id = fileManager.getSelectedFile()?.session_id;
    //     if (session_id) {
    //         getSessionConversation({
    //             id: session_id,
    //         }, (response) => {
    //             if (response) {
    //                 setMessages(response.data);
    //                 setSummary(summary);
    //                 scrollToBottom();
    //             }
    //             else {
    //                 setSummary(summary);
    //             }
    //         }, (status, detail) => {
    //             // showPopup({ message: detail? detail.message :  `Error(${status})\n실패 했습니다.` });
    //         });
    //     }
    // }

    const getConversation = (summary: string, page: number) => {
        // setMessageList([]);
        onKnowledgeClick([]);
        const session_id = fileManager.getSelectedFile()?.session_id;
        if (session_id) {
            getSessionConversationPage({
                id: session_id,
                page: page,
                limit: 5
            }, (response) => {
                if (response) {
                    setMessages(response.data?.list);
                    setSummary(summary);
                    if (page == 1) scrollToBottom();
                    else {
                        scrollToPosition(heightBackup?.current || 0);
                    }
                    setPage(page + 1);
                    setNextPage(response.data.total_count > page * 5);
                    setFetching(false)
                }
                else {
                    setSummary(summary);
                }

            }, (status, detail) => {
                // showPopup({ message: detail? detail.message :  `Error(${status})\n실패 했습니다.` });
            });
        }
    }

    const getSummary = () => {
        const selected = fileManager.getSelectedFile();
        if (selected) {
            getKnowledgePdfFileSummary({ file_id: selected.id },
                (response) => {
                    if (response) {
                        setFetching(false);
                        setMessageList([]);
                        getConversation(response.data, 1);
                    }
                    else {
                        setFetching(false);
                        getConversation("", 1);
                    }
                },
                () => { }
            )
        }
    }

    useEffect(() => {
        const scrollLayout = document.querySelector('.file_chat_layout');
        const handleScroll = () => {
            if ((scrollRef?.current?.scrollTop || 100) < 20) {
                setFetching(true);
            }
        }
        setFetching(true);
        scrollLayout?.addEventListener('scroll', handleScroll);
        return () => scrollLayout?.removeEventListener('scroll', handleScroll);
    }, [])

    useEffect(() => {
        if (isFetching && hasNextPage) {
            heightBackup.current = (scrollRef.current?.scrollHeight || 0) - (scrollRef.current?.scrollTop || 0)
            getConversation(summary, page);
        }
        else if (!hasNextPage) setFetching(false);
    }, [isFetching])

    useEffect(() => {
        file_id_check.current = knowledge_set_id;
        getSummary();
        // eslint-disable-next-line 
    }, [knowledge_set_id])

    useEffect(() => {
        setInputStatus(selected_file_status === 0);
        if (selected_file_status === 0 && makedSummary && makedSummary.text?.length > 0) {
            setSummary(makedSummary.text + " ");
        }
        // eslint-disable-next-line 
    }, [selected_file_status]);

    useEffect(() => {
        if (!isReferenceOpen) {
            setSelectedChat(undefined);
        }
        // eslint-disable-next-line 
    }, [isReferenceOpen]);

    useEffect(() => {
        if (makedSummary && makedSummary.file_id === fileManager.getSelectedFile()?.id) {
            setSummary(makedSummary.text || "");
        }
        // eslint-disable-next-line 
    }, [makedSummary]);

    useEffect(() => {
        if (selected_file_status === 0) {
            const container = document.querySelector("#summary_layout");
            const tempList = container?.querySelectorAll("ol");
            const example = tempList && tempList[tempList.length - 1];
            const items = example?.querySelectorAll("li");
            if (items) {
                for (let i = 0; i < items.length; i++) {
                    items[i].addEventListener('click', sugestClickEvent);
                }
            }
        }
        return releaseClickEvent;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summary]);


    const sugestClickEvent = (event: MouseEvent) => {
        if (event.target instanceof Element) {
            if (inputStatus) {
                sendChatBotMessageStream(event.target.textContent);
            }
        }
    }

    const releaseClickEvent = () => {
        const container = document.querySelector("#summary_layout");
        const items = container?.querySelectorAll("li");
        if (items) {
            for (let i = 0; i < items.length; i++) {
                items[i].removeEventListener('click', sugestClickEvent);
            }
        }
    }

    const getStatueString = () => {
        switch (selected_file_status) {
            case 0:
                return "응답을 생성 중입니다.";
            case 1:
            case 2:
            case 3:
                return "문서를 분석 중입니다.";
            case 999:
                return "오류가 발생 했습니다.";
        }
    }

    const getGuideString = () => {
        switch (selected_file_status) {
            case 0:
                return "하단 입력창을 이용해 질문을 해 보세요.";
            case 1:
            case 2:
            case 3:
                return "문서를 분석 중입니다.";
            case 999:
                return "오류가 발생 했습니다.";
        }
    }

    return (
        <div className='file_chat_content' >
            <div className='file_chat_layout' ref={scrollRef}>
                {
                    !hasNextPage &&
                    <div className='pdf_file_info'>
                        <div>
                            현재 파일 : <span className='pdf_file_name'>&nbsp;{fileManager.getSelectedFile()?.name || ""}</span>
                        </div>
                        <br />
                        <div>
                            내용 요약 : <span className='pdf_summary' id={"summary_layout"}>
                                &nbsp;<ReactMarkdown>{summary}</ReactMarkdown>
                                {
                                    selected_file_status !== 0 && <Loading width={''} />
                                }
                            </span>
                        </div>
                    </div>
                }
                {
                    messageList && messageList.length > 0 ?
                        <>
                            {
                                messageList.map((data, index) => (
                                    <div key={'chat_item_' + index} style={{ background: data.id !== undefined && data.id === selectedChat ? "#fdf0d5" : "#FFFFFF" }}>
                                        {
                                            data.isUser ?
                                                <User text={data.message} time={data.time} />
                                                :
                                                <Bot text={data.message} time={data.time} id={data.id} knowledge={data.knowledge} isloading={data.isloading} />
                                        }
                                    </div>
                                ))
                            }
                        </>
                        :
                        <>
                            <div className='pdf_chat_guide'>
                                {getGuideString()}
                            </div>
                        </>
                }
            </div>
            <ChatInput
                inputStatus={inputStatus}
                placeholder={inputStatus ? '문서에 관해서 무엇이든 물어보세요.' : getStatueString()}
                onEnter={(message) => sendChatBotMessageStream(message)}
                inputRef={inputRef}
                ref={inputComponentRef}
            />
        </div>
    );
}
export default FileChat;