// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layer-base {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
}
.visible {
    display: block;
}
.none {
    display: none;
}
.layer-wrapper {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
}

.layer-content {
    box-sizing: border-box;
    position: relative;
    width: 360px;
    max-width: 800px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 40px 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/layer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,MAAM;IACN,OAAO;IACP,SAAS;IACT,QAAQ;IACR,oCAAoC;IACpC,YAAY;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa;AACjB;AACA;IACI,sBAAsB;IACtB,eAAe;IACf,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;IACP,aAAa;IACb,cAAc;IACd,UAAU;AACd;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,QAAQ;IACR,2BAA2B;IAC3B,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".layer-base {\r\n    box-sizing: border-box;\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    bottom: 0;\r\n    right: 0;\r\n    background-color: rgba(0, 0, 0, 0.6);\r\n    z-index: 999;\r\n}\r\n.visible {\r\n    display: block;\r\n}\r\n.none {\r\n    display: none;\r\n}\r\n.layer-wrapper {\r\n    box-sizing: border-box;\r\n    position: fixed;\r\n    top: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    left: 0;\r\n    z-index: 1000;\r\n    overflow: auto;\r\n    outline: 0;\r\n}\r\n\r\n.layer-content {\r\n    box-sizing: border-box;\r\n    position: relative;\r\n    width: 360px;\r\n    max-width: 800px;\r\n    top: 50%;\r\n    transform: translateY(-50%);\r\n    margin: 0 auto;\r\n    padding: 40px 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
