import { useState, useEffect } from 'react';
import Layer from '../../components/Layer';
import usePopup from '../../hooks/popup/usePopup';

import {
    editProduct,
} from '../../api/product/ProductApi';

import './productPopup.css'

interface ProductAddPopupProps {
    product_id: number;
    product_name: string;
    isShow: boolean;
    onClose: (isAdded: boolean, type: string) => void;
}

export default function ProductEditPopup({ isShow, product_id, product_name, onClose }: ProductAddPopupProps) {

    const { showPopup } = usePopup();

    const [editName, setEditName] = useState(product_name);
    useEffect(() => {
        setEditName(product_name);
    }, [product_name])

    const EditProductClick = () => {
        showPopup({
            type: 'confirm',
            title: '확인',
            message: '수정 하시겠습니까?',
            confirm: () => {
                editProduct({
                    id: product_id,
                    knowledge_set_name: editName
                },
                    (response) => {
                        closePopup(true);
                    },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                        closePopup(false)
                    });
            },
        });

    }

    const closePopup = (isChanged: boolean) => {
        setEditName("");
        onClose(isChanged, 'edit');
    }

    return (
        <Layer isShow={isShow}>
            <div className='product_popup_background'>
                <div className='product_popup_title'>Product 이름 수정</div>
                <div className='product_popup_content' style={{ border: 'none' }}>
                    <div className='product_popup_input_line'>
                        <div className='product_popup_input_label'>이름</div>
                        <input className='product_popup_input' value={editName || ''} onChange={(e) => { setEditName(e.target.value) }} />
                    </div>
                </div>
                <div className='product_popup_button_layer'>
                    <button className="product_popup_button add_product_popup_button" onClick={() => { EditProductClick(); }}>{"수정"}</button>
                    <button className="product_popup_button close_product_popup_button" onClick={() => { closePopup(false) }}>{"닫기"}</button>
                </div>
            </div>
        </Layer>
    )
}