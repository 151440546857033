import { AxiosResponse } from 'axios';
import APIService from '../APIService';
import {GetServiceInfoParams, UpdateServiceParams } from '../../dto/RequestParams'
import {ServiceResponse, CheckServiceStatusRespopnse, TaskResponse} from '../../dto/ResponseData'

const prefix = 'service/';

export async function getServiceInfo(params: GetServiceInfoParams, success: (res: AxiosResponse<ServiceResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function updateServiceInfo(params: UpdateServiceParams, success: (res: AxiosResponse<ServiceResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'update', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function checkServiceStatus(params: GetServiceInfoParams, success: (res: AxiosResponse<CheckServiceStatusRespopnse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'check_status', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function getRunningServiceTask(params: GetServiceInfoParams, success: (res: AxiosResponse<TaskResponse[], any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_running_task', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}