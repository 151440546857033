import React, { useState, useRef } from 'react';

import usePopup from "../../hooks/popup/usePopup";
import { knowledgeFileUpload, knowledgeFileUploadConfirm, knowledgePdfFileUploadConfirm } from '../../api/product/KnowledgeFileApi';
import { addFileChatProduct } from '../../api/product/ProductApi';

import '../pdfchat.css'
import AddIcon from '../../assets/pdf_chat/upload.svg';

interface FileUploderProps {
    status : boolean;
    onUploaded: () => void;
}

function FileUploder({ onUploaded , status}: FileUploderProps) {
    const { showPopup } = usePopup();

    const customer_id = sessionStorage.getItem('customer_id');
    const service_id = sessionStorage.getItem('service_id');

    // let productId = 0; //TODO

    const FILE_SIZE_LIMIT = 1024 * 1024 * 2; //2mb(메가)


    const inputRef = useRef<HTMLInputElement>(null);

    const [isUploading, setIsUploading] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);

    const onFileDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onFileDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // setIsDragging(false);
    }

    const onFileDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // if (e.dataTransfer && e.dataTransfer.files) {
        //     setIsDragging(true);
        // }
    }

    const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer && e.dataTransfer.files) {
            onChangeFiles(e);
            // setIsDragging(false);
        }
    }

    const handleUploadButtonClick = () => {
        if (inputRef.current) {
            if (!status) {
                showPopup({ message: "파일 분석중에는 업로드 할 수 없습니다." });
                return;
            }
            inputRef.current.click();
        }
    };

    const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
        if (!status) {
            showPopup({ message: "파일 분석중에는 업로드 할 수 없습니다." });
            return;
        }
        let selectFiles: FileList | null;
        if (e.type === "drop") {
            selectFiles = (e as React.DragEvent<HTMLDivElement>).dataTransfer.files;
        } else {
            selectFiles = (e.target as HTMLInputElement).files;
        }
        if (selectFiles) {
            if (selectFiles.length > 1) {
                showPopup({ message: "파일을 하나만 선택 해 주세요." });
            }
            else if (selectFiles.length === 0) {
                showPopup({ message: "파일을 선택 해 주세요." });
            }
            else {
                var fileName = selectFiles!.item(0)!.name
                if (fileName.slice(fileName.indexOf(".") + 1).toLowerCase() !== 'pdf' ){
                    showPopup({ message: "PDF 파일이 아닙니다." });
                    return;
                }
                if(FILE_SIZE_LIMIT < selectFiles!.item(0)!.size){
                    showPopup({message: '2mb 미만인 파일만\n업로드가 가능합니다.'});
                    return;
                }
                addFileChatProduct({
                    customer_id: customer_id!,
                    service_id: service_id!,
                    knowledge_set_name: "pdf_chat_item"
                },
                    (response) => {
                        console.log(response!.data)
                        const knowledgeSet = response!.data;
                        fileUpload(selectFiles!.item(0)!, knowledgeSet.id)
                    },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                    }
                );
            }
        }
    }

    const fileUpload = (uploadfile: File, knowledgeSetId: number) => {
        const formData = new FormData();
        formData.append("id", String(knowledgeSetId));
        formData.append("files", uploadfile);
        setIsUploading(true);

        knowledgeFileUpload(formData, onUploadProgress,
            (response) => {
                if (response?.data) {
                    setProgressPercent(100);

                    // const ext = uploadfile.name.split('.').pop();
                    // if (ext === 'pdf') {
                    //     knowledgePdfFileUploadConfirm({
                    //         customer_id: customer_id!,
                    //         service_id: service_id!,
                    //         knowledge_set_id: knowledgeSetId,
                    //         file_list: response.data
                    //     },
                    //         (confirmResponse) => {
                    //             onUploadComplete();
                    //         },
                    //         (confirmStatus, detail) => {
                    //             showPopup({ message: detail ? detail.message : `Error(${confirmStatus})\n업로드에 실패 했습니다.` });
                    //         }
                    //     )
                    // }
                    // else {
                        knowledgePdfFileUploadConfirm({
                            customer_id: customer_id!,
                            service_id: service_id!,
                            knowledge_set_id: knowledgeSetId,
                            file_list: response.data
                        },
                            (confirmResponse) => {
                                onUploadComplete();
                            },
                            (confirmStatus, detail) => {
                                showPopup({ message: detail ? detail.message : `Error(${confirmStatus})\n업로드에 실패 했습니다.` });
                            }
                        )
                    // }


                }
            },
            (status, detail) => {
                showPopup({ message: detail ? detail.message : `Error(${status})\n업로드에 실패 했습니다.` });
            });
    }

    const onUploadComplete = () => {
        showPopup({ message: "업로드가 완료 되었습니다." });
        setIsUploading(false);
        setProgressPercent(0);
        if (inputRef?.current?.value) inputRef.current.value = '';
        onUploaded();
    }

    const onUploadProgress = (event: ProgressEvent) => {
        const per = (event.loaded / event.total) * 100;
        if (per >= 100) {
            setProgressPercent(0);
        }
        else {
            setProgressPercent(parseFloat(per.toFixed(2)));
        }
    };

    return (
        <div>
            <div className={'pdf_drop_box ' + (isUploading && 'on_upload')}
                onDragEnter={onFileDragEnter}
                onDragLeave={onFileDragLeave}
                onDragOver={onFileDragOver}
                onDrop={onFileDrop}
                onClick={handleUploadButtonClick}
            >
                <div>
                    {
                        isUploading ?
                            <div className='pdf_drop_upload'>
                                <img src={AddIcon}  width={24} height={24} />
                                <div >&nbsp;&nbsp;Upload Files</div>
                            </div>
                            :
                            <div className='pdf_drop_normal'>
                                <img src={AddIcon} width={24} height={24} />
                            </div>

                    }
                    {
                        status ? 
                        <div className='drop_text'>{isUploading ? "업로드중 입니다..." : <p>드래드앤 드롭 또는 클릭해서<br/>파일을 선택하세요.</p>}</div>
                        :
                        <div className='drop_text'><p>파일을 분석 중입니다.<br/>잠시만 기다려 주세요.</p></div>
                    }
                    {
                        isUploading &&
                        <div style={{ marginTop: "5px", marginLeft: "12px" }}>
                            <div className="loading_progress_bar" >
                                <span className="loading_progress_bar_gauge" style={{ width: progressPercent + "%" }} />
                            </div>
                        </div>
                    }
                </div>
                <input type="file" ref={inputRef} style={{ display: "none" }} onChange={onChangeFiles} />
            </div>
        </div>
    )
}
export default FileUploder;