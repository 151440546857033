import React, { useEffect, useState, useRef } from "react";

import FileChat from "../components/FileChat";
import { ConversationKnowledgeItem } from '../../dto/Items';

import { ReactComponent as Send } from '../../assets/pdf_chat/send.svg';
import '../pdfchat.css'
import { FileManager } from "../manager/FileManager";
import PdfViewer from "../components/PdfViewer";

export interface SummaryProps {
    file_id: number;
    text: string;
}

interface RightProps {
    className: string;
    session_id: string | undefined;
    knowledge_set_id: number | undefined;
    summary: SummaryProps | undefined;
    language: string;
    onPointChange: () => void;
}
function Right(props: RightProps) {
    const [isReferenceOpen, setIsReferenceOpen] = useState(false);
    const [knowledgeList, setKnowledgeList] = useState<ConversationKnowledgeItem[] | undefined>([]);
    const [selectedKnowledgeSetId, setSelectedKnowledgeSetId] = useState<number>(-1);
    const [selectedFileStatus, setSelectedFileStatus] = useState<number>(0);
    const [selectedKnowledgeFileName, setSelectedKnowledgeFileName] = useState<string>("");

    const fileManager = FileManager.getInstance();

    const pageRef = useRef<HTMLDivElement[]>([]);
    const thumbRef = useRef<HTMLDivElement[]>([]);

    fileManager.setFileChangeListener((file) => {
        if (file) {
            setSelectedFileStatus(file.status);
            if (file.set_id && selectedKnowledgeSetId !== file.set_id) {
                setSelectedKnowledgeSetId(file.set_id);
                setSelectedKnowledgeFileName(file.name);
                setKnowledgeList([]);
            }
            if (file.session_id) {
                sessionStorage.setItem("currentSessionId", file.session_id);
            }
            else {
                sessionStorage.removeItem("currentSessionId");
            }
        }
        else {
            setSelectedKnowledgeSetId(-1);
            setSelectedKnowledgeFileName("");
            setKnowledgeList([]);
            sessionStorage.removeItem("currentSessionId");
        }
    })

    const onKnowledgeClick = (knowledge?: ConversationKnowledgeItem[]) => {
        if (knowledge && knowledge.length > 0) {
            setIsReferenceOpen(true);
            const pageNum = knowledge[0].metadata.page - 1;
            thumbRef.current[pageNum].scrollIntoView();
            pageRef.current[pageNum].scrollIntoView();
            setKnowledgeList(knowledge);
        }
        else {
            setIsReferenceOpen(false);
            setKnowledgeList([]);
        }
    }

    useEffect(() => {
        if (!knowledgeList || knowledgeList?.length === 0) {
            setIsReferenceOpen(false)
        }
    }, [knowledgeList]);

    return (
        <div className={`${props.className}`}>
            <div className='v_section chat_layout'>
                {
                    selectedKnowledgeSetId > 0 ?
                        <FileChat
                            knowledge_set_id={selectedKnowledgeSetId}
                            selected_file_status={selectedFileStatus}
                            onKnowledgeClick={onKnowledgeClick}
                            onPointChange={props.onPointChange}
                            language={props.language}
                            makedSummary={props.summary}
                            isReferenceOpen={isReferenceOpen} />
                        :
                        <div className="pdf_chat_help">
                            <div>
                                <div className="pdf_chat_prompt">
                                    문서를 업로드하고 AI에게 무엇이든 물어보세요.
                                </div>
                                <div className="pdf_chat_input_box pdf_chat_input_box_help">
                                    <input className="pdf_chat_input" placeholder="문서에 관해 무엇이든 물어보세요." disabled />
                                    <div className="pdf_send_box">
                                        <Send className="pdf_send_button" />
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
            {
                selectedKnowledgeSetId > 0 &&
                <PdfViewer 
                    pageRef={pageRef}
                    thumbRef={thumbRef}
                    knowledgeList={knowledgeList}
                    selectedKnowledgeSetId={selectedKnowledgeSetId}
                    selectedKnowledgeFileName={selectedKnowledgeFileName}

                />
            }
        </div>
    )
}
export default Right;