import React, { useState } from 'react';
import Layer from '../../components/Layer';
import './productPopup.css'
import usePopup from '../../hooks/popup/usePopup';

import {
    addKnowledgeItem,
} from '../../api/product/KnowledgeItemApi';

interface KnowledgeAddPopupProps {
    isShow: boolean;
    productId: number;
    onClose: (isAdded: boolean) => void;
}

const KnowledgeAddPopup = ({isShow, productId, onClose }: KnowledgeAddPopupProps) => {
    const customer_id = sessionStorage.getItem('customer_id');
    const service_id = sessionStorage.getItem('service_id');

    const { showPopup } = usePopup();
    const [knowledgeContent, setKnowledgeContent] = useState('');

    const onAddClick = () => {
        if (knowledgeContent.length === 0) {
            showPopup({ message: "추가 할 내용이 없습니다." });
        }
        else {
            showPopup({
                type: 'confirm',
                title: '확인',
                message: '추가 하시겠습니까?',
                confirmText: "추가",
                cancelText: "취소",
                confirm: () => {
                    addKnowledgeItem({
                        knowledge_set_id: productId,
                        service_id: service_id!,
                        customer_id: customer_id!,
                        content: knowledgeContent
                    },
                        (response) => {
                            showPopup({ message: "추가 되었습니다." });
                            popupClose(true);
                        },
                        (status) => {
                            showPopup({ message: `Error(${status})\n실패 했습니다.` });
                        }
                    )
                },
            });
        }
    }

    const popupClose = (isAdd: boolean) => {
        setKnowledgeContent("");
        onClose(isAdd);
    }

    return (
        <Layer isShow={isShow}>
            <div className='knowledge_edit'>
                <div className='product_popup_title'>추가 하기
                </div>
                <div className='knowledge_edit_content' style={{ border: 'none' }}>
                    <div className='knowledge_input_line'>
                        <textarea className='popup_knowledge_input_area' maxLength={1000} value={knowledgeContent} onChange={(e) => { setKnowledgeContent(e.target.value); }} />
                    </div>
                </div>
                <div className='product_popup_button_layer'>
                    <button className="product_popup_button add_product_popup_button" onClick={onAddClick}>{"추가"}</button>
                    <button className="product_popup_button close_product_popup_button" onClick={() => popupClose(false)}>{"취소"}</button>
                </div>
            </div>
        </Layer>
    );
};
export default KnowledgeAddPopup;