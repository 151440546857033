interface LoadingIconProps {
    width: string;
}

const Loading = (props: LoadingIconProps) => {
    const { width } = props;
    const boxStyle = {
        width: width ? width : '20px',
        height: width ? width : '20px',
    } 
    return (
        <div style={boxStyle}>
            <div className="loading_icon" style={boxStyle} />
        </div>
    );
}
export default Loading;