import '../../css/kakao.css';
import fallback from '../../assets/guide/fallback.png';
import selectSkill from '../../assets/guide/select_skill.png';
import fallback_2 from '../../assets/guide/fallback_2.png';
import fallback_3 from '../../assets/guide/fallback_3.png';
import callback from '../../assets/guide/callback_setting.png';
import callback_2 from '../../assets/guide/callback_setting_2.png';

export default function Page5() {
    return (
        <div className='page_content'>
            <div className='sub_header'>시나리오 생성</div>
            <div className='conter_contents'>
                <ul>
                    <li>1. 시나리오 클릭 후 폴백 블록 클릭 <img className='guide_image' src={fallback} width='500px' alt='fallback click' /></li>
                    <li>2. 파라미터 설정에 스킬 검색/선택에서 <span style={{ fontWeight: '700' }}>Step3 에서 생성한</span> 스킬 선택 <img className='guide_image' src={selectSkill} width='800px' alt='select skill' /></li>
                    <li>3. 일반 파라미터 옆 + 버튼 클릭<img className='guide_image' src={fallback_2} width='450px' alt='parameter setting click' /></li>
                    <li>4. 파라미터 명 : <span style={{ fontWeight: '700' }}>service_id</span> | 엔티티 : <span style={{ fontWeight: '700' }}>sys.constant</span> 입력 후<br />
                        값 에는 기본값 사용에 체크후 그 자리에 본인 service_id 입력<img className='guide_image' src={fallback_3} width='450px' alt='input parameters' /></li>
                    <li>5. 저장 옆 ... 을 클릭 후 callback 설정 선택<img className='guide_image' src={callback} width='300px' alt='select callback setting' /></li>
                    <li>6. 오른쪽 위 Callback 설정을 켜주고, 대기시간동안 보낼 메시지 설정(없어도 상관 없음) 후 확인<img className='guide_image' src={callback_2} width='450px' alt='callback setting' /></li>
                    <li>7. 하단의 응답 추가에 스킬 데이터 선택 후 저장</li>
                    <li>{'   '}</li>
                </ul>
            </div>
        </div>
    )
}