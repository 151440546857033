import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from 'react-router';
import { RecoilRoot } from 'recoil';

import Intro from './pdfchat/moblie/Introduce';
import Login from './pdfchat/moblie/Login';
import Chat from './pdfchat/moblie/Chat'

import PdfChatLanding from './pdfchat/PdfChatLanding';
import PdfChatLogin from './pdfchat/PdfChatLogin';

import KakaoLogin from './pages/auth/KakaoLogin';
import NaverLogin from './pages/auth/NaverLogin';

import PdfChat from './pdfchat/PdfChat'

import ChatView from "./pages/external/ChatView";
import PdfChatView from "./pages/external/PdfChatView";

import Popup from './popup/Popup'


import './App.css';

function App() {
    let customer_id = sessionStorage.getItem('customer_id');

    return (
        <RecoilRoot>
            <BrowserRouter>
                <div className="app">
                    {
                        window.location.pathname.indexOf('/external/') >= 0 ?
                            <Routes>
                                <Route path="/external/chatbot" element={<ChatView />} />
                                <Route path="/external/pdf_chat" element={<PdfChatView />} />
                                <Route path="/external/mobile/pdf_chat" element={<Chat />} />
                            </Routes>
                            :
                            (
                                customer_id ?
                                    (
                                        
                                        // window.location.pathname.indexOf('/mobile/') >= 0 ?
                                        // <Routes>
                                        //     <Route path="/mobile/pdf_chat" element={<Chat />} />
                                        //     <Route path="/mobile/pdf_chat_login" element={<Login />} />
                                        //     <Route path="/*" element={<Navigate to='/mobile/pdf_chat' />} />
                                        // </Routes>
                                        // :
                                        <Routes>
                                            <Route path="/pdf_chat" element={<PdfChat />} />
                                            <Route path="/pdf_chat_login" element={<PdfChatLogin />} />
                                            <Route path="/*" element={<Navigate to='/pdf_chat' />} />
                                        </Routes>

                                    )
                                    :
                                    (
                                        // window.location.pathname.indexOf('/mobile/') >= 0 ?
                                        // <Routes>
                                        //     <Route path="/mobile/pdf_chat_login" element={<Login />} />
                                        //     <Route path="/mobile/kakao_login" element={<KakaoLogin />} />
                                        //     <Route path="/mobile/naver_login" element={<NaverLogin />} />
                                        //     <Route path="/mobile/pdf_chat" element={sessionStorage.getItem('customer_id') ? <PdfChat /> : <Navigate to='/mobile/introduce' />} />
                                        //     <Route path="/mobile/introduce" element={<Intro />} />
                                        //     <Route path="/*" element={<Navigate to='/mobile/introduce' />} />
                                        // </Routes>
                                        // :
                                        <Routes>
                                            <Route path="/pdf_chat_login" element={<PdfChatLogin />} />
                                            <Route path="/kakao_login" element={<KakaoLogin />} />
                                            <Route path="/naver_login" element={<NaverLogin />} />
                                            <Route path="/pdf_chat" element={sessionStorage.getItem('customer_id') ? <PdfChat /> : <Navigate to='/introduce' />} />
                                            <Route path="/introduce" element={<PdfChatLanding />} />
                                            <Route path="/*" element={<Navigate to='/introduce' />} />
                                        </Routes>
                                    )
                            )
                    }
                </div>
            </BrowserRouter>
            <Popup />
        </RecoilRoot>
    );
}

export default App;