import React, { useEffect, useState } from "react";

import {
    getBalance,
} from '../api/payment/PaymentApi';

import Top from './layout/Top';
import Left from './layout/Left';
import Right from './layout/Right';
import usePopup from "../hooks/popup/usePopup";
import './pdfchat.css';

function PdfChat() {
    const customer = JSON.parse(sessionStorage.getItem('customer') || "{}");
    const { showPopup } = usePopup();

    const [balance, setBalance] = useState(customer.balance);
    const [session_id, setSessionId] = useState<string | undefined>(undefined);
    const [knowledge_set_id, setKnowledgeSetId] = useState<number | undefined>(undefined);
    const [file_name, setFile_name] = useState<string | undefined>(undefined);
    const [file_status, setFile_status] = useState<number | undefined>(undefined);

    const [requestStatus, setRequestStatus] = useState<number>(0);

    const handleSessionSelect = (knowledge_set_id: number | undefined, id: string | undefined, file_name: string | undefined, file_status: number | undefined) => {
        setSessionId(id);
        setKnowledgeSetId(knowledge_set_id)
        setFile_name(file_name);
        setFile_status(file_status);
    }

    useEffect(()=>{
        if (requestStatus === 2) {
            showPopup({message: "충전 요청이 반려 되었습니다."})
        }
    }, [requestStatus])
    const onPointChange = () => {
        getBalance({ customer_id: customer.id },
            (response) => {
                if (response?.data?.balance) {
                    setBalance(response.data.balance)
                    setRequestStatus(response.data.status)
                }
            },
            () => {

            }
        );
    }

    const onStatusChange = (status: number | undefined) => {
        console.log("calll onStatusChange : " + status )
        setFile_status(status)
    }

    return (
        <div>
            <Top balance={balance} />
            <div className='pdf_content_view'>
                <Left className='v_section file_list' onSessionSelect={handleSessionSelect} onPointChange={onPointChange}  onStatusChange={onStatusChange} />
                <Right className='v_section chat_view' knowledge_set_id={knowledge_set_id} session_id={session_id} file_name={file_name} file_status={file_status} onPointChange={onPointChange} />
            </div>
        </div>
    )
}
export default PdfChat;