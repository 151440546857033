import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BreadCrumb from '../components/BreadCrumb';
import '../css/kakao.css';
import Page1 from './kakao_guide/Page1';
import Page2 from './kakao_guide/Page2';
import Page3 from './kakao_guide/Page3';
import Page4 from './kakao_guide/Page4';
import Page5 from './kakao_guide/Page5';
import Page6 from './kakao_guide/Page6';


export default function Kakao() {
    const [page, setPage] = useState(0);
    let sliderRef = useRef<Slider>(null);
    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        adaptiveHeight: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => setPage(next)
    };

    const pageList = ['1. 채널 생성', '2. 챗봇 만들기', '3. 스킬 생성', '4. AI 챗봇 설정', '5. 시나리오 생성','6. 채널 연결'];

    return (
        <div className="home_contents">
            <BreadCrumb items={[{ name: "홈", path: "/" }, { name: "카카오톡 설정 안내" }]} />
            <div className="page_title">카카오톡 설정 안내</div>
            <div className="nav_header">
                {
                    pageList.map((item, index) => {
                        return <div key={'paging_' + index} className={`nav_button ${page === index ? 'selected': ''}`} onClick={() => { sliderRef.current?.slickGoTo(index) }}>{item}</div>
                    })
                }
            </div>
            <div className="slider-container">
                <Slider {...settings} ref={sliderRef} >
                    <Page1 />
                    <Page2 />
                    <Page3 />
                    <Page4 />
                    <Page5 />
                    <Page6 />
                </Slider>
            </div>
        </div>
    )
}