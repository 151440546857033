import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Icon } from '../assets/icon/market.svg';
import { ReactComponent as Back } from '../assets/icon/back.svg';
import usePopup from "../hooks/popup/usePopup";

import { customerSocialRegistration } from '../api/auth/AuthApi';
import { SocialRegistrationParams } from '../dto/RequestParams'
import { CustomerResponse } from '../dto/ResponseData'

import { AxiosResponse } from "axios";
import '../css/login.css'

function SocialRegistration({setServiceType}:{setServiceType: (type: string) => void;}) {
    const { showPopup } = usePopup();
    const navigate = useNavigate();


    const location = useLocation();
    const type = location.state.type;
    const email = location.state.email;

    const [name, setName] = useState('');

    const onRegistration = (e: any) => {
        e.preventDefault();
        console.log("============================")
        const registrationParams: SocialRegistrationParams = {            
            name: name,
            email: email,
            type: type
        }
        customerSocialRegistration(registrationParams,
            (response: AxiosResponse<CustomerResponse, any> | undefined) => {
                if(response) {
                    const data: CustomerResponse = response.data;
                    if (data.access_token) {
                        sessionStorage.setItem('customer_id', data.id);
                        sessionStorage.setItem('service_id', data.service_id);
                        sessionStorage.setItem('service_name', data.service_name);
                        sessionStorage.setItem('customer', JSON.stringify(data));
                        sessionStorage.setItem('access_token', data.access_token);
                        sessionStorage.setItem('account_type', "google");
                        sessionStorage.setItem('user_email', data.email);
                        setServiceType("market")
                        navigate('/');
                    }
                    else {
                        showPopup({ message: `에러가 발생 했습니다.` });
                    }
                }
            },
            (status, detail) => {
                if (detail) {
                    showPopup({ message: detail.message });
                }
                else {
                    showPopup({ message: `Error(${status})\n실패 했습니다.` });
                }
            });
    }

    return (
        <div className="login">
            <Icon className="icon_bg" />
            <div className="background_box">
                <div>
                    <Back className="back_button" onClick={() => { navigate('/'); }} />
                </div>
                <div className="logo"><Icon className="logo_icon" /> Market Buddy</div>
                <div className="guide_text">회원 가입을 위한 기본 정보를 <br /><br />입력해 주세요.</div>
                <div className="login_box">
                    <div className="email_area" >
                        <div className="input_header">Email</div>
                        <input className="text_input" placeholder="name@example.com" value={email} disabled />
                    </div>
                    <div className="password_area" >
                        <div className="input_header">Name</div>
                        <input className="text_input" placeholder="Your name" value={name} onChange={(e) => { setName(e.target.value) }} />
                    </div>
                    <div className="button_area" >
                        <div className="registration_button" onClick={onRegistration}>회원가입</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SocialRegistration;